import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

export default function SelectBox(props) {
  const menuStyle = {
    whiteSpace: 'normal',
  };

    // Adjust the maxHeight property to increase the height of the dropdown
    const selectStyle = {
      textAlign: 'left',
      borderColor: 'red !important', // Use !important to force the style
      backgroundColor: 'lightblue',
      maxHeight: '100px !important', // Use !important to force the style
    };
    const paperProps = {
      style: {
        maxHeight: '300px', // Adjust the maxHeight to your desired value
      },
    };
  return (
    <FormControl sx={{ minWidth: "100%", marginBottom: '16px'}}  >
      {props.isLable && (
        <Typography variant="label" style={{ fontSize: '14px', textAlign: "left", marginBottom: 10 }}>
          {props.lableValue}
        </Typography>
      )}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.value}
        name={props.name}
        error={props.error}
        onChange={props.onChange}
        variant='outlined'
        style={{
          borderColor: 'red',
          maxHeight: '400px',
        }}
        // sx={{
        //   color: "white",
        //   '.MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#0D508B',
        //   },
        //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#0D508B',
        //   },
        //   '&:hover .MuiOutlinedInput-notchedOutline': {
        //     borderColor: '#0D508B',
        //   },
        //   '& .MuiListItemText-root': {
        //     color: 'red !important', // Adjust the color of the selected text
        //   },
        //   '.MuiSvgIcon-root': {
        //     fill: "black !important",
        //   }
        // }}
        // sx={{ ...selectStyle }} 
        // MenuProps={{ PaperProps: paperProps }}
        InputProps={{
          style: {
            borderColor: 'red', // Set your desired border color
          },
          // endAdornment: (
          //   <InputAdornment position="end">
          //     <IconButton>
          //       {/* You can add an icon here if needed */}
          //     </IconButton>
          //   </InputAdornment>
          // ),
        }}
        >
        {props.options &&
          props.options.map((option) => (
            <MenuItem key={option.id} value={option.id} style={menuStyle}>
              {props.labelCondition === 'role' ? option.role :
                (props.labelCondition === 'plant' ? option.plant :
                (props.labelCondition === 'name' ? option.name :
                  (props.labelCondition === 'function' ? option.function : null)))}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

