import React from 'react';
import TextBox from '../../pages/input/TextBox';
import { Typography } from '@mui/material';
import { vechicleInfo } from '../recoil/atoms';
import { useRecoilState } from 'recoil';
import DateTimeMonth from "../input/DateTimeMonth"
import dayjs from 'dayjs';
import moment from 'moment';
import PropTypes from 'prop-types';


const VehicleInfo = ({ stepperError }) => {
  const [vechInfo, setvechInfo] = useRecoilState(vechicleInfo);
  const vechileInfoOnchange = (e) => {
    const { name, value } = e.target;
    setvechInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const handleDateChange = (e, key) => {
    const updatedValue = e.target.value;

    console.log(`Changing ${key} to:`, updatedValue);

    setvechInfo((prevVechInfo) => ({
      ...prevVechInfo,
      [key]: updatedValue,
    }));
  };

  console.log("stepperError3 ", stepperError);
  return (
    <div style={{ margin: "15px" }}>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="vehicle_Model"
          label="Vehicle Model *"
          value={vechInfo.vehicle_Model}
          isString={true}
          onChange={vechileInfoOnchange}
          error={stepperError?true:false}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="vin_Number"
          label="VIN Number"
          value={vechInfo.vin_Number}
          onChange={vechileInfoOnchange}
          // error={stepperError && !vechInfo.vin_Number ? true : false}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          id="toDate"
          label="Date of Sale *"
          type="date"
          value={vechInfo.date_sale ? dayjs(vechInfo.date_sale).format('YYYY-MM-DD') : ""}
          onChange={(e) => handleDateChange(e, "date_sale")}
          error={stepperError?true:false}
        />
      </div>

      {/* <div style={{ marginTop: "20px" }}>
        <DateTimeMonth
          name="Production_Date"
          lableVarient="label"
          lableValue="Production Date"
          isLable={true}
          value={vechInfo.Production_Date ?dayjs(vechInfo.Production_Date) :""}
          isString={true}
          onChange={(e) => handleDateChange(e, "Production_Date")}
        />
      </div> */}

      {/* 
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="part_Name"
          label="Part Name"
          value={vechInfo.part_Name}
          isString={true}
          onChange={vechileInfoOnchange}
          error={stepperError && !vechInfo.part_Name ? true : false}
        />
      </div> */}
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="Total_kms_Covered"
          label="Total Kms Covered"
          value={vechInfo.Total_kms_Covered}
          isString={true}
          onChange={vechileInfoOnchange}
          // error={stepperError && !vechInfo.Total_kms_Covered ? true : false}
        />
      </div>
    </div>


  );

}
VehicleInfo.propTypes = {
  stepperError: PropTypes.bool.isRequired,
};
export default VehicleInfo;