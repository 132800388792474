import React,{useState}from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useRecoilState,useRecoilValue } from 'recoil';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axios from 'axios';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SuccessPopup from '../input/successPopup';
import DealerInfoActiveIcon from '../../assets/icons/ic_user_profile_blue.svg';
import VehicleInfoIcon from '../../assets/icons/ic_truck_light_blue.svg';
import GlassInfoIcon from '../../assets/icons/ic_glass_info_light_bue.svg';
import NatureofComplaintIcon from '../../assets/icons/ic_nature_light_blue.svg';
import { useNavigate } from "react-router-dom";
import VehicleInfoActiveIcon from '../../assets/icons/ic_truck_blue.svg';
import GlassInfoActiveIcon from '../../assets/icons/ic_glass_info_blue.svg';
import NatureofComplaintActiveIcon from '../../assets/icons/ic_nature_of_complaint_blue.svg';
import CircularProgress from '@mui/material/CircularProgress';
import DealerInfoCompletedIcon from '../../assets/icons/ic_user_profile_white.svg';
import VehicleInfoCompletedIcon from '../../assets/icons/ic_truck_white.svg';
import GlassInfoCompletedIcon from '../../assets/icons/ic_glass_info_white.svg';
import NatureofComplaintCompletedIcon from '../../assets/icons/ic_nature_of_complaint_white.svg';
import {
  delearInfo,vechicleInfo,glassInfo,popupShow,error,NatureofComplaintInfo
} from "../recoil/atoms";

import DealerInfo from './DealerInfo';
import VehicleInfo from './VehicleInfo';
import GlassInfo from './GlassInfo';
import NatureofComplaint from './NatureofComplaint';
import CustomButton from '../input/CustomButton';
import API from '../sevices/Api';


const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: 'transparent', // Set the background color to transparent
    borderRadius: 1,
    // Add dashed border style
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderColor: '#0D508B', // Adjust the color as needed
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E8E8E8', // Initial transparent border
  },
  active: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #0D508B',
    // Blue border when active
  },
  completed: {
    backgroundColor: '#0D508B', // No background when completed
    borderColor: 'transparent', // No border when completed
  },
}));



// Define the icons mapping
const stepIcons = {
  1: {
    default: DealerInfoActiveIcon,
    active: DealerInfoActiveIcon,
    completed: DealerInfoCompletedIcon,
  },
  2: {
    default: VehicleInfoIcon,
    active: VehicleInfoActiveIcon,
    completed: VehicleInfoCompletedIcon,
  },
  3: {
    default: GlassInfoIcon,
    active: GlassInfoActiveIcon,
    completed: GlassInfoCompletedIcon,
  },
  4: {
    default: NatureofComplaintIcon,
    active: NatureofComplaintActiveIcon,
    completed: NatureofComplaintCompletedIcon,
  },
};

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icon = Object.prototype.hasOwnProperty.call(stepIcons, props.icon) ? stepIcons[props.icon] : {};

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <img src={active ? (icon.active || icon.default) : completed ? (icon.completed || icon.default) : icon.default} alt={`Step ${props.icon}`} />
    </div>
  );
}


ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop:"10px"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Dealer Information',
    'Vehical Information',
    'Glass Information',
    'Nature of Complaint',];
}


const access_token = localStorage.getItem("accesstoken");

const Assessment = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const[dealerValidate]=useRecoilState(delearInfo);
  // const[vechileValidate]=useRecoilState(vechicleInfo);
  // const[glassValidate]=useRecoilState(glassInfo);
  const [stepperError,setStepperError] = useRecoilState(error);
  const [msg, setMsg] = useState("");
  const [, setShow] = useState(false);
  const [, setOpen] = useRecoilState(popupShow);
  const [popupError, setPopuperror] = useState(false);
  console.log(stepperError, "stepperErrorassessment");
 

  const steps = getSteps();
  const delarInformation = useRecoilValue(delearInfo);
  const vechileInformation = useRecoilValue(vechicleInfo);
  const glassInformation = useRecoilValue(glassInfo);
  const natureCompInfo = useRecoilValue(NatureofComplaintInfo);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DealerInfo  stepperError={stepperError}/>;
      case 1:
        return <VehicleInfo  stepperError={stepperError}/>;
      case 2:
        return <GlassInfo  stepperError={stepperError}/>;
      case 3:
        return <NatureofComplaint />;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    // let validation = checkAllMandatoryFields();
    // if (validation) {
    //   setStepperError(true);
    //   return true;
    // }
    // else{
    //   setStepperError(false);
    // }  const areMandatoryFieldsFilled = checkAllMandatoryFields();

    if (areMandatoryFieldsFilled) {
      // Reset stepperError to false after successful data insertion
      setStepperError(false);
    } else {
      // Set stepperError to true when mandatory fields are not filled
      setStepperError(true);
    }

    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onHandleClosed = () => {
    setShow(false);
    setPopuperror(false);
    setOpen(false);
  };

//   const checkAllValues = (value, mandatoryFields) => {
//     let validation = false;
//     for (let i = 0; i < mandatoryFields.length; i++) {
//      if (!value[mandatoryFields[i]]) {
//       return true
//     }  
//   }
//     return validation; 
// }
    

//   const checkAllMandatoryFields = () => {
//     const stepper1MandatoryFields = ["dateComplaint_record", "complaintRecord_customer","dealer_Name","dealer_Address","contact_Person","customer_Name"];
//     let stepper1Validattion = checkAllValues(
//       dealerValidate,
//       stepper1MandatoryFields
//     );
//     if (stepper1Validattion) {
//       setActiveStep(0);
//       return true;
//     }
//     const stepper2MandatoryFields = ["vehicle_Model", "vin_Number","date_sale","Production_Date","part_Name","Total_kms_Covered"];
//     let stepper2Validattion = checkAllValues(
//       vechileValidate,
//       stepper2MandatoryFields
//     );
//      if(stepper2Validattion){
//       setActiveStep(1);
//       return true;
//     }
//     const stepper3MandatoryFields = ["glass_Batchcode", "glass_status"];
//     let stepper3Validattion = checkAllValues(
//       glassValidate,
//       stepper3MandatoryFields
//     );
//      if(stepper3Validattion){
//       setActiveStep(2);
//       return true;
//   }
// }


  const handleSubmit = () => {
    setIsLoading(true);
    dataInsert();
  };


  
  const dataInsert = () => {
    let url =API.INSERT_ASSESSMENT
    axios.post(url, {
      dealer_info:delarInformation,vehicle_info:vechileInformation,glass_info:glassInformation,nature_of_complaint:natureCompInfo,status:"In-Progress"
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
    .then((data) => {
      if (data.data.raw.length > 0) {
      setIsLoading(false);
      navigate(`/manageassessment`);
      setOpen(true);
      setMsg("Submitted  successfully");
      setPopuperror(false);
      console.log(popupError,"check");
      }
      else {
        setPopuperror(true);
    }
    });
    
  };

  return (
    <div className={classes.root}>
       {isLoading && <div className='loader'><CircularProgress /> </div>}
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', padding:"20px" }}>
              <CustomButton
                disabled={activeStep === 0}
                onClick={handleBack}
                text="Back"
              />
              <div style={{marginLeft:"20px"}}></div>
                <CustomButton
                disabled={activeStep === 3}
                text={"Next"}
                onClick={handleNext}
                style={{ textTransform: 'none',}}
              />
              {
                activeStep === steps.length -1 &&
                <>
                 <div style={{marginLeft:"20px"}}></div>
                 <CustomButton
                 disabled={activeStep === 0}
                text={"Submit"}
                onClick={handleSubmit}
                style={{ textTransform: 'none',}}
              />
                </>
               
              } 
            </div>
          </div>
        )}
      </div>
      { popupError ? (
                    <SuccessPopup
                        style={{ width: 400 }}
                        open={ popupError}
                        onClose={onHandleClosed}
                        popupError={popupError}
                        msg={msg}

                     />
                ) : null}
    </div>
  );
}
export default Assessment;
