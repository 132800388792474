
const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.windshieldidna.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/chryso";
    default:
      return "http://localhost:3000";
  }
};

const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.feapi.windshieldidna.saint-gobain.com/";
    case "prod":
      return "https://dc02.saint-gobain.com/api/aim/v2";
    default:
      return "http://localhost:8080/";
  }
};

const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_WS_Crack_Assessment&redirect_uri=https://dev.windshieldidna.saint-gobain.com/login&service=simple&scope=stGoSGI";
    case "prod":
      return "https://dc02.saint-gobain.com/aimapi/v1/graphql";
    default:
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_WS_Crack_Assessment&redirect_uri=http://localhost:3000/login&service=simple&scope=stGoSGI";
  }
};

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));


export const configParam = {
  DEEP_COPY: deepCopy,
  API_URL: _get_api_url(),
  APP_URL: _get_app_url(),
  SSO_URL : _get_sso_url(),
  BLOB_CLIENT:"https://wscrackassessment.blob.core.windows.net/files/",
  SAS_TOKEN:"/sp=racwdl&st=2023-11-14T04:48:44Z&se=2024-12-01T12:48:44Z&spr=https&sv=2022-11-02&sr=c&sig=UJJitmEP9P47yy4A3Moeh2B6lUKESUCXVFXJIkn3kh4%3D"
};

export default configParam;
