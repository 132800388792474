import React from 'react';
import TextBox from '../../pages/input/TextBox';
import { Typography } from '@mui/material';
import { glassInfo } from '../recoil/atoms';
import { useRecoilState } from 'recoil';
import DateTimeMonth from "../input/DateTimeMonth"
import dayjs from 'dayjs';
import moment from 'moment';
import Helper from '../sevices/Helper';
import SelectBox from '../input/SelectBox';

const GlassInfo = ({stepperError}) => {
  const [glassIn, setglassIn] = useRecoilState(glassInfo);

  const glassInfoOnchange = (e) => {
    const { name, value } = e.target;
    setglassIn(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  console.log("stepperError2 ", stepperError);

  console.log("glassIn",glassIn);

const handleDateChange = (e, key) => {
  const updatedValue = e.target.value;

  setglassIn((prevVechInfo) => ({
    ...prevVechInfo,
    [key]: updatedValue,
  }));
};
  return (
    <div style={{ margin: "15px" }}>
    
      <div style={{ marginTop: "20px" }}>
      <TextBox
        id="toDate"
        label="Production Date *"
        type="date"
        value={glassIn.Production_Date ?dayjs(glassIn.Production_Date).format('YYYY-MM-DD') : ""}
        onChange={(e) => handleDateChange(e, "Production_Date")}
        error={stepperError?true:false}
      />
    </div>


      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="part_Name"
          label="Part Name"
          value={glassIn.part_Name}
          isString={true}
          onChange={glassInfoOnchange}
          // error={stepperError && !glassIn.part_Name ? true : false}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="part_number"
          label="Part Number"
          value={glassIn.part_number}
          onChange={glassInfoOnchange}
          // error={stepperError && !glassIn.part_number ? true : false}
        />
      </div>
      
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="glass_Batchcode"
          label="Glass Batch Code"
          value={glassIn.glass_Batchcode}
          isString={true}
          onChange={glassInfoOnchange}
          // error={stepperError && !glassIn.glass_Batchcode ? true : false} 
        />
      </div>

      <div style={{marginTop:"20px"}}>
            <SelectBox
            labelVariant="body2"
            isLable={true}
            lableValue="Glass Status *"
            options={Helper.GlassStatusArray}
            value={glassIn.glass_status}
            onChange={glassInfoOnchange}
            name="glass_status"
            labelCondition="name"
            error={stepperError?true:false}
          />
          </div>
    </div>
  ); 
}

export default GlassInfo;