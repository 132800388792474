import React, { useRef, useState, useEffect } from 'react';
import TextBox from '../../pages/input/TextBox';
import { Button, Typography } from '@mui/material';
import CloudUploadIcon from '../../assets/icons/ic_cloud_upload.svg';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import { Paper, Popover, } from '@material-ui/core';
import { imageType, NatureofComplaintInfo, chooseImages,videoType,nocimageType } from "../recoil/atoms"
import { useRecoilState, useRecoilValue } from 'recoil';
import ImageSlider from '../input/imageSlider';
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import CapturePhoto from './manageassessment/CapturePhoto';
import CaptureVideo from './manageassessment/CaptureVideo';
import VideoSlider from '../input/VideoSlider';
import Swal from 'sweetalert2';
import API from '../sevices/Api';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../input/CustomButton';

const access_token = localStorage.getItem("accesstoken");
const NatureofComplaint = () => {
  const fileInputRef = useRef(null);
  const videoInputRef=useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorVideoEl, setAnchorVideoEl] = useState(null);
  const [selectedImages, setSelectedImages] = useRecoilState(imageType);
  const [selectednocImages,setSelectedNocImages]=useRecoilState(nocimageType);
  const [selectedVideo, setSelectedVideos] = useRecoilState(videoType);
  const [isEdit,] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showVideoComponent, setShowVideoComponent] = useState(false);
  const [natureInfo, setNatureInfo] = useRecoilState(NatureofComplaintInfo);
  const [captureImage, setCaptureImage] = useRecoilState(chooseImages);
  // const captureImageArray = useRecoilValue(chooseImages);
  const [alertVisible, setAlertVisible] = useState(true);
  const [DelImages, setDelImages] = useState([]);
  // const [selectedImages, setSelectedImages] = useState([]);
  const useEffectTriggeredRef = useRef(false);
  const navigate = useNavigate();

  console.log("natureInfo", natureInfo);
  console.log("NatureInfoImageList", natureInfo.imageList);



  useEffect(() => {
    if (natureInfo && natureInfo.imageList && natureInfo.videoList) {
      // Assuming natureInfo.imageList is an array of image URLs
      setSelectedImages(natureInfo.imageList);
      setSelectedVideos(natureInfo.videoList)
      setSelectedNocImages(natureInfo.imageList_noc);
      
    }
  }, [natureInfo]);

useEffect(() => {
    if (natureInfo && natureInfo.imageList) {
      // Assuming natureInfo.imageList is an array of image URLs
      setSelectedImages(natureInfo.imageList);
      setSelectedNocImages(natureInfo.imageList_noc);
    }
  }, [natureInfo]);
  // useEffect(()=>{
  //   const getImageDataUris = captureImageArray;
  //   let mergedArray = [...getImageDataUris, ...selectedImages];

  //   setDelImages(mergedArray);

  //     // Check if natureInfo has an imageList property
  //     if (natureInfo && natureInfo.imageList) {
  //       // const imagesWithUrls = natureInfo.imageList.map((imageName) => {
  //       //   const baseUrl = configParam.BLOB_CLIENT;
  //       //   const sasToken = configParam.SAS_TOKEN;
  //       //   return `${baseUrl}${imageName}${sasToken}`;
  //       // });
  //       // console.log("natureInfo.imageList",  imagesWithUrls);
  //       setDelImages(natureInfo.imageList);
  //     }
  //   }, [captureImageArray, selectedImages, natureInfo, setDelImages]);



  const natureInfoOnchange = (e) => {
    const { name, value } = e.target;
    setNatureInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleCloseAlert = () => {
    setAlertVisible(false);
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files; // Get the selected files from the input
    const maxAllowedFiles = 5;

    // Check if the number of selected files exceeds the maximum allowed
    if (files.length > maxAllowedFiles) {
      alert(`You can only select up to ${maxAllowedFiles} images.`);
      // Clear the file input to prevent uploading more files
      fileInputRef.current.value = null;
    } else {
      // Process the selected files here
      const loadedImages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageUrl = e.target.result;

          // Push the loaded image URL to the array
          loadedImages.push(imageUrl);

          // Check if all images are processed, then update the state
          if (loadedImages.length === files.length) {
            // Update the state with the selected images
            setSelectedImages(prevState => [...prevState, ...loadedImages]);
            setSelectedNocImages(prevState => [...prevState, ...loadedImages]);

            // Display the selected images
            loadedImages.forEach((loadedImageUrl) => {
              const image = new Image();
              image.src = loadedImageUrl;
              document.body.appendChild(image);
            });

            // Now, you can make the API call or perform any other actions if needed
            // uploadBlobImagesAPI(selectedImages);
          }
        };

        reader.readAsDataURL(files[i]); // Read the selected file as a data URL
      }
    }
  };


  useEffect(() => {
    console.log(selectedImages, "selectedImages");
    console.log("selectedvideosss", selectedVideo);
  }, []);

  // useEffect(() => {
  //   if (!useEffectTriggeredRef.current) {
  //     console.log("useEffect triggered");

  //     console.log("selectedImages", selectedImages);

  //     const getImageDataUris = captureImage.map((item) => item.imageDataUrl);
  //     const mergedArray = [...getImageDataUris, ...selectedImages];

  //     console.log("Merged Array:", mergedArray);

  //     setSelectedImages((prevState) => {
  //       if (!arraysAreEqual(prevState, mergedArray)) {
  //         return mergedArray;
  //       }
  //       return prevState;
  //     });

  //     setCaptureImage((prevState) => {
  //       if (!arraysAreEqual(prevState, mergedArray)) {
  //         return mergedArray;
  //       }
  //       return prevState;
  //     });


  //     useEffectTriggeredRef.current = true;
  //   }

  //   // Your logic for capturing an image and updating captureImageArray
  //   // ...

  //   function arraysAreEqual(arr1, arr2) {
  //     return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
  //   }
  // }, [captureImage, selectedImages]);



  // const handleSubmit = () => {

  //   const imagesData = [];

  //   // Loop through the array of imageDataUrls
  //   selectedImages.forEach((imageDataUrl, index) => {
  //     const imageType = imageDataUrl.substring(
  //       imageDataUrl.indexOf('/') + 1,
  //       imageDataUrl.indexOf(';')
  //     );
  //     const imageBase64 = imageDataUrl.substring(
  //       imageDataUrl.indexOf(',') + 1
  //     );
  //     const imageName = `image_${Date.now()}_${index}.${imageType}`;

  //     // Add each image data to the array
  //     imagesData.push({
  //       imageName,
  //       imageBase64,
  //       imageType,
  //     });
  //     uploadBlobImagesAPI(imagesData);
  //   });
  // }
  const handleFileSelectGallery = async (event) => {
    if (event.target && event.target.files) {
      // Rest of your file handling logic
      const selectedFiles = event.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      const fileNames = selectedFilesArray.map((file) => file.name);
      const fileType = selectedFilesArray.map((file) => file.type);
      console.log("filess", fileType, fileNames);
      const imagePromises = selectedFilesArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
            resolve(base64Image);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      });

      try {
        const base64Images = await Promise.all(imagePromises);
        console.log("imagebase",base64Images)
        setSelectedImages(base64Images);
        setShowVideoComponent(false)
        uploadBase64Image(base64Images, fileNames, fileType);

      } catch (error) {
        console.error("Error reading files:", error);
      }
    } else {
      console.error("Event object or its 'files' property is undefined.");
    }
  };


  const getFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/');
    const fileName = pathParts[pathParts.length - 1];
    return fileName;
  };

  // upload video Function
  const handleFileVideoSelectGallery = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    console.log("selectedFilesArray", selectedFilesArray)
    const videoPromises = selectedFilesArray.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Video = e.target.result;
          resolve(base64Video);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    });


    try {
      const base64Videos = await Promise.all(videoPromises);
      console.log("videobase",base64Videos)
      setSelectedVideos(base64Videos);
    } catch (error) {
      console.error("Error reading files:", error);
    }
  };




  const uploadBlobImagesAPI = async (images) => {
    try {
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      const imageNames = imagesArray.map((image) => image.url);
      setNatureInfo((prev) => ({
        ...prev,
        imageList: [...prev.imageList, ...imageNames],
      }));


      // for (let i = 0; i < imagesArray.length; i++) {
      //   const imageName = imagesArray[i].name;
      //   setNatureInfo((prev) => ({
      //   ...prev,
      //   imageList: [ ...imageName],  
      // }));
      // }



    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };


  const handleDeleteIconClick = (imageUrl, index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.isConfirmed) {
        setNatureInfo((prevNatureInfo) => {
          if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
            const imageName = getFileNameFromUrl(imageUrl);
            console.log("imageName", imageName);
            const urlObject = new URL(imageUrl);
            const pathParts = urlObject.pathname.split('/');
            console.log("pathpath",pathParts)
            const foldername=pathParts[2]
            const claimnumber=pathParts[3]
            deleteExistingImageFromBlob(imageName,foldername,claimnumber);
  // Process as HTTP URL
}
         
          const newImageList = [...prevNatureInfo.imageList];
          const newImageNocList=[...prevNatureInfo.imageList_noc];
          newImageList.splice(index, 1);
          newImageNocList.splice(index, 1);
          return {
            ...prevNatureInfo,
            imageList: newImageList,
            imageList_noc:newImageNocList,
          };
        });

        Swal.fire('Deleted!', 'Your image has been deleted.', 'success');
      }
    });
  }


  const deleteExistingImageFromBlob = async (imageName,foldername,claimnumber) => {
    try {
      const response = await axios.delete(`${API.DELETE_IMAGE}/${imageName}/${foldername}/${claimnumber}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("response for delete image", response);
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }


  async function uploadBase64Image(base64Images, fileNames, fileType) {
    let url = 'http://localhost:3045/upload_allImages';
    const imageObjects = base64Images.map((base64, index) => ({ image: base64, imageName: fileNames[index], fileType: fileType[index] }));
    await axios.post(url, { image: imageObjects }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((response) => {
        console.log('Upload successful!', response.data);
        const responseData = response.data;
        console.log(responseData);

        // setNatureInfo((prevNatureInfo) => ({
        //   ...prevNatureInfo,
        //   imageList: ["2023-08-17_1692251026291-before_0823_009.png","2023-08-17_1692251105234-before_0823_009.png"]
        // }));


        //  start
        // console.log("natureInfo", natureInfo);
        // console.log('Upload successful!', response.data.imageName);
        // const responseData = response.data.imageName;
        // setNatureInfo((prev) => ({
        //   ...prev,
        //   imageList: [ ...responseData],  
        // }));

        //  endedddd
        console.log("responseData", natureInfo);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      })

  }
  console.log("responseData", natureInfo);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleVideoClose = () => {
    setAnchorVideoEl(null);
  };


  const handleCamera = () => {
    // setShowComponent(true);
    // setAnchorEl(false)
    navigate('/capturephoto');
  }

  const handleVideoCamera = () => {
    navigate(`/videorecorder`);

    // setShowVideoComponent(true);
    // setShowComponent(false);
  }


  const handleGallery = () => {
    fileInputRef.current.click();

  }
  const handleVideoGallery = () => {
    videoInputRef.current.click();

  }
  const popoverContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>
      <div onClick={handleCamera} style={{ display: 'flex', padding: 20, }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Camera</Typography>
      </div>
      <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
          Upload from Gallery
        </Typography>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple
        />
      </div>

    </div>
  );

  // VideoPOPUP Content
  const VideoContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>
      <div onClick={handleVideoCamera} style={{ display: 'flex', padding: 20, }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Video Camera</Typography>
      </div>
      <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
          Upload Video from Gallery
        </Typography>
        <input
          type="file"
          ref={fileInputRef} // Assign the input element to the ref
          style={{ display: 'none' }}
          onChange={handleFileVideoSelectGallery}
          accept="video/*"
          multiple // Allow multiple file selection
        />
      </div>

    </div>
  );


  const handlePopover = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleVideoPopover = (event) => {
    event.preventDefault();
    setAnchorVideoEl(event.currentTarget);

  };
  console.log("selectedvideos1", selectedVideo);
  // const handleAddIconClick = (event) => {
  //   fileInputRef.current.click();

  //   handleFileSelectGallery(event);
  // }
  return (
    <div style={{ margin: "15px" }}>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="defect_Location"
          label="Defect Location"
          value={natureInfo.defect_Location}
          isString={true}
          onChange={natureInfoOnchange}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="customer_Voice"
          label="Customer Voice"
          value={natureInfo.customer_Voice}
          isString={true}
          onChange={natureInfoOnchange}

        />
      </div>

      {DelImages.length === 0 &&
        <div style={{ marginTop: "20px" }}>
          <Typography style={{ textAlign: "left" }}>Defect Image</Typography>
          <div style={{
            border: "2px dashed #00448B",
            padding: "20px",
            flex: 1,
            display: 'flex',
            width: "100%",
            height: 150,
            marginTop: 10,
            backgroundColor: '#00448a1a',
            marginRight: 20,
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: "4px"
          }}>
            <img onClick={handleGallery} src={CloudUploadIcon} style={{
              width: 30,
              height: 30,
              marginLeft: 10,
              marginBottom: 10,
            }} alt="My Image" />
            <Typography style={{
              textAlign: 'center',
              marginLeft: 15,
              color: 'black',
              fontWeight: 600,
              width: 170,
              fontSize: 16
            }}>Upload your Images or Browse</Typography>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              multiple
            />
          </div>
        </div>
      }
      {DelImages.length > 5 && alertVisible && (
        <Alert
          style={{ width: "100%", marginTop: 20 }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleCloseAlert}

            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px",
          }}
        >
          <p style={{ textAlign: "center" }}>Please select up to 5 images</p>
        </Alert>
      )}


      <div>
        {showComponent && <CapturePhoto />}
      </div>
      <div style={{ marginTop: "30px" }}>
      {console.log(selectedImages, "SELECTED")}

        {
          selectedImages.length > 0 && selectedImages.length <= 5 &&
          <div>
            <ImageSlider
              images={selectedImages}
              isEdit={isEdit}
              handlePopover={handlePopover}
              onDeleteIconClick={handleDeleteIconClick}
            />
          </div>
        }
      </div>
      <div>
        {selectedImages.length > 5 && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            You can only select up to 5 images.
          </div>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <Typography style={{ textAlign: "left" }}>Defect Video (Optional)</Typography>
        <div style={{
          border: "2px dashed #00448B",
          padding: "20px",
          flex: 1,
          display: 'flex',
          width: "100%",
          height: 150,
          marginTop: 10,
          backgroundColor: '#00448a1a',
          marginRight: 20,
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px"
        }}>
          <img onClick={handleVideoGallery} src={CloudUploadIcon} style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
          }} alt="My Image" />
          <Typography style={{
            textAlign: 'center',
            marginLeft: 15,
            color: 'black',
            fontWeight: 600,
            width: 170,
            fontSize: 16
          }}>Upload your Videos or Browse</Typography>
            <input
          type="file"
          ref={videoInputRef} // Assign the input element to the ref
          style={{ display: 'none' }}
          onChange={handleFileVideoSelectGallery}
          accept="video/*"
          multiple // Allow multiple file selection
        />
        </div>
      </div>

      <div>
        {showVideoComponent && <CaptureVideo />}
      </div>
      <div style={{ marginTop: "30px" }}>
        {
          selectedVideo.length > 0 &&
          <VideoSlider
            videos={selectedVideo}
            setSelectedVideos={setSelectedVideos}
            natureInfo={natureInfo}
            setNatureInfo={setNatureInfo}

          />
        }
      </div>
      {/* camera POPUP */}
      <div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper style={{ width: '100%' }}>{popoverContent}</Paper>
        </Popover>
      </div>
      {/* Video POPUP */}
      <div>
        <Popover
          open={Boolean(anchorVideoEl)}
          anchorVideoEl={anchorVideoEl}
          onClose={handleVideoClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper style={{ width: '100%' }}>{VideoContent}</Paper>
        </Popover>
      </div>
    </div>


  );

}

export default NatureofComplaint;