import { useRecoilState } from 'recoil';
import React, { useEffect, useRef, useState } from "react";
import {   useNavigate , useLocation} from "react-router-dom";
import CircleIcon from '../../../assets/icons/ic_take_picture.svg';
import CloseIcon from '../../../assets/icons/ic_close1.svg';
import switchCamera from '../../../assets/icons/ic_switch_camera.png';
import{chooseImages, imageType}from"../../recoil/atoms"


const CapturePhoto = () => {
  const location = useLocation();
  const [, setImageSrc] = useState('https://i.ibb.co/RjYk1Ps/2817290-eps-1.png');
  const [hasPermission, setHasPermission] = useState(false);
  const recordData = location.state ? location.state.selectedRecordData : null
  const videoRef = useRef(null);
  // const [captureImage,setCaptureImage]=useRecoilState(chooseImages);
  const [selectedImages, setSelectedImages] = useRecoilState(imageType);


  const navigate = useNavigate();

  useEffect(() => {
    navigator.permissions.query({ name: 'camera' })
      .then(permissionStatus => {
        console.log('Camera permission status:', permissionStatus.state);
        setHasPermission(permissionStatus.state === 'granted');
      });
  }, []);

  useEffect(() => {
    if (hasPermission) {
      getVideo();
    }
  }, [hasPermission]);

  useEffect(() => {
    navigator.permissions.query({ name: 'camera' })
      .then(permissionStatus => {
        console.log('Camera permission status:', permissionStatus.state);
        setHasPermission(permissionStatus.state === 'granted');
      });

    getVideo();
  }, [videoRef]);

  const handleCamera = async () =>{
    try {
      const constraints = { video: { facingMode: "environment" } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          if (video.readyState >= video.HAVE_ENOUGH_DATA) {
            video.play();
          }
        };
        video.width = window.innerWidth;
        video.height = window.innerHeight;
      }
    } catch (error) {
      console.error("Error accessing back camera:", error);
    }

  }
  
  const getVideo = () => {
    let video = videoRef.current;
    if (video && !video.srcObject) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: window.innerWidth, height: window.innerWidth } })
        .then(stream => {
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            if (video.readyState >= video.HAVE_ENOUGH_DATA) {
              video.play();
            }
          };
        })
        .catch(err => {
          console.error("error:", err);
        });
    }
  };

  function goBack() {
    navigate(-1)
  }

  const takePhoto = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    // Draw video frame onto canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0);
    // Get image data URL from canvas
    const imageDataUrl = canvas.toDataURL();

    console.log("imageDataUrl",imageDataUrl);

    setSelectedImages((prevImages) => prevImages ? [...prevImages, imageDataUrl] : [imageDataUrl]);


  //   const imageType = imageDataUrl.substring(imageDataUrl.indexOf('/') + 1, imageDataUrl.indexOf(';'));
  //  const imageBase64 = imageDataUrl.substring(imageDataUrl.indexOf(',') + 1);
  //  const imageName = `image_${Date.now()}_${Math.random()}.${imageType}`;
  //   newImages.push({ imageBase64, imageName, imageType });
  //  setCaptureImage(prevImages => [...prevImages, { imageDataUrl }]);

    navigate('/complaintDetails')

  };
  
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black', overflow: 'hidden' }}>
     {/* <div style={{ position: 'absolute', top: 50, right: 10, margin: '10px', zIndex: 1 }}>
        <img onClick={handleCamera} style ={{width:"35px", height:"35px"}} src={switchCamera} alt="Switch Camera" />
      </div> */}
      <div style={{margin:'10px', borderRadius:'8px'}}>
      <video ref={videoRef} style={{width: '100%', height: 'calc(100vh - 50px)',borderRadius:'8px'}} />
      </div>
      <div style={{
        position: 'absolute',
        bottom: '30px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
        <div style={{marginLeft:25, float:'left',}}> 
          <img onClick={handleCamera} src={switchCamera} style={{width:"33px", height:"33px"}} alt="My Left Image" />
        </div>
        <div style={{ textAlign: 'center' }}>
          <img onClick={takePhoto} src={CircleIcon} style={{ width: 50, height: 50 }} alt="My Center Image" />
        </div>
        <div style={{ float: 'right', marginRight: 25 }}>
          <img onClick={goBack} src={CloseIcon} alt="My Right Image" />
        </div>
      </div>
    </div>
  );
  

};
export default CapturePhoto;