import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import AddIcon from '../../assets/icons/ic_add.svg';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import service from '../sevices/Api';
import axios from 'axios';
import Card from "@material-ui/core/Card";
import deleteIcon from '../../assets/icons/ic_delete.svg';
import editIcon from '../../assets/icons/ic_edit.svg';
import FilterIcon from '../../assets/icons/ic_filter.svg';
import MenuIcon from '../../assets/icons/ic_menu.svg';
import Helper from '../sevices/Helper';
import LeftDrawer from '../drawer/LeftDrawer';
import AlertDialog from '../input/AlertDialog';
import { CircularProgress } from '@material-ui/core';
import { Help } from '@material-ui/icons';


const useStyles = makeStyles({
  containerStyle: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px", // Adjust the padding as needed
  },
  userListStyle: {
    color: '#252C32',
    fontFamily: 'Inter',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: '600',
    fontWeight: '600 !important', // Add !important to the property value
    lineHeight: '29px',
    textAlign: 'left',
    color: '#333333',
  },
  addUserStyle: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    lineHeight: '19px',
    textAlign: 'left',
    color: "#333333 !important"
  },

})
const access_token = localStorage.getItem("accesstoken");
const UserManagement = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [deleting,] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
    try {
      setIsLoading(true);
      let url = service.GET_USERS;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      if (response.data.length > 0) {
        const data = response.data;
        setUserInfo(data);
      }
    } catch (error) {
      console.error(error);
      AlertDialog({
        type: 'error',
        title: 'Error',
        text: error,
      });
    }
    finally {
     setIsLoading(false); 
    }
  };


  
  const handleEdit = () => {
    setIsPopupOpen(false);
    navigate(`/newuser`, { state: { userInfo: selectedUser, isEdit: true } });
  }
  const handleCardClick = () => {
    navigate(`/profile`);
  }
  const handleMenuIconClick = (event, user) => {
    const rect = event.target.getBoundingClientRect();
    setIsPopupOpen(true);
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    });
    setSelectedUser(user);
  };
  const handleNewClick = () => {
    navigate('/newuser');
  }

  const rowDelete = async (user) => {
    try {
      setIsLoading(true);
      const url = service.DELETE_USER;
      await axios.delete(url, { data: { userId: user.id },headers: {
        Authorization: `Bearer ${access_token}`,
     
      } });
      // Update the user list in state after successful deletion
      const updatedUserInfo = userInfo.filter((u) => u.id !== user.id);
      setUserInfo(updatedUserInfo);
      AlertDialog({
        type: 'success',
        title: 'Sucesss',
        text: "User Deleted Successfully",
      });
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Failed to delete user:", error);
      AlertDialog({
        type: 'error',
        title: 'Error',
        text: error,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = () => {
    setIsPopupOpen(false);
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you really sure you want to delete this?',
      confirmButtonText: 'Yes',
      onConfirm: () => {
        if (deleting) {
          return;
        }
        rowDelete(selectedUser);
      },
    });
  };


  const filteredUserInfo = userInfo.filter((user) => {
    const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
    const plantName = Helper.plantData[user.plant_id].toLowerCase(); // Assuming plantData is an array of plants with an id property
    return fullName.includes(searchQuery.toLowerCase()) || plantName.includes(searchQuery.toLowerCase());
  });
  
 

  return (
    <div style={{ padding: 15 }}>
       {isLoading && (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(0, 0, 0, 0.3)' }}>
          <CircularProgress />
        </div>
      )}
      <LeftDrawer onClickFunction={handleNewClick} />
      <div className={classes.containerStyle}>
        <Typography className={classes.userListStyle}>User Management</Typography>
      </div>
      <div style={{ display: 'flex', marginTop: "5px", flexDirection: "column" }}>
        <Typography style={{ fontFamily: "Inter", fontWeight: "400px", fontSize: "16px", textAlign: "left", paddingLeft: "15px", paddingRight: "15px", color: "#909190" }}>The created assessments are listed with details on the timeline, stage, progress and other properties.</Typography>
      </div>
      <div style={{ flexDirection: 'row', display: 'flex', padding: '15px', maxWidth: '100%' }}>
        <img src={FilterIcon} alt="Filter Icon" />
        <div style={{ position: 'relative', flex: '1', paddingRight: "15px" }}>
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '14px',
              borderRadius: '5px',
              border: '2px solid #E8E8E8',
              marginLeft: '10px', // Add some left margin for spacing
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }} >
        {filteredUserInfo.map((user) => {
          const timestamp = user.created_at;
          const dateObject = new Date(timestamp);
          const formattedDate = dateObject.toLocaleDateString();
          const plantData = Helper.plantData;
          return (
            <Card
              key={user.id}
              style={{
                width: "100%",
                padding: 20,
                borderRadius: "8px",
                border: "1px solid #E8E8E8",
                background: "var(--black-white-white, #FFF)",
                display: "flex",
                alignItems: "center",
                marginBottom: "30px",
                boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
              variant="outlined"
            >
              {/* <Avatar {...stringAvatar((user.first_name + " " + user.last_name).toUpperCase(), 50)} /> */}
              <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>
                    {user.firstname.toUpperCase()} {user.lastname.toUpperCase()}
                  </Typography>
                  <img onClick={(event) => handleMenuIconClick(event, user)} src={MenuIcon}></img>
                </div>

                <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{
                    color: '#909190',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.072px',
                  }}>
                    Location
                  </Typography>
                  <Typography style={{
                    color: '#909190',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    marginRight: '40px',
                    letterSpacing: '-0.072px',
                  }}>
                    Added On
                  </Typography>
                </div>
                <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    letterSpacing: '-0.084px',
                  }}>
                    {plantData[user.plant_id]}

                  </Typography>
                  <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    marginRight: '40px',
                    letterSpacing: '-0.084px',
                  }}>
                    {formattedDate}
                  </Typography>
                </div>
              </div>

              {isPopupOpen && (
                <div style={{
                  position: 'absolute',
                  top: `${popupPosition.top}px`,
                  right: `${popupPosition.right}px`,
                  background: 'rgba(255, 255, 255, 1)',
                  padding: '20px',
                  borderRadius: '8px',
                  border: '1px solid #E8E8E8',
                  boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                  {/* Your popup content */}
                  <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    <img src={editIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />

                    <Typography
                      style={{
                        color: '#252C32',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}>Edit</Typography>
                  </div>
                  <div onClick={handleDelete} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    <img src={deleteIcon} alt="Delete Icon" style={{ marginRight: '10px' }} />
                    <Typography
                      style={{
                        color: '#FF3347',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}>Delete</Typography>
                  </div>
                </div>
              )}

            </Card>
          );
        })}
      </div>
    </div>
  );

}

export default UserManagement;