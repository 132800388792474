import React, { useState} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types'; // Import PropTypes

import { FieldInvestigation} from "../recoil/atoms"
import { useRecoilState } from 'recoil';
import TextBox from './TextBox';


// import Swal from 'sweetalert2';


const useStyles = makeStyles(() => ({
  imageSliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  },
  sliderImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: "10px"
  },
  arrowButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftArrow: {
    left: "10px",
  },
  rightArrow: {
    right: "10px",
  },
  arrowIcon: {
    fontSize: "40px",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "30px",
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  number: {
    width: "20px",
    height: "20px",
    backgroundColor: "#ccc",
    borderRadius: "50%",
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeNumber: {
    backgroundColor: "#00448B",
    color: "white",
    fontSize: "12px"
  },
  iconContainer: {
    position: "absolute",
    bottom: "2px",
    right: "2px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px !important"
  },
  addIcon: {
    fontSize: "30px",
    marginRight: "10px",
    cursor: "pointer",
    color: "#00448B",
  },
  deleteIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "red",
  },
}));
const ImageSliderText = ({ images, descriptions, handlePopover,onAddIconClick, onDeleteIconClick }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const[fieldData,setFieldData]= useRecoilState(FieldInvestigation);

  // Use descriptions prop or fill with empty strings if not provided
  const [descriptionValues, setDescriptionValues] = useState(descriptions || Array(images.length).fill(''));

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDeleteIconClick = () => {
    const clickedImageName = images[currentImageIndex];
    if (typeof onDeleteIconClick === 'function') {
      onDeleteIconClick(clickedImageName, currentImageIndex);
    }
  };

console.log("descriptionValues",descriptionValues)

  const handleAddIconClick = () => {
    const clickedImageName = images[currentImageIndex];
    if (typeof onAddIconClick === 'function') {
      onAddIconClick(clickedImageName, currentImageIndex);
    }
  };

  const handleInputChange = (event, currentImageIndex) => {
    // Make a copy of the descriptionValues array
    const updatedDescriptionValues = [...descriptionValues];
  
    // Update the description for the specified index
    updatedDescriptionValues[currentImageIndex] = String(event.target.value);
  
    // Update the local state with the new descriptionValues array
    setDescriptionValues(updatedDescriptionValues);
  
    // Check if fieldData.image_desc is defined and is an array
    if (fieldData && fieldData.image_desc && Array.isArray(fieldData.image_desc)) {
      // Make a copy of the image_desc array
      const updatedImageDesc = [...fieldData.image_desc];
  
      // Update the description for the specified index in image_desc
      updatedImageDesc[currentImageIndex] = {
        id: currentImageIndex.toString(),
        desc: String(event.target.value),
      };
  
      // Update the recoil state with the new image_desc array
      setFieldData((prevFieldData) => ({
        ...prevFieldData,
        image_desc: updatedImageDesc,
      }));
    } else {
      console.error("fieldData.image_desc is not defined or not an array");
    }
  
    // Check if fieldData.raw_images is defined and is an array
    if (fieldData && fieldData.raw_images && Array.isArray(fieldData.raw_images)) {
      // Make a copy of the raw_images array
      const updatedRawImages = [...fieldData.raw_images];
  
      // Update the description and set imageName for the specified index in raw_images
      updatedRawImages[currentImageIndex] = {
        imageDescription: String(event.target.value),
        imageName: updatedRawImages[currentImageIndex]?.imageName || '', // Set to empty string if undefined
      };
  
      // Update the recoil state with the new raw_images array
      setFieldData((prevFieldData) => ({
        ...prevFieldData,
        raw_images: updatedRawImages,
      }));
    } else {
      console.error("fieldData.raw_images is not defined or not an array");
    }
  };
  console.log("imagesimages",images)
  console.log("fielddd",fieldData)
  
  return (
    <div>
      <div className={classes.imageSliderContainer}>
        {/* Previous Button */}
        <button className={`${classes.arrowButton} ${classes.leftArrow}`} onClick={prevImage}>
          <KeyboardArrowLeftIcon className={classes.arrowIcon} />
        </button>

        {/* Image */}
        <img
          className={classes.sliderImage}
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
        />

        <div style={{ marginTop: "30px", width:"100%", display:"flex" , flex:1, flexDirection:"column"}}>
              <TextBox
                name={`Observation/Findings for Image ${currentImageIndex + 1}`}
                label={`Observation/Findings for Image ${currentImageIndex + 1}`}
                value={descriptionValues[currentImageIndex]}
                isString={true}
                style={{ width: '100%', marginTop: '30px', height: '100px' }}  // Adjust the height property as needed  
                onChange={(event) => handleInputChange(event, currentImageIndex)}
                // error={formErrors.firstName}
              />
              </div>
{/* 
        TextField for each image
        <div style={{ marginTop: "30px",}}>

          
          <TextField  
            label={`Observation/Findings for Image ${currentImageIndex + 1}`}
            variant="outlined"
            value={descriptionValues[currentImageIndex]}
            onChange={(event) => handleInputChange(event, currentImageIndex)}
          />
        </div> */}

        {/* Next Button */}
        <button className={`${classes.arrowButton} ${classes.rightArrow}`} onClick={nextImage}>
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </button>

        {/* Number Container */}
        <div className={classes.numberContainer}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`${classes.number} ${index === currentImageIndex ? classes.activeNumber : ''}`}
              onClick={() => setCurrentImageIndex(index)}>
              {index + 1}
            </span>
          ))}
        </div>

        {/* Icon Container */}
        <div className={classes.iconContainer}>
          {/* Add Icon */}
          <AddIcon className={classes.addIcon} onClick={handlePopover} />

          {/* Delete Icon */}
          <DeleteIcon className={classes.deleteIcon} onClick={handleDeleteIconClick} />
        </div>
      </div>
    </div>
  );
};

export default ImageSliderText;

