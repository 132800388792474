import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
// import Logo from '../../assets/icons/ic_empower_logo.svg';
import { makeStyles,Typography,Button } from '@material-ui/core';
import configParam from "../../config";
import Logo from '../../assets/icons/ic_wsca_logo.png';
import SekuritLogo from '../../assets/icons/ic_sekurit_logo.png'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

const useStyles = makeStyles(() => ({
  logoTextStyle:{
    height: "44px",
    background: 'linear-gradient(90deg, #00448A 0%, #0D6BA9 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '0em',
    color:"white", 
    marginLeft:"10px",
    backgroundColor:"red",
  },
  logoDivStyle:{
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center", 
    alignItems: "center"
  }
}));



const Login = () => {
  const [error,setError] = useState('');
  const [loading,setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.replace(url);
  };

  useEffect(() => {
    const userDetails = async () => {
      const url = window.location.href;
      const response = decodeURIComponent(url);
      const myArray = response.split("?");
  
      if (myArray.length > 1) {
        const queryParameters = new URLSearchParams(window.location.search)
        // const userDetails = queryParameters.get("UserData");
        // const currentUserDetails = JSON.parse(userDetails);
        // setUser(currentUserDetails);
        // const userlogoutdata = localStorage.getItem("user_id");
        // const userprocesslogoutdata = localStorage.getItem("user_process_id");
        const code = queryParameters.get("code");
 
        if (code) {
          try {
            // Call the API to check the user's authentication status
            const Apiurl = configParam.API_URL
            const response = await axios.get(`${Apiurl + "login"}?code=${code}`);
     
            if (response.status === 200) {
              const userData = response.data;
              const access_token = userData.data.access_token.replace(/"/g, "");
              console.log("access_token",access_token)
              const token=jwtDecode(access_token);
              if (token) {
                await getUserDetails(token.userId, access_token);
                // localStorage.setItem("user", JSON.stringify(currentUserDetails));
                // let path = '/business';
      
                // if (currentUserDetails.role_id === '2') {
                //   path = `/plants/${currentUserDetails.location_id}`;
                // } else {
                //   path = `/analytics/${userlogoutdata || userprocesslogoutdata}`;
                // }
                // navigate(path);
              }
              else {
                localStorage.setItem("user", "");
                setError("Access denied");
              }
            }
          } catch (error) {
            // Handle any errors during the API call or authentication process
            console.error("Error during authentication:", error);
        
          }
        }
      }
    };
    const getUserDetails = async (id, access_token) => {
      access_token = access_token.replace(/"/g, "");
      const Apiurl = configParam.API_URL
      console.log("access_token",access_token)
      try {
        const response = await axios.get(Apiurl + `check_user/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
        if (response.status === 200) {
          if (response && response.data) {
           
            let userData = response.data[0];
         
            userData["access_token"] = `${access_token}`;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("accesstoken", access_token);
            navigate('/manageassessment');
            window.location.reload();
          }
        } else {
       
        }
      } catch (error) {
  
        // Handle other types of errors
       
  
      }
    }
    userDetails()
  }, [navigate]);



  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      <div className={classes.logoDivStyle}>
          <img src={SekuritLogo} style={{height:"65px",width:"240px"}} alt="Logo" className="icon" />
          <Typography  style={{
          color: "#62BBB2",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight:900,
          lineHeight: "normal",
        }}>Windshield Crack Assessment App</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          variant="subtitle1"
          style={{ color: "red", marginTop: "25px" }}
        >
          {error}&nbsp;
        </Typography>
        {!loading ? (
          <Button
            variant="contained"
            style={{
              width: "300px",
              textTransform: "none",
              fontSize: 24,
              color: "white",
              borderRadius: "8px",  // Added border-radius
              background: "linear-gradient(90deg, #296FAC 1.17%, #142D63 97.67%)" // Added background
        
            }}
            onClick={onHandleSSO}
          >
            Log In
          </Button>
        ) : (
          <CircularProgress />
        )}
      </div>
      {/* <Typography
        variant="body1"
        style={{ marginTop: 30, fontSize: 14, textAlign: "center" }}
      >
        Copyright © 2023 Saint-Gobain. All Rights reserved
      </Typography> */}
    </div>
  );
  
}
export default Login;