import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';

// Create custom styles using makeStyles
const useStyles = makeStyles(() => ({
  customButton: {
    width: '100%', // Set the width to 100% to make it full width
    backgroundColor: "#0D508B", // Custom background color
    color: "#fff", // Custom text color
    "&:hover": {
      backgroundColor: "#0056b3", // Custom background color on hover
    },
  },
}));

const CustomButton = ({ text, onClick,disabled }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.customButton}
      onClick={onClick}
      disabled={disabled}
      style={{ textTransform: 'none' }} // Disable all caps
    >
     {text}
    </Button>
  );
};

export default CustomButton;
