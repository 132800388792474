import React from "react";
import { TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '../../assets/icons/ic_calendar.svg';

const useStyles = makeStyles(() => ({
  text: {
    "& .MuiSelect-root": {
      color: "#0D508B",
    },
    "& .MuiInputLabel-root": {
      color: "#49454F",
      padding: '0 8px', // Add some padding to avoid overlapping with outlined border
      backgroundColor: 'white', // Set the background color to match the form control background
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#00448B",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0D508B",
      borderRadius:"8px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0D508B",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red", // Red border on error
    },
    // "& .MuiOutlinedInput-input": {
    //   minHeight: "35px", // Set minHeight to "unset" to override the default value
    // },
  },
}));

const TextBox = (props) => {
  const classes = useStyles();

  const renderInputAdornment = () => {
    if (props.type === "date" && props.showCalendarIcon) {
      return (
        <InputAdornment position="end">
          <IconButton>
            <img src={EventIcon} alt="Calendar Icon" />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Typography style={{
        textAlign: "left",
        marginBottom: "8px",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "15px",
        letterSpacing: "0em",
        color: "#333333",
        textAlign: "left",
        marginBottom: "8px",
      }}>
        {props.label}
      </Typography>
      <TextField
        required={props.required ? props.required : null}
        style={{
          width: "100%",
          marginBottom: "16px",
          borderRadius: "8px",
          borderColor: props.error ? "red" : "",  // Set border color based on props.error and stepperError
        }}
        id={props.id}
        type={props.type}
        value={props.value}
        InputLabelProps={{
          shrink: true
        }}
        name={props.name}
        onChange={props.onChange}
        inputRef={props.inputRef}
        error={props.error ? props.error : false}
        helperText={props.helperText ? props.helperText : ""}
        disabled={props.disabled ? props.disabled : false}
        className={classes.text}
        variant="outlined"
        InputProps={{
          endAdornment: renderInputAdornment(),
        }}
      />
    </React.Fragment>
  );
};

export default TextBox;

