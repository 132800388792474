import React, { Component, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Grid,
  Card,
  TableCell,
  Table,
  TableRow,
  Box,
  TableContainer,
  TableBody,
  Typography
} from "@mui/material";
import axios from "axios";
import Helper from "../sevices/Helper";
import Logo from '../../assets/icons/ic_wsca_logo.png';
import moment from 'moment';


const PreviewPdf = ({ data }) => {
    console.log("sertttt",data.created_at)
  return (
    <Grid>
      <Grid container style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "blue",
          }}
        >
            <div>
          <Typography
            variant="h1"
            style={{
              textTransform: "uppercase",
              textAlign: "center",
              fontWeight: "bold",
              maxWidth: "700px",
              fontSize: "28px",
              color: 'FFFFFF',
              padding:'5px'
            }}
          >
            JOINT INVESTIGATION REPORT
          </Typography>
          <Typography style={{
                    color: 'FFFFFF',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    letterSpacing: '-0.084px',
                    padding:'5px'
                  }}>
                   {moment(data.created_at).format(("DD-MMM-YYYY"))}

                  </Typography>
          </div>
          <div style={{padding:'10px'}}>
          <img
          src={Logo}
          alt={`Image`}
        />
        </div>
        </div>
      </Grid>
      <h3
    style={{
      width: "100%",
      fontWeight: 600,
      color: "#4D4D4D",
      padding: "7px 15px",
      fontSize: 18,
    }}
  >
    Dealer Details
  </h3>
      <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>

  <Box p={1} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Dealer Name</span><br />{data.dealer_info.dealer_Name? data.dealer_info.dealer_Name : ""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Contact Person</span><br />{data.dealer_info.contact_Person?data.dealer_info.contact_Person:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Customer Name</span><br />{data.dealer_info.customer_Name?data.dealer_info.customer_Name:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Dealer Address</span><br />{data.dealer_info.dealer_Address?data.dealer_info.dealer_Address:""}
    </div>
    {/* <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Laboratory Type:</span><br />{data.dealer_info.dealer_Address ? data.dealer_info.dealer_Address : ""}
    </div> */}
  </Box>
  </Card>
  <h3
    style={{
      width: "100%",
      fontWeight: 600,
      color: "#4D4D4D",
      padding: "7px 15px",
      fontSize: 18,
    }}
  >
    Vehicle Details
  </h3>
  <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
  <Box p={1} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Vehicle No</span><br />{data.vehicle_info.vin_Number? data.vehicle_info.vin_Number : ""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Vehicle Model</span><br />{data.vehicle_info.vehicle_Model?data.vehicle_info.vehicle_Model:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Date of Sale</span><br />{data.vehicle_info.date_sale?data.vehicle_info.date_sale:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Total Kms</span><br />{data.vehicle_info.Total_kms_Covered?data.vehicle_info.Total_kms_Covered:""}
    </div>
  </Box>
  </Card>
  <h3
    style={{
      width: "100%",
      fontWeight: 600,
      color: "#4D4D4D",
      padding: "7px 15px",
      fontSize: 18,
    }}
  >
    Glass Details  
  </h3>
  <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>

  <Box p={1} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Production Date</span><br />{data.glass_info.Production_Date? data.glass_info.Production_Date : ""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Part Number</span><br />{data.glass_info.part_number?data.glass_info.part_number:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Part Name</span><br />{data.glass_info.part_Name?data.glass_info.part_Name:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Glass Batch Code</span><br />{data.glass_info.glass_Batchcode?data.glass_info.glass_Batchcode:""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Glass Staus</span><br />{Helper.GlassStatusArray.find(item => item.id ===data.glass_info.glass_status )?.name ? Helper.GlassStatusArray.find(item => item.id === data.glass_info.glass_status)?.name : ""}
    </div>
    <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
      <span style={{ fontWeight: 600 }}>Defect Location</span><br />{data.nature_of_complaint.defect_Location?data.nature_of_complaint.defect_Location:""}
    </div>
  </Box>
</Card>
<h3
    style={{
      width: "100%",
      fontWeight: 600,
      color: "#4D4D4D",
      padding: "7px 15px",
      fontSize: 18,
    }}
  >
    Glass Details  
  </h3>
  {data.processed_images.map((record,index) => {return(
 <Card style={{ marginBottom: "10px", width: "99%", border: "1px solid #ccc" }}>
    <div style={{width:'100%',float:'left'}}>
    <div p={1} style={{ width:'50%',float:'left'}}>
    <div style={{ flex: "1", minWidth: "200px" }}>
      <img
          src={data.raw_images[index].imageName}
          alt={`Image ${index + 1}`}
          // style={{width:"100px"}}
        // onClick={handleImageClick}

        />
        <div style={{ flex: "1", minWidth: "200px"}}>
      <h6 style={{ fontWeight: 600 }}>Observation</h6>
      <p>{data.raw_images[index].imageDescription}</p>
    </div>
    </div>
    
    </div>
    <div p={1} style={{ width:'50%',float:'left'}}>
    <div style={{ flex: "1", minWidth: "200px"}}>
      <img
          src={record}
          alt={`Image ${index + 1}`}
          // style={{width:"100px"}}
        // onClick={handleImageClick}

        />
        <div style={{ flex: "1", minWidth: "200px" }}>
      <h6 style={{ fontWeight: 600 }}>Conclusion</h6>
      <p>{data.root_cause_identified[index]}</p>
    </div>
    </div>
    
    </div>
    </div>
</Card>
  )})}

    </Grid>
  );
};
export default PreviewPdf;