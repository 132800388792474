import React from 'react';
import Avatar from "@mui/material/Avatar";
import { Typography } from '@mui/material';
import LocationIcon from '../../assets/icons/ic_location.svg';
import Card from "@material-ui/core/Card";


const Profile = () => {
  let fullNameInitials = '';
  let name = '';
  let plant_id = '';
  let plantName = '';
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      const firstName = userData.firstname?.charAt(0) || ''; // Handle undefined or null
      const lastName = userData.lastname?.charAt(0) || '';
      name = userData.firstname + " " + lastName;
      fullNameInitials = firstName + lastName;
      plant_id = userData.plant_id;
      plant_id = userData.plant_id;

      if (plant_id === 1) {
        plantName = "Chennai";
      } else if (plant_id === 2) {
        plantName = "Pune";
      } else if (plant_id === 3) {
        plantName = "ALSC";
      } else if (plant_id === 4) {
        plantName = "HLSC";
      } else {
        plantName = "Bhiwadi";
      }
    }
  } catch (error) {
    console.error("Error parsing user data:", error);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* First part with linear gradient background */}
      <div style={{ flex: 1, background: 'linear-gradient(180deg, #1278B4 0%, #193D75 100%)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'   }}>
        {/* Content of the first part */}
        <div style={{ marginRight: '1rem', marginLeft: 20 }}>
          <Avatar sx={{ bgcolor: '#0D508B', width: "100px", height: "100px" }}>
            {fullNameInitials}
          </Avatar>
          <Typography
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 600,
              padding: "10px",
              lineHeight: '30px', // Adjust as needed
            }}
          >
            {name}
          </Typography>
          <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
            < img src={LocationIcon} />
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                flex: '1 0 0',
                padding: "10px",
                alignSelf: 'stretch',
                color: "#FFFFFF"
              }}>
              {plantName}
            </Typography>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "40px" }}>
          <Card
            style={{
              width: "85%",
              padding: 20,
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
              background: "var(--black-white-white, #FFF)",
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
              marginTop:"20px",
              boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
            }}
            variant="outlined"
          >
            {/* <Avatar {...stringAvatar((user.first_name + " " + user.last_name).toUpperCase(), 50)} /> */}
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: "10px" }}>
              <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{
                  color: '#909190',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '-0.072px',
                }}>
                  Total Complaints
                </Typography>
                <Typography style={{
                  color: '#909190',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                  marginRight: '40px',
                  letterSpacing: '-0.072px',
                }}>
                  Resolved Complaints
                </Typography>
              </div>

              <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '-0.084px',
                }}>
                  123
                </Typography>
                <Typography style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  marginRight: '40px',
                  letterSpacing: '-0.084px',
                }}>
                  34
                </Typography>
              </div>
            </div>
          </Card>
        </div>
      </div>

      {/* Second part without background */}
      <div style={{ flex: 1 }}>
        {/* Content of the second part */}
      </div>
    </div>
  );


}

export default Profile;