import React, { useState, useEffect } from 'react';
import TextBox from '../../pages/input/TextBox';
import API from '../sevices/Api';
import axios from 'axios';
import SelectBox from '../input/SelectBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AlertDialog from '../input/AlertDialog';
import { CircularProgress } from '@material-ui/core';

const access_token = localStorage.getItem("accesstoken");
const NewUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [functionData, setFunctionData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const userInfo = location.state ? location.state.userInfo : null;
  const isEdit = location.state ? location.state.isEdit : null;
  const initialFirstName = userInfo ? userInfo.firstname : '';
  const initialLastName = userInfo ? userInfo.lastname : '';
  const initialSGID = userInfo ? userInfo.sgid : '';
  const initialEmail = userInfo ? userInfo.email : '';
  const initialRole = userInfo ? userInfo.role_id : '';
  const initialPlant = userInfo ? userInfo.plant_id : '';
  const initialFunction = userInfo ? userInfo.function_id : '';
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [sgid, setSgid] = useState(initialSGID);
  const [email, setEmail] = useState(initialEmail);
  const [functionId, setfunctionId] = useState(initialFunction);
  const [roleId, setroleId] = useState(initialRole);
  const [plantId, setplantId] = useState(initialPlant);
  const [isLoading, setIsLoading] = useState(true);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    sgid: false,
    email: false,
    functionId: false,
    roleId: false,
    plantId: false,
  });

  const menuStyle = {
    whiteSpace: 'normal',
  };


  useEffect(() => {
    getFunctionList();
    getRoleList();
    getPlantList();
  }, []);


  const validateForm = () => {
    const errors = {
      firstName: !firstName.trim(),
      lastName: !lastName.trim(),
      sgid: !sgid.trim(),
      email: !validateEmail(email),
      functionId: !functionId,
      roleId: !roleId,
      plantId: !plantId,
    };
    setFormErrors(errors);
    return Object.values(errors).every((value) => !value);
  };

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@(ext\.)?saint-gobain\.com$/;
    return regex.test(email);
  };


  const getFunctionList = async () => {
    try {
      let url = API.FUNCTION;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      if (response.data.length > 0) {
        const data = response.data;
        setFunctionData(data)
      }
    } catch (error) {
      console.error(error);
      AlertDialog({
        type: 'error',
        title: 'Error',
        text: error,
      });
    }
    finally {
      setIsLoading(false); 
    }
  };

  const getRoleList = async () => {
    try {
      let url = API.ROLE;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      if (response.data.length > 0) {
        const data = response.data;
        setRoleData(data);
      }
    } catch (error) {
      console.error(error);
      AlertDialog({
        type: 'error',
        title: 'Error',
        text: error,
      });
    }
    finally {
      setIsLoading(false); 
    }
  };

  const getPlantList = async () => {
    try {
      let url = API.PLANT;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      if (response.data.length > 0) {
        const data = response.data;
        setPlantData(data);
      }
    } catch (error) {
      console.error(error);
      AlertDialog({
        type: 'error',
        title: 'Error',
        text: error,
      });
    }
    finally {
      setIsLoading(false); 
    }
  };
  const handleSaveClick = async () => {
    const isFormValid = validateForm();
    console.log(functionId, roleId, plantId);
  
    if (isFormValid) {
      setIsLoading(true);
  
      if (isEdit) {
        const newUser = {
          id: userInfo.id,
          firstName,
          lastName,
          sgid,
          email,
          functionId,
          roleId,
          plantId,
        };
  
        try {
          const response = await axios.post(`${API.UPDATE_USER}`, newUser, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          });
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'User Updated Successfully',
            confirmButtonText: 'Yes',
            onConfirm: () => {
              navigate(-1);
            },
          });
        } catch (error) {
          AlertDialog({
            type: 'error',
            title: 'Error',
            text: error,
          });
          // Handle error
          console.error('Failed to update user:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        const newUser = {
          firstName,
          lastName,
          sgid,
          email,
          functionId,
          roleId,
          plantId,
        };
  
        try {
          const response = await axios.post(`${API.INSERT_USER}`, newUser, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          });
  
          console.log('response', response);
          navigate(-1);
        } catch (error) {
          // Handle error
          console.error('Failed to save user:', error);
          AlertDialog({
            type: 'error',
            title: 'Error',
            text: error,
          });
        } finally {
          setIsLoading(false);
        }
      }
    }
  };


  const handleCancelClick = () => {
    navigate(-1);
  }
  const firstNameOnChnage = (event) => {
    setFirstName(event.target.value);
  }
  const lastNameOnChange = (event) => {
    setLastName(event.target.value);
  }
  const sgidOnChange = (event) => {
    setSgid(event.target.value);
  }
  const emailOnChange = (event) => {
    setEmail(event.target.value);
  }
  const handleFunctionChange = (event) => {
    setfunctionId(event.target.value);
  }
  const handleroleIdChange = (event) => {
    setroleId(event.target.value);
  }
  const handleplantIdChange = (event) => {
    setplantId(event.target.value);
  }
  

  return (
    <div style={{ margin: "15px" }}>
       {isLoading && (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(0, 0, 0, 0.3)' }}>
          <CircularProgress />
        </div>
      )}
      <div style={{ marginTop: "30px" }}>
        <TextBox
          name="first_name"
          label="First Name"
          value={firstName}
          isString={true}
          onChange={firstNameOnChnage}
          error={formErrors.firstName}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="last_name"
          label="Last Name"
          isString={true}
          value={lastName}
          onChange={lastNameOnChange}
          error={formErrors.lastName}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="sgid"
          label="SG ID"
          isString={true}
          value={sgid}
          onChange={sgidOnChange}
          error={formErrors.sgid}
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="email"
          label="Email"
          isString={true}
          value={email}
          onChange={emailOnChange}
          error={formErrors.email}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <SelectBox
          labelVariant="body2"
          isLable={true}
          lableValue="Function"
          options={functionData}
          value={functionId}
          onChange={handleFunctionChange}
          error={formErrors.functionId}
          labelCondition="function"
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <SelectBox
          labelVariant="body2"
          isLable={true}
          lableValue="Role"
          options={roleData}
          value={roleId}
          onChange={handleroleIdChange}
          error={formErrors.roleId}
          labelCondition="role"
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <SelectBox
          labelVariant="body2"
          isLable={true}
          lableValue="Plant"
          options={plantData}
          value={plantId}
          onChange={handleplantIdChange}
          error={formErrors.plantId}
          labelCondition="plant"
        />
      </div>

      {/* <div style={{ marginTop: "20px" }}>
        <SelectBox
          labelVariant="body2"
          isLable={true}
          lableValue="Function"
          options={functionData}
          value={functionId}
          onChange={handleFunctionChange}
          error={formErrors.functionId}
        >
          {functionData &&
            functionData.map((d, index) => <MenuItem key={index} value={d.id} style={menuStyle}>{d.function}</MenuItem>)}
        </SelectBox>
      </div>

      <div style={{ marginTop: "20px" }}>
        <SelectBox labelVariant="body2" isLable={true} lableValue="Role" options={roleData} value={roleId}
          onChange={handleroleIdChange}
          error={formErrors.roleId} >
          {roleData &&
            roleData.map((d, index) => <MenuItem key={index} value={d.id} style={menuStyle}>{d.role}</MenuItem>)}
          </SelectBox>
      </div>
      <div style={{ marginTop: "20px" }}>
        <SelectBox labelVariant="body2" isLable={true} lableValue="Role" options={plantData} value={plantId}
          onChange={handleplantIdChange}
          error={formErrors.plantId} >
         {plantData &&
            plantData.map((d, index) => <MenuItem key={index} value={d.id} style={menuStyle}>{d.plant}</MenuItem>)}
          </SelectBox>
      </div> */}

      <div style={{ width: '95%', display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
        <Button
          variant="outlined"
          style={{
            borderRadius: '3px',
            width: "47%",
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            display: 'flex',
            borderWidth: '3px',
            border: '2px solid #00448B',
            fontSize: 20,
            color: "#00448B",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            width: "48%",
            borderRadius: '3px',
            flex: 1,
            display: 'flex',
            backgroundColor: "#00448B",
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            height: "45px",
          }}
          onClick={handleSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default NewUser;