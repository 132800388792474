class Helper {

    static claimTypeArray = [
        { id: 1, name: 'AGR' },
        { id: 2, name: 'OEM' },
        { id: 3, name: 'SPD' }
      ];
     static RegionArray = [
        { id: 1, name: 'South' },
        { id: 2, name: 'North' },
        { id: 3, name: 'East' },
        { id: 4, name: 'West' }
      ];
    static ModeOfTransportArray = [
      { id: 1, name: 'Road /Courier' },
      { id: 2, name: 'Air' },
      { id: 3, name: 'Hand Carry' }
    ];
    static GlassStatusArray = [
      { id: 1, name: 'Initial' },
      { id: 2, name: 'Replaced' },
    
    ];

    static RootCauseArray = [
      { id: 1, name: 'External Damage' },
      { id: 2, name: 'Manufacturing Defect' },
      { id: 3, name: 'Abused Product' },
      { id: 4, name: 'Others' },    
    ];

    static plantData = {
      1: 'Chennai',
      2: 'Pune',
      3:'ALSC',
      4:'HLSC',
      5:'Bhiwadi'
    };

}
export default Helper;
