import { Typography } from '@mui/material';
import React, { useState} from 'react';
import TextBox from '../../pages/input/TextBox';
import { delearInfo, } from '../recoil/atoms';
import { useRecoilState } from 'recoil';
import DateTimeMonth from "../input/DateTimeMonth";
import moment from 'moment';
import dayjs from 'dayjs';
import SelectBox from '../input/SelectBox';
import Helper from '../sevices/Helper';

const DealerInfo = ({stepperError}) => {
  const [delar, setDelar] = useRecoilState(delearInfo);
  const [claimTypeId, setClaimTypeId] = useState("");
  const [regionId, setRegionId] = useState("");


  console.log("stepperError1 ", stepperError);

  const delarOnchange = (e) => {
    const { name, value } = e.target;
    setDelar(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
 
  const handleDateChange = (e, key) => {
    const updatedValue = e.target.value;
  
    setDelar((prevDelar) => ({
      ...prevDelar,
      [key]: updatedValue,
    }));
  };
  

  const handleCliamTypeChange = (event) =>{
    setClaimTypeId(event.target.value); // Assuming the option object has an 'id' property
    // Update delar state
    setDelar((prevDelar) => ({
      ...prevDelar,
      claim_type_id: event.target.value, // Update with the correct property name
    }));
  }
 
  // const handleRegionIdChange = (event) =>{
  //   setRegionId(event.target.value); // Assuming the option object has an 'id' property
  //   // Update delar state
  //   setDelar((prevDelar) => ({
  //     ...prevDelar,
  //     region_id: event.target.value, // Update with the correct property name
  //   }));
  // }

  console.log("dealerInfo", delar);
 
  return (
    <div style={{ margin: "15px" }}>    

           <div style={{marginTop:"20px"}}>
            <SelectBox
            labelVariant="body2"
            isLable={true}
            lableValue="Claim Type *"
            options={Helper.claimTypeArray}
            value={delar.claim_type_id}
            onChange={delarOnchange}
            name="claim_type_id"
            labelCondition="name"
            error={stepperError?true:false}
          />
          </div>
          <div style={{marginTop:"20px"}}>
            <SelectBox
            labelVariant="body2"
            isLable={true}
            lableValue="Region *"
            options={Helper.RegionArray}
            value={delar.region_id}
            onChange={delarOnchange}
            error={stepperError?true:false}
            name="region_id"
            labelCondition="name"
          />
          </div>
          <div style={{ marginTop: "20px" }}>
            <TextBox
              id="toDate"
              label="Date of Complaint Recorded *"
              type="date"
              value={delar.dateComplaint_record || ""}
              onChange={(e) => handleDateChange(e, "dateComplaint_record")}
              error={stepperError?true:false}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <TextBox
              id="toDate"
              label="Date of Complaint Recorded by Customer *"
              type="date"
              value={delar.complaintRecord_customer || ""}
              onChange={(e) => handleDateChange(e, "complaintRecord_customer")}
              error={stepperError?true:false}
            />
          </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="dealer_Name"
          label="Dealer Name *"
          value={delar.dealer_Name}
          isString={true}
          onChange={delarOnchange}
          error={stepperError?true:false}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="dealer_Address"
          label="Dealer Address"
          isString={true}
          value={delar.dealer_Address}
          onChange={delarOnchange}
          // error={stepperError && !delar.dealer_Address ? true : false}
          // error={stepperError?true:false}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="contact_Person"
          label="Contact Person"
          isString={true}
          value={delar.contact_Person}
          onChange={delarOnchange}
          // error={stepperError && !delar.contact_Person ? true : false} 
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        <TextBox
          name="customer_Name"
          label="Customer Name"
          isString={true}
        // value={selectedDate}
        // onChange={(event) => handleDateSelection(event.target.value, "from")}
        value={delar.customer_Name}
        onChange={delarOnchange}
        // error={stepperError && !delar.customer_Name ? true : false} 
        />
      </div>
    </div>
  ); 
}

export default DealerInfo;