import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axios from 'axios';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DealerInfoActiveIcon from '../../assets/icons/ic_user_profile_blue.svg';
import VehicleInfoIcon from '../../assets/icons/ic_truck_light_blue.svg';
import BackIcon from '../../assets/icons/ic_back.svg';
import GlassInfoIcon from '../../assets/icons/ic_glass_info_light_bue.svg';
import NatureofComplaintIcon from '../../assets/icons/ic_nature_light_blue.svg';
import VehicleInfoActiveIcon from '../../assets/icons/ic_truck_blue.svg';
import GlassInfoActiveIcon from '../../assets/icons/ic_glass_info_blue.svg';
import NatureofComplaintActiveIcon from '../../assets/icons/ic_nature_of_complaint_blue.svg';
import CircularProgress from '@mui/material/CircularProgress';
import DealerInfoCompletedIcon from '../../assets/icons/ic_user_profile_white.svg';
import VehicleInfoCompletedIcon from '../../assets/icons/ic_truck_white.svg';
import GlassInfoCompletedIcon from '../../assets/icons/ic_glass_info_white.svg';
import NatureofComplaintCompletedIcon from '../../assets/icons/ic_nature_of_complaint_white.svg';
import RootCause from './RootCause';
import ResultReport from './ResultReport';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../input/CustomButton';
import AssismentComplaintDetails from './AssismentComplaintDetails';
import { delearInfo, vechicleInfo, glassInfo, NatureofComplaintInfo, FieldInvestigation, flowType, error, imageType,videoType,rootData,nocimageType } from "../recoil/atoms"
import Swal from 'sweetalert2';
import API from '../sevices/Api';
import Services from '../sevices/Services';
import FieldInvestigationTab from './FieldInvestigationTab';
import FieldInvestigationView from './FieldInvestigationView';
import { act } from '@testing-library/react';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: 'transparent', // Set the background color to transparent
    borderRadius: 1,
    // Add dashed border style
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderColor: '#0D508B', // Adjust the color as needed
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E8E8E8', // Initial transparent border
  },
  active: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #0D508B',
    // Blue border when active
  },
  completed: {
    backgroundColor: '#0D508B', // No background when completed
    borderColor: 'transparent', // No border when completed
  },
}));



// Define the icons mapping
const stepIcons = {
  1: {
    default: DealerInfoActiveIcon,
    active: DealerInfoActiveIcon,
    completed: DealerInfoCompletedIcon,
  },
  2: {
    default: VehicleInfoIcon,
    active: VehicleInfoActiveIcon,
    completed: VehicleInfoCompletedIcon,
  },
  3: {
    default: GlassInfoIcon,
    active: GlassInfoActiveIcon,
    completed: GlassInfoCompletedIcon,
  },
  4: {
    default: NatureofComplaintIcon,
    active: NatureofComplaintActiveIcon,
    completed: NatureofComplaintCompletedIcon,
  },
};

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icon = Object.prototype.hasOwnProperty.call(stepIcons, props.icon) ? stepIcons[props.icon] : {};

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <img src={active ? (icon.active || icon.default) : completed ? (icon.completed || icon.default) : icon.default} alt={`Step ${props.icon}`} />
    </div>
  );
}


ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: "10px"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Complaint Details',
    'Field Investigation',
    'Root Cause Analysis',
    'Result/Report',];
}


const access_token = localStorage.getItem("accesstoken");

const complaintDetails = () => {
  const classes = useStyles()
  const steps = getSteps();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading,] = useState(false);
  const [dealerValidate] = useRecoilState(delearInfo);
  const [vechileValidate] = useRecoilState(vechicleInfo);
  const [glassValidate] = useRecoilState(glassInfo);
  const [stepperError, setStepperError] = useRecoilState(error);
  const dealerInformation = useRecoilValue(delearInfo);
  const vechileInformation = useRecoilValue(vechicleInfo);
  const glassInformation = useRecoilValue(glassInfo);
  //  const cardDataView=useRecoilValue(viewVisitData);
  const complaintInformation = useRecoilValue(NatureofComplaintInfo);

  const fieldInvestigate = useRecoilValue(FieldInvestigation);
  const dataFlow = useRecoilValue(flowType);
  const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);
  const assessment_id = localStorage.getItem('row_id');
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [fieldButtonText, setFieldButtonText] = useState('Edit');
  const [selectedImages, setSelectedImages] = useRecoilState(imageType);
  const [selectednocImages,setSelectedNocImages]=useRecoilState(nocimageType);
  const [selectedVideos, setSelectedVideos] = useRecoilState(videoType);
  const [natureInfo, setNatureInfo] = useRecoilState(NatureofComplaintInfo);
  const [rootcausedata,setrootcausedata]=useRecoilState(rootData);
  const [loading, setLoading] = React.useState(false);
  const [submitCompleted, setSubmitCompleted] = React.useState(false);
  const [id,setId]=useState('');
  const [processedImageStatus,setprocessedImageStatus]=useState(null);
  const [approvedStatus,setapprovedStatus]=useState(null);
  const a=localStorage.getItem('assessment_data')
  const parsedData = JSON.parse(a);
  const userdata=localStorage.getItem('userData')
  const userdatajson=JSON.parse(userdata)
  console.log('a',a)
  console.log("selectedImages", selectedImages);

  // setFieldData({})

  console.log("fieldInvestigateComplaint", fieldInvestigate);

  const checkAllValues = (value, mandatoryFields) => {
    for (let i = 0; i < mandatoryFields.length; i++) {
      if (!value[mandatoryFields[i]]) {
        return false; // Return false if any mandatory field is not filled
      }
    }
    return true; // Return true only if all mandatory fields are filled
  }


  const fetchData = async () => {
    try {
      const url = `${API.GET_FIELD_INVESTIGATION}/${assessment_id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("response", response);
      console.log("responseField", response.data);
      if (response.status === 200) {
        const newData = response.data[0];
        console.log(newData, "newData");
        const field_investigation_id = newData.id;
        setprocessedImageStatus(newData.processed_image_status)
        setapprovedStatus(newData.approval_status)
        localStorage.setItem("field_investigation_id",field_investigation_id);

        // Redirect to FvieldInvestigationView if status is 200
        setRedirectUrl('View');
      } else {
        console.log("NORESPONSE");
        // Redirect to FieldInvestigationTab if status is not 200 (e.g., 404)
        setRedirectUrl('Tab');
      }
    } catch (error) {
      console.log("Error", error);
      setRedirectUrl('Tab');

    }
  };

  useEffect(() => {
    fetchData();
  }, [assessment_id]);


  // const checkAllMandatoryFields = () => {

  //     const stepper1MandatoryFields = ["dateComplaint_record", "complaintRecord_customer","dealer_Name","dealer_Address","contact_Person","customer_Name"];
  //     let stepper1Validattion = checkAllValues(
  //       dealerValidate,
  //       stepper1MandatoryFields
  //     );
  //     if (stepper1Validattion) {
  //       return true;
  //     }


  //   const stepper2MandatoryFields = ["vehicle_Model", "vin_Number","date_sale","Production_Date","part_Name","Total_kms_Covered"];
  //   let stepper2Validattion = checkAllValues(
  //     vechileValidate,
  //     stepper2MandatoryFields
  //   );
  //    if(stepper2Validattion){
  //     return true;
  //   }

  //   const stepper3MandatoryFields = ["glass_Batchcode", "glass_status"];
  //   let stepper3Validattion = checkAllValues(
  //     glassValidate,
  //     stepper3MandatoryFields
  //   );
  //    if(stepper3Validattion){
  //     return true;

  //  }

  // }


  const checkAllMandatoryFields = () => {
    const stepper1MandatoryFields = ["claim_type_id", "region_id", "dateComplaint_record", "complaintRecord_customer", "dealer_Name"];
    const stepper2MandatoryFields = ["vehicle_Model", "date_sale"];
    const stepper3MandatoryFields = ["Production_Date", "glass_status"];

    const stepper1Validation = checkAllValues(dealerValidate, stepper1MandatoryFields);
    const stepper2Validation = checkAllValues(vechileValidate, stepper2MandatoryFields);
    const stepper3Validation = checkAllValues(glassValidate, stepper3MandatoryFields);

    const isValid = stepper1Validation && stepper2Validation && stepper3Validation;

    setStepperError(!isValid); // Set stepper error based on the overall validation result

    return isValid;
  }

  const handleApprove=()=>{
    const ids = localStorage.getItem('row_id');
    let url = `${API.UPDATE_ASSESMENT}/${ids}`
    axios.post(url, {
      dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: parsedData.nature_of_complaint, status: "Completed"
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        console.log(res)
        if (res.data.affected > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: 'Your data has been updated successfully!',
          }).then(() => {
            navigate(`/manageassessment`);
          });

        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }

      });

  }

  const handleReject=()=>{
    const ids = localStorage.getItem('row_id');
    let url = `${API.UPDATE_ASSESMENT}/${ids}`
    axios.post(url, {
      dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: parsedData.nature_of_complaint, status: "In-Progress"
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        console.log(res)
        if (res.data.affected > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: 'Your data has been updated successfully!',
          }).then(() => {
            navigate(`/manageassessment`);
          });

        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }

      });

  }

  function getStepContent(step) {
    console.log(redirectUrl, "rediretURL")
    switch (step) {
      case 0:
        return <AssismentComplaintDetails stepperError={stepperError} />;
      case 1:
        return redirectUrl === 'Tab' ? <FieldInvestigationTab /> : (
          <FieldInvestigationView
          />
        );
      case 2:
        return <RootCause />;
      case 3:
        return <ResultReport processedImageStatus={processedImageStatus} approvedStatus={approvedStatus} Approve={handleApprove} Reject={handleReject} /> ;

      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    //  let validation = checkAllMandatoryFields();
    //     if (validation) {
    //       console.log("validationNexBtn",validation);
    //       setStepperError(true);
    //       return true;
    //     }
    //     else{
    //       setStepperError(false);

    //     }
    console.log("extractedNames2",fieldData.need_to_process);
    if(activeStep===1 && fieldData.need_to_process.length>0 &&fieldData.processed_images.length===0){
      setLoading(true);
      let url = API.INSERT_ROOT;
      console.log("id",id)
      // const extractedNames = fieldData.raw_images.map(item => {
      //   const match = item.imageName.match(/raw\/(.*?)\?/);
      //   return match ? match[1] : null;
      // });
      
      // console.log("extractedNames",extractedNames);
      // console.log("extractedNames1",fieldData.need_to_process);
      axios.post(url, {
        folder:fieldData.need_to_process,
        id: localStorage.getItem('row_id'),
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
        .then((data) => {
          // console.log("f3",data)
          // console.log("f4",data.data)
          // console.log("fieldfield",fieldData)
          const ids = localStorage.getItem('field_investigation_id');
          const storedId = localStorage.getItem('row_id');
          // const imageUrls = data.data.map(imageObj => imageObj.imageurl);
          const roottype = data.data.map(imageObj => imageObj.Crack_type);
          // const raw_images = fieldData.raw_images.map(imageObj => {
          //   console.log("fieldfield12",imageObj.imageName)
          //   const cleanUrl = imageObj.imageName.split("?")[0];
          //   const parts = cleanUrl.split("/");
          //   return {
          //     ...imageObj,
          //     imageName: parts[parts.length - 2] + "/" + parts[parts.length - 1],
          //     imageDescription: imageObj.imageDescription
          // };
          // });
          const processedimageNames = fieldData.raw_images_names.map(item => {
            return item.imageName;
          });
          console.log("processedimageNames",processedimageNames)
          // console.log("fieldfield11",raw_images)
          // console.log("imageeee",imageUrls)
          let url = `${API.UPDATE_FIELD_INVESTIGATION}/${ids}`
          axios.post(url, {
            collection_of_parts: fieldData.collection_of_parts,
            date_of_investigation: fieldData.date_of_investigation,
            field_observation: fieldData.field_observation,
            mode_of_transport: fieldData.mode_Transport,
            assessment_id: storedId,
            processed_images: processedimageNames,
            raw_images: fieldData.raw_images_names,
            need_to_process:[],
            raw_videos: fieldData.raw_videos,
            recommendation: fieldData.recommendation,
            root_cause_identified: roottype,
            processed_image_status:processedImageStatus,
            approval_status:approvedStatus

          }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
            .then((res) => {
              console.log(res)
              const url = `${API.GET_FIELD_INVESTIGATION}/${assessment_id}`;
              axios.get(url, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                }
              }).then((response) => {
                console.log("responseField", response.data);
                console.log("rawraw1",fieldData)
                if (response) {
    
                    const newData = response.data[0];
                    console.log(newData, "newData");
                    console.log("newData",newData.raw_images);
                    console.log("newData1",newData.mode_of_transport);
                    // const imageDescArray = newData.raw_images.map((imageData, index) => ({
                    //     id: index.toString(),
                    //     desc: imageData.imageDescription || '', // Use the imageDescription or an empty string if not available
                    // }));
    
                    const dateObject = new Date(newData.date_of_investigation);
    
                   // Get the date portion as a string (YYYY-MM-DD)
                   const dateOnly = dateObject.toISOString().slice(0, 10);
                   const nextDay = new Date(dateObject);
    nextDay.setDate(dateObject.getDate() + 1);
    
    // Get the next day's date portion as a string (YYYY-MM-DD)
    const nextDayString = nextDay.toISOString().slice(0, 10);
    
                    setFieldData((prevData) => ({
                        ...prevData,
                        date_of_investigation: nextDayString,
                        // image_desc: imageDescArray,
                        // image_names: newData.image_names,
                        raw_images: newData.raw_images,
                        raw_images_names:newData.raw_images_names,
                        //   raw_videos: newData.raw_videos,
                        processed_images: newData.processed_images,
                        processed_images_names:newData.processed_images_names,
                        // field_observation: newData.field_observation,
                        mode_Transport: newData.mode_of_transport,
                        collection_of_parts: newData.collection_of_parts,
                        root_cause_identified:newData.root_cause_identified,
                        recommendation: newData.recommendation,
                    }));
                    // setSelectedVideos([newData.raw_videos])
                } 
              })
              .catch((error) => {
                // Handle error
                console.error('Failed to save area:', error);
              });
              if (res.data.affected > 0) {
                Swal.fire({
                  icon: 'success',
                  title: 'Update Successful',
                  text: 'Your data has been updated successfully!',
                })
      
              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Submission Error',
                  text: 'There was an error submitting your data.',
                });
              }
      
            });

          setrootcausedata(data.data)
          // if (data.data.raw.length > 0) {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Submitted Successfully',
          //     text: 'Your data has been submitted successfully!',
          //   }).then(() => {
          //     navigate(`/manageassessment`);
          //   });
          // } else {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Submission Error',
          //     text: 'There was an error submitting your data.',
          //   });
          // }
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((error) => {
          setLoading(false);
             Swal.fire({
              icon: 'warning',
              title: 'Submission Error',
              text: 'There was an error in processing the image',
            });
          console.error('Error submitting data:', error);
        })
    }
    else if(activeStep===1 && fieldData.need_to_process.length>0 &&fieldData.processed_images.length>0){
      setLoading(true);
      let url = API.INSERT_ROOT;
      console.log("id",id)
      // const extractedNames = fieldData.raw_images.map(item => {
      //   const match = item.imageName.match(/raw\/(.*?)\?/);
      //   return match ? match[1] : null;
      // });
      
      // console.log("extractedNames",extractedNames);
      // console.log("extractedNames1",fieldData.need_to_process);
      axios.post(url, {
        folder:fieldData.need_to_process,
        id: localStorage.getItem('row_id'),
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
        .then((data) => {
          // console.log("f3",data)
          // console.log("f4",data.data)
          // console.log("fieldfield",fieldData)
          const ids = localStorage.getItem('field_investigation_id');
          const storedId = localStorage.getItem('row_id');
          // const imageUrls = data.data.map(imageObj => imageObj.imageurl);
          // const roottype = data.data.map(imageObj => imageObj.Crack_type);
          // const raw_images = fieldData.raw_images.map(imageObj => {
          //   console.log("fieldfield12",imageObj.imageName)
          //   const cleanUrl = imageObj.imageName.split("?")[0];
          //   const parts = cleanUrl.split("/");
          //   return {
          //     ...imageObj,
          //     imageName: parts[parts.length - 2] + "/" + parts[parts.length - 1],
          //     imageDescription: imageObj.imageDescription
          // };
          // });
          // const finalprocessed=[]
          // const processedimageNames = imageUrls.map(item => {
          //   const cleanUrl = item.split("?")[0];
          //   const parts = cleanUrl.split("/");
          //   return parts[parts.length - 2] + "/" + parts[parts.length - 1];
          // });
          // finalprocessed.push(fieldData.processed_images)
          // finalprocessed.push(processedimageNames)
          // console.log("fieldfield11",raw_images)
          // console.log("imageeee",imageUrls)
          let url = `${API.UPDATE_FIELD_INVESTIGATION}/${ids}`
          axios.post(url, {
            collection_of_parts: fieldData.collection_of_parts,
            date_of_investigation: fieldData.date_of_investigation,
            field_observation: fieldData.field_observation,
            mode_of_transport: fieldData.mode_Transport,
            assessment_id: storedId,
            processed_images: fieldData.raw_images_names.map(obj => obj.imageName),
            raw_images: fieldData.raw_images_names,
            need_to_process:[],
            raw_videos: fieldData.raw_videos,
            recommendation: fieldData.recommendation,
            root_cause_identified: roottype,
            processed_image_status:processedImageStatus,
            approval_status:approvedStatus

          }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
            .then((res) => {
              console.log(res)
              const url = `${API.GET_FIELD_INVESTIGATION}/${assessment_id}`;
              axios.get(url, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                }
              }).then((response) => {
                console.log("responseField", response.data);
                console.log("rawraw1",fieldData)
                if (response) {
    
                    const newData = response.data[0];
                    console.log(newData, "newData");
                    console.log("newData",newData.raw_images);
                    console.log("newData1",newData.mode_of_transport);
                    // const imageDescArray = newData.raw_images.map((imageData, index) => ({
                    //     id: index.toString(),
                    //     desc: imageData.imageDescription || '', // Use the imageDescription or an empty string if not available
                    // }));
    
                    const dateObject = new Date(newData.date_of_investigation);
    
                   // Get the date portion as a string (YYYY-MM-DD)
                   const dateOnly = dateObject.toISOString().slice(0, 10);
                   const nextDay = new Date(dateObject);
    nextDay.setDate(dateObject.getDate() + 1);
    
    // Get the next day's date portion as a string (YYYY-MM-DD)
    const nextDayString = nextDay.toISOString().slice(0, 10);
    
                    setFieldData((prevData) => ({
                        ...prevData,
                        date_of_investigation: nextDayString,
                        // image_desc: imageDescArray,
                        // image_names: newData.image_names,
                        raw_images: newData.raw_images,
                        raw_images_names:newData.raw_images_names,
                        //   raw_videos: newData.raw_videos,
                        processed_images: newData.processed_images,
                        processed_images_names:newData.processed_images_names,
                        // field_observation: newData.field_observation,
                        mode_Transport: newData.mode_of_transport,
                        collection_of_parts: newData.collection_of_parts,
                        root_cause_identified:newData.root_cause_identified,
                        recommendation: newData.recommendation,
                    }));
                    // setSelectedVideos([newData.raw_videos])
                } 
              })
              .catch((error) => {
                // Handle error
                console.error('Failed to save area:', error);
              });
              if (res.data.affected > 0) {
                Swal.fire({
                  icon: 'success',
                  title: 'Update Successful',
                  text: 'Your data has been updated successfully!',
                })
      
              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Submission Error',
                  text: 'There was an error submitting your data.',
                });
              }
      
            });

          setrootcausedata(data.data)
          // if (data.data.raw.length > 0) {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Submitted Successfully',
          //     text: 'Your data has been submitted successfully!',
          //   }).then(() => {
          //     navigate(`/manageassessment`);
          //   });
          // } else {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Submission Error',
          //     text: 'There was an error submitting your data.',
          //   });
          // } 
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((error) => {
          setLoading(false);
                Swal.fire({
              icon: 'warning',
              title: 'Submission Error',
              text: 'There was an error in processing the image',
            });
          console.error('Error submitting data:', error);
        })
    }
    else if(activeStep===1 && fieldData.processed_images.length>0){
      console.log("welcome1")
      setrootcausedata(fieldData)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else if(activeStep===2 && fieldData.root_cause_identified.length===0){
      console.log("welcome2")
      const ids = localStorage.getItem('field_investigation_id');
      const storedId = localStorage.getItem('row_id');
      let url = `${API.UPDATE_FIELD_INVESTIGATION}/${ids}`
      const rdata=rootcausedata.map(image => image.Crack_type)
      // const pimage=rootcausedata.map(image => image.imageurl)
      // console.log("rdata",rdata)
      // const raw_images = fieldData.raw_images.map(imageObj => {
      //   console.log("fieldfield12",imageObj.imageName)
      //   const cleanUrl = imageObj.imageName.split("?")[0];
      //   const parts = cleanUrl.split("/");
      //   return {
      //     ...imageObj,
      //     imageName: parts[parts.length - 2] + "/" + parts[parts.length - 1],
      //     imageDescription: imageObj.imageDescription
      // };
      // });
      // const processedimageNames = pimage.map(item => {
      //   const cleanUrl = item.split("?")[0];
      //   const parts = cleanUrl.split("/");
      //   return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      // });
      axios.post(url, {
        collection_of_parts: fieldData.collection_of_parts,
        date_of_investigation: fieldData.date_of_investigation,
        field_observation: fieldData.field_observation,
        mode_of_transport: fieldData.mode_Transport,
        assessment_id: storedId,
        processed_images: fieldData.processed_images_names,
        raw_images: fieldData.raw_images_names,
        need_to_process:[],
        raw_videos: fieldData.raw_videos,
        recommendation: fieldData.recommendation,
        root_cause_identified: rdata,
        processed_image_status:1,
        approval_status:1
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
        .then((resp) => {
          const ids = localStorage.getItem('row_id');
    let url = `${API.UPDATE_ASSESMENT}/${ids}`
    axios.post(url, {
      dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: parsedData.nature_of_complaint, status: "Waiting For Approval"
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        console.log(res)
        if (res.data.affected > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: 'Your data has been updated successfully!',
          })

        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }

      });
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
       
    }
    else if(activeStep===2 && fieldData.root_cause_identified.length>0){
      console.log("welcome3")
      const ids = localStorage.getItem('field_investigation_id');
      const storedId = localStorage.getItem('row_id');
      let url = `${API.UPDATE_FIELD_INVESTIGATION}/${ids}`
      // const raw_images = fieldData.raw_images.map(imageObj => {
      //   console.log("fieldfield12",imageObj.imageName)
      //   const cleanUrl = imageObj.imageName.split("?")[0];
      //   const parts = cleanUrl.split("/");
      //   return {
      //     ...imageObj,
      //     imageName: parts[parts.length - 2] + "/" + parts[parts.length - 1],
      //     imageDescription: imageObj.imageDescription
      // };
      // });
      // const processedimageNames = fieldData.processed_images.map(item => {
      //   const cleanUrl = item.split("?")[0];
      //   const parts = cleanUrl.split("/");
      //   return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      // });
      axios.post(url, {
        collection_of_parts: fieldData.collection_of_parts,
        date_of_investigation: fieldData.date_of_investigation,
        field_observation: fieldData.field_observation,
        mode_of_transport: fieldData.mode_Transport,
        assessment_id: storedId,
        processed_images: fieldData.processed_images_names,
        raw_images: fieldData.raw_images_names,
        need_to_process:[],
        raw_videos: fieldData.raw_videos,
        recommendation: fieldData.recommendation,
        root_cause_identified: fieldData.root_cause_identified,
        processed_image_status:1,
        approval_status:1
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
        .then((resp) => {
          const ids = localStorage.getItem('row_id');
          let url = `${API.UPDATE_ASSESMENT}/${ids}`
          axios.post(url, {
            dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: parsedData.nature_of_complaint, status: "Waiting For Approval"
          }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
            .then((res) => {
              console.log(res)
              if (res.data.affected > 0) {
                Swal.fire({
                  icon: 'success',
                  title: 'Update Successful',
                  text: 'Your data has been updated successfully!',
                })
      
              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Submission Error',
                  text: 'There was an error submitting your data.',
                });
              }
      
            });
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
       
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (redirectUrl === 'Tab') {
      // If 'tab', set selectedImages to an empty array
      setSelectedImages([]);
      setSelectedNocImages([])
    }

    
    
  };


  const generateClaimNumber = async () => {
    let cliam_type_name, region_name = '';
    let glassStatus = '';

    if (dealerInformation.claim_type_id === 1) {
      cliam_type_name = "AGR"
    } else if (dealerInformation.claim_type_id === 2) {
      cliam_type_name = "OEM"
    } else {
      cliam_type_name = "SPD"
    }
    if (dealerInformation.region_id === 1) {
      region_name = "South"
    } else if (dealerInformation.region_id === 2) {
      region_name = "North"
    } else if (dealerInformation.region_id === 3) {
      region_name = "East"
    } else {
      region_name = "West"
    }

    const cliamTypeSub = cliam_type_name.substring(0, 1);
    const regionNameSub = region_name.substring(0, 1);

    const vehicalModel = vechileInformation.vehicle_Model;
    const vehicalModelSub = vehicalModel.substring(0, 3).toUpperCase();

    const productionDate = glassInformation.Production_Date;
    const formattedProductionDate = generateProductionDate(productionDate);

    if (glassInformation.glass_status === 1) {
      glassStatus = "Initial";
    } else {
      glassStatus = "Replaced";
    }

    const glassStatusSub = glassStatus.substring(0, 1);

    const serialNumber = await generateFourDigits();

    const claim_number = `${cliamTypeSub}${regionNameSub}${vehicalModelSub}${formattedProductionDate}${glassStatusSub}${serialNumber}`;
    console.log("claim_number", claim_number);
    // localStorage.setItem('claim_number', claim_number);

    return claim_number;
  }

  const generateProductionDate = (date) => {
    const [year, month, day] = date.split('-');
    const formattedDate = `${day}${month}${year}`;
    return formattedDate;
  }

  const generateFourDigits = async () => {
    const latestAssessment = await Services.getLatestAssessment();
    console.log("latestAssessment", latestAssessment);
    if (!latestAssessment) {
      // setIsLoading(false);
      return "0001"; // No latest record, default to "001"
    }
    const assessmentClaimNumber = latestAssessment[0].Assessment_claim_number;
    console.log("assessmentClaimNumber", assessmentClaimNumber);

    let lastFourDigits = assessmentClaimNumber.slice(-4);
    // Convert to number, increment, and format
    lastFourDigits = String(parseInt(lastFourDigits, 10) + 1).padStart(4, '0');

    console.log("formattedLastFourDigits", lastFourDigits);
    return lastFourDigits;

  }


  const dataInsert = async (nature_info) => {
    const claim_number = await generateClaimNumber();
    const successMessage = `Claim Submitted Successfully -  ${claim_number}`;
    console.log("complaintdetails", nature_info);
    let url = API.INSERT_ASSESSMENT
    axios.post(url, {
      dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: nature_info, status: "In-Progress",
      claim_number: claim_number,role_id:userdatajson.role_id,user_id:userdatajson.id
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((data) => {
        if (data.data.raw.length > 0) {
          localStorage.setItem("claim_number", claim_number);
          Swal.fire({
            icon: 'success',
            title: 'Submitted Successfully',
            text: successMessage,
          }).then(() => {
            navigate(`/manageassessment`);
            setNatureInfo[""];
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
      });
  };

  const generateImagesArray = async () => {
    const imagesData = [];
    const videoData=[];

    // Use Promise.all to wait for all upload operations to complete
    await Promise.all(
      selectedImages.map(async (imageDataUrl, index) => {
        const imageType = imageDataUrl.substring(
          imageDataUrl.indexOf('/') + 1,
          imageDataUrl.indexOf(';')
        );
        const imageBase64 = imageDataUrl.substring(
          imageDataUrl.indexOf(',') + 1
        );
        const imageName = `image_${Date.now()}_${index}.${imageType}`;

        const claim_number = await generateClaimNumber();
        
        const folder_name = "nature_of_complaint";

        imagesData.push({
          imageName,
          imageBase64,
          imageType,
          claim_number,
          folder_name
          
        });
      })
    );

    console.log("selectedVideos",selectedVideos);

    if (selectedVideos.length > 0) {
      await Promise.all(
        selectedVideos.map(async (imageDataUrl, index) => {
          const videoType = imageDataUrl.substring(
            imageDataUrl.indexOf('/') + 1,
            imageDataUrl.indexOf(';')
          );
          const imageBase64 = imageDataUrl.split(',')[1];
          const videoName = `image_${Date.now()}_${index}.${videoType}`;
    
          const claim_number = await generateClaimNumber();
    
          videoData.push({
            videoName,
            imageBase64,
            claim_number,
          });
        })
      );
      await uploadBlobImageVideoAPI(imagesData, videoData);
    }else{
      await uploadBlobImagesAPI(imagesData);
    }

  };
  
  const generateFieldInvestigateImagesArray = async () => {
    const imagesData = [];
    const videoData=[];

    // Use Promise.all to wait for all upload operations to complete
    await Promise.all(
      selectedImages.map(async (imageDataUrl, index) => {
        const imageType = imageDataUrl.substring(
          imageDataUrl.indexOf('/') + 1,
          imageDataUrl.indexOf(';')
        );
        const imageBase64 = imageDataUrl.substring(
          imageDataUrl.indexOf(',') + 1
        );
        const imageName = `image_${Date.now()}_${index}.${imageType}`;

        const claim_number = localStorage.getItem('claim_number');
        
        const folder_name = "raw";

        imagesData.push({
          imageName,
          imageBase64,
          imageType,
          claim_number,
          folder_name
          
        });
      })
    );

    console.log("selectedVideos",selectedVideos);

    if (selectedVideos.length > 0) {
      await Promise.all(
        selectedVideos.map(async (imageDataUrl, index) => {
          const videoType = imageDataUrl.substring(
            imageDataUrl.indexOf('/') + 1,
            imageDataUrl.indexOf(';')
          );
          const imageBase64 = imageDataUrl.split(',')[1];
          const videoName = `image_${Date.now()}_${index}.${videoType}`;
    
          const claim_number = localStorage.getItem('claim_number');
          const folder_name = "raw";
    
          videoData.push({
            videoName,
            imageBase64,
            claim_number,
            folder_name
          });
        })
      );
      await uploadBlobImageVideoFieldInvestigationAPI(imagesData, videoData);
    }else{
      await uploadBlobImagesFieldInvestigationAPI(imagesData);
    }

  };

  const updateImagesArray = async () => {
    try {
      const images = [];
      const videos=[];
      console.log("selectedImages", selectednocImages);

      // Process each image
      for (let index = 0; index < selectednocImages.length; index++) {
        const imageDataUrl = selectednocImages[index];

        if (imageDataUrl.startsWith('data:image/')) {
          const imageType = imageDataUrl.substring(
            imageDataUrl.indexOf('/') + 1,
            imageDataUrl.indexOf(';')
          );
          const imageBase64 = imageDataUrl.substring(
            imageDataUrl.indexOf(',') + 1
          );
          const claim=localStorage.getItem('assessment_data')
          const claimdata=JSON.parse(claim)
          const claim_number=claimdata.claim_number
          const folder_name='nature_of_complaint'
          const imageName = `image_${Date.now()}_${index}.${imageType}`;
          images.push({
            imageName,
            imageBase64,
            imageType,
            claim_number,
            folder_name
          });
        }
      }
      for (let index = 0; index < selectedVideos.length; index++) {
        const imageDataUrl = selectedVideos[index];

        if (imageDataUrl.startsWith('data:video/')) {
          const imageType = imageDataUrl.substring(
            imageDataUrl.indexOf('/') + 1,
            imageDataUrl.indexOf(';')
          );
          const imageBase64 = imageDataUrl.substring(
            imageDataUrl.indexOf(',') + 1
          );
          const claim=localStorage.getItem('assessment_data')
          const claimdata=JSON.parse(claim)
          const claim_number=claimdata.claim_number
          const videoName = `image_${Date.now()}_${index}.${imageType}`;
          videos.push({
            videoName,
            imageBase64,
            claim_number,  
          });
        }
      }
console.log("updateimagesss",images)
      // Call uploadBlobImagesAPI once after processing all images
      if (images.length > 0 && videos.length===0) {
        const response = await axios.post(API.UPLOAD_BLOB_IMAGES, {
          images,
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        console.log("responseee", response);
        const imagesArray = response.data;
        console.log("imagesArray", imagesArray);
        const imageNames = imagesArray.map((image) => image.url);
        console.log("imageNames", imageNames);
        const updatedFinalImageNames = imageNames.map(url => {
          const cleanUrl = url.split("?")[0];
          const parts = cleanUrl.split("/");
          return parts[parts.length - 2] + "/" + parts[parts.length - 1];
        });

        // Update natureInfo with the new image names
        const updatedNatureInfo = {
          ...natureInfo,
          imageList: [...natureInfo.imageList_noc, ...updatedFinalImageNames],
        };

        await dataUpdate(updatedNatureInfo);
      } 
      else if(images.length>0 && videos.length>0){
        const response = await axios.post(API.UPLOAD_BLOB_IMAGES, {
          images,
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        console.log("responseee", response);
        const imagesArray = response.data;
        console.log("imagesArray", imagesArray);
        const imageNames = imagesArray.map((image) => image.url);
        console.log("imageNames", imageNames);
        const updatedFinalImageNames = imageNames.map(url => {
          const cleanUrl = url.split("?")[0];
          const parts = cleanUrl.split("/");
          return parts[parts.length - 2] + "/" + parts[parts.length - 1];
        });
        const videoresponse=await axios.post(API.UPLOAD_VIDEO, {
          videos,
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        console.log("responseee", videoresponse);
        const videoArray = videoresponse.data;
        console.log("imagesArray", imagesArray);
        const videoNames = videoArray.imageUrl;
      console.log("imageNames1", videoNames);
      const urlsArray = [];
      urlsArray.push(videoNames);
      console.log("natureInfooo1", natureInfo);
      const updatedFinalVideos = urlsArray.map(url => {
        const cleanUrl = url.split("?")[0];
        const parts = cleanUrl.split("/");
        return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      });

        // Update natureInfo with the new image names
        const updatedNatureInfo = {
          ...natureInfo,
          imageList: [...natureInfo.imageList_noc, ...updatedFinalImageNames],
          videoList:[...natureInfo.videoList,...updatedFinalVideos]
        };

        await dataUpdate(updatedNatureInfo);
      }
      else if(images.length===0 && videos.length>0){
        const videoresponse=await axios.post(API.UPLOAD_VIDEO, {
          videos,
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        console.log("responseee", videoresponse);
        const videoArray = videoresponse.data;
        const videoNames = videoArray.imageUrl;
      console.log("imageNames1", videoNames);
      const urlsArray = [];
      urlsArray.push(videoNames);
      console.log("natureInfooo1", natureInfo);
      const updatedFinalVideos = urlsArray.map(url => {
        const cleanUrl = url.split("?")[0];
        const parts = cleanUrl.split("/");
        return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      });

        // Update natureInfo with the new image names
        const updatedNatureInfo = {
          ...natureInfo,
          videoList:[...natureInfo.videoList,...updatedFinalVideos]
        };

        await dataUpdate(updatedNatureInfo);
      }
      else {
        // No base64 images, update natureInfo without calling uploadBlobImagesAPI
        await dataUpdate(natureInfo);
      }
    } catch (error) {
      console.error('Error updating images:', error);
      // Handle error as needed
    }
  };


  // Rest of your code...



  const dataUpdate = async (updatedNatureInfo) => {
    const ids = localStorage.getItem('row_id');
    let url = `${API.UPDATE_ASSESMENT}/${ids}`
    axios.post(url, {
      dealer_info: dealerInformation, vehicle_info: vechileInformation, glass_info: glassInformation, nature_of_complaint: updatedNatureInfo, status: parsedData.status,role_id:userdatajson.role_id,user_id:userdatajson.user_id
    }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        console.log(res)
        if (res.data.affected > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: 'Your data has been updated successfully!',
          }).then(() => {
            navigate(`/manageassessment`);
          });

        }
        else {
          Swal.fire({
            icon: 'warning',
            title: 'Submission Error',
            text: 'There was an error submitting your data.',
          });
        }

      });

  }

  const uploadBlobImagesAPI = async (images) => {
    try {
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      console.log("imagesArray", imagesArray);
      const imageNames = imagesArray.map((image) => image.url);
      const updatedFinalImageNames = imageNames.map(url => {
        const cleanUrl = url.split("?")[0];
        const parts = cleanUrl.split("/");
        return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      });
      const updatedNatureInfo = {
        ...natureInfo,
        imageList: [...natureInfo.imageList, ...updatedFinalImageNames],
      };
      await dataInsert(updatedNatureInfo);

    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };

  const uploadBlobImagesFieldInvestigationAPI = async (images) => {
    try {
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      console.log("imagesArray", imagesArray);
      const imageNames = imagesArray.map((image) => image.url);

      const updatedImageNames = imageNames.map((url, index) => ({
        id: index.toString(),
        name: url,
      }));

      // Update the recoil state with the new image_names array
      await Promise.resolve(setFieldData({
        ...fieldData,
        image_names: updatedImageNames,
      }));
      console.log("good1",fieldData)
      await FieldInsert(updatedImageNames);
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };


  const uploadBlobImageVideoAPI = async (images,videos) => {
    try {
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      console.log("imagesArray", imagesArray);
      const imageNames = imagesArray.map((image) => image.url);
      console.log("imageNames", imageNames);
      console.log("natureInfooo", natureInfo);
      const updatedFinalImageNames = imageNames.map(url => {
        const cleanUrl = url.split("?")[0];
        const parts = cleanUrl.split("/");
        return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      });
      // setNatureInfo((prev) => ({
      //   ...prev,
      //   imageList: [...prev.imageList, ...imageNames],
      // }));
      
      let videourl = API.UPLOAD_VIDEO;
      const responsevideo = await axios.post(videourl, {
        videos,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee1", responsevideo);
      const videoArray = responsevideo.data;
      console.log("imagesArray1", videoArray);
      const videoNames = videoArray.imageUrl;
      console.log("imageNames1", videoNames);
      const urlsArray = [];
      urlsArray.push(videoNames);
      console.log("natureInfooo1", natureInfo);
      const updatedFinalVideos = urlsArray.map(url => {
        const cleanUrl = url.split("?")[0];
        const parts = cleanUrl.split("/");
        return parts[parts.length - 2] + "/" + parts[parts.length - 1];
      });

      const updatedNatureInfo = {
        ...natureInfo,
        imageList: [...natureInfo.imageList, ...updatedFinalImageNames],
        videoList:[...natureInfo.videoList,...updatedFinalVideos],
      };

      await dataInsert(updatedNatureInfo);

    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };

  const uploadBlobImageVideoFieldInvestigationAPI = async (images,videos) => {
    try {
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      console.log("imagesArray", imagesArray);
      const imageNames = imagesArray.map((image) => image.url);
      console.log("imageNames", imageNames);
      console.log("natureInfooo", natureInfo);
      // setNatureInfo((prev) => ({
      //   ...prev,
      //   imageList: [...prev.imageList, ...imageNames],
      // }));
      
      const updatedImageNames = imageNames.map((url, index) => ({
        id: index.toString(),
        name: url,
      }));
      
      let videourl = API.UPLOAD_VIDEO;
      const responsevideo = await axios.post(videourl, {
        videos,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee1", responsevideo);
      const videoArray = responsevideo.data;
      console.log("imagesArray1", videoArray);
      const videoNames = videoArray.imageUrl;
      console.log("imageNames1", videoNames);
      const urlsArray = [];
      urlsArray.push(videoNames);
      console.log("natureInfooo1", natureInfo);

      setFieldData({
        ...fieldData,
        raw_videos:videoNames
      });
      await FieldInsert(updatedImageNames,urlsArray)

    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };
  const submitAssessmentData = async () => {

    const areMandatoryFieldsFilled = checkAllMandatoryFields();

    if (areMandatoryFieldsFilled) {
      await generateImagesArray();

      // Reset stepperError to false after successful data insertion
      setStepperError(false);
    } else {
      // Set stepperError to true when mandatory fields are not filled
      setStepperError(true);

      // Handle case where mandatory fields are not filled
      Swal.fire({
        icon: 'warning',
        title: 'Validation Info',
        text: 'Please fill out all mandatory fields before submitting.',
      });
    }
  };



 
console.log("datafielddatafiled",fieldData)

  // const handleUpdate = () => {
  //   const ids = localStorage.getItem('row_id');
  //   let url = `${API.UPDATE_ASSESMENT}/${ids}`
  //   axios.post(url, {
  //     dealer_info:dealerInformation,vehicle_info:vechileInformation,glass_info:glassInformation,nature_of_complaint:complaintInformation,status:"In-Progress"
  //   })
  //   .then((res) => {
  //     console.log(res)
  //     if(res.data.affected > 0){
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Update Successful',
  //         text: 'Your data has been updated successfully!',
  //       }).then(() => {
  //         navigate(`/manageassessment`);
  //       });

  //     }
  //     else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Submission Error',
  //         text: 'There was an error submitting your data.',
  //       });
  //     }

  //   });
  //  };
  const updateRawImagesFromDescriptions = (updatedImageNames) => {

    console.log(fieldInvestigate, "fieldInvestigate")
    // Make a copy of the raw_images array
    const updatedRawImages = [];

    // Loop through image_desc array
    fieldData.image_desc.forEach((descItem) => {
      // Find the corresponding image_names item by id
      const matchingImageName = updatedImageNames.find(
        (nameItem) => nameItem.id === descItem.id
      );
console.log("matchingImageName",matchingImageName)
      if (matchingImageName) {
        // Update the raw_images array with the matching id
        const indexToUpdate = parseInt(descItem.id, 10); // Convert id to integer
        updatedRawImages[indexToUpdate] = {
          ...updatedRawImages[indexToUpdate],
          imageName: matchingImageName.name,
          imageDescription: descItem.desc,
        };
      }
    });

    // Update the recoil state with the new raw_images array
    setFieldData({
      ...fieldData,
      raw_images: updatedRawImages,
    });

    console.log("updatedRawImages", updatedRawImages);
    // Additional logic as needed
    return updatedRawImages;
  };

  const handleSubmitField = async () => {
    setLoading(true)
    await generateFieldInvestigateImagesArray();
  };

  const FieldInsert = async (updatedImageNames,urlsArray) => {
    if(userdatajson.role_id===3){
      const storedId = localStorage.getItem('row_id');
      console.log("storedIdDown", storedId);
      const resultUpdatedRawImages = updateRawImagesFromDescriptions(updatedImageNames);
      console.log(resultUpdatedRawImages, "resultUpdatedRawImages")
          const imageNames = updatedImageNames.map(obj => obj.name);
    console.log("updatedImageNamesupdatedImageNames1",imageNames)
    const updatedimageNames = imageNames.map(url => {
      const cleanUrl = url.split("?")[0];
      const parts = cleanUrl.split("/");
      return parts[parts.length - 2] + "/" + parts[parts.length - 1];
    });
    console.log("updatedImageNamesupdatedImageNames2",updatedimageNames)
    const updatedData = resultUpdatedRawImages.map((item, index) => ({
      imageName: updatedimageNames[index],
      imageDescription:item.imageDescription
    }));
     
      if (storedId) {
        // const formattedDate = moment(originalDate).format("DD/MM/YYYY");
  
        let url = API.INSERT_FIELDINVESTIGAT;
        axios.post(url, {
          collection_of_parts: fieldData.collection_of_parts,
          date_of_investigation: fieldData.date_of_investigation,
          field_observation: fieldData.field_observation,
          mode_of_transport: fieldData.mode_Transport,
          assessment_id: storedId,
          processed_images: [],
          raw_images: updatedData,
          raw_videos: urlsArray,
          need_to_process:updatedimageNames,
          recommendation: "",
          root_cause_identified: [],
          processed_image_status:null,
          approval_status:null
        }, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        })
          .then((data) => {
           
            if (data.data.raw.length > 0) {
              Swal.fire({
                icon: 'success',
                title: 'Submitted Successfully',
                text: 'Your data has been submitted successfully!',
              }).then(() => {
                navigate(`/manageassessment`);
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Submission Error',
                text: 'There was an error submitting your data.',
              });
            }
          })
          .catch((error) => {
            console.error('Error submitting data:', error);
          }) 
      } else {
        console.error('Assessment ID not found in local storage');
        
      }
    }
    else{
      const storedId = localStorage.getItem('row_id');
    console.log("storedIdDown", storedId);
    const resultUpdatedRawImages = updateRawImagesFromDescriptions(updatedImageNames);
    console.log(resultUpdatedRawImages, "resultUpdatedRawImages")
    console.log("updatedImageNamesupdatedImageNames",updatedImageNames)
    
    const imageNames = updatedImageNames.map(obj => obj.name);
    console.log("updatedImageNamesupdatedImageNames1",imageNames)
    const updatedimageNames = imageNames.map(url => {
      const cleanUrl = url.split("?")[0];
      const parts = cleanUrl.split("/");
      return parts[parts.length - 2] + "/" + parts[parts.length - 1];
    });
    console.log("updatedImageNamesupdatedImageNames2",updatedimageNames)
    const updatedData = resultUpdatedRawImages.map((item, index) => ({
      imageName: updatedimageNames[index],
      imageDescription:item.imageDescription
    }));
    if (storedId) {
      // const formattedDate = moment(originalDate).format("DD/MM/YYYY");

      let url = API.INSERT_FIELDINVESTIGAT;
      axios.post(url, {
        collection_of_parts: fieldData.collection_of_parts,
        date_of_investigation: fieldData.date_of_investigation,
        field_observation: fieldData.field_observation,
        mode_of_transport: fieldData.mode_Transport,
        assessment_id: storedId,
        processed_images: [],
        raw_images: updatedData,
        raw_videos: urlsArray,
        need_to_process:updatedimageNames,
        recommendation: "",
        root_cause_identified: [],
        processed_image_status:null,
        approval_status:null
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
        .then((data) => {
          console.log("f1",data)
          console.log("f2",data.data.raw[0].id)
          setId(data.data.raw[0].id)
          localStorage.setItem("field_investigation_id",data.data.raw[0].id);
          // if (data.data.raw.length > 0) {
          //   Swal.fire({
          //     icon: 'success',
          //     title: 'Submitted Successfully',
          //     text: 'Your data has been submitted successfully!',
          //   }).then(() => {
          //     navigate(`/manageassessment`);
          //   });
          // } else {
          //   Swal.fire({
          //     icon: 'warning',
          //     title: 'Submission Error',
          //     text: 'There was an error submitting your data.',
          //   });
          // }
          const url = `${API.GET_FIELD_INVESTIGATION}/${storedId}`;
            axios.get(url, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              }
            }).then((response) => {
              console.log("responseField", response.data);
              console.log("rawraw1",fieldData)
              if (response) {
  
                  const newData = response.data[0];
                  console.log(newData, "newData");
                  console.log("newData",newData.raw_images);
                  console.log("newData1",newData.mode_of_transport);
                  // const imageDescArray = newData.raw_images.map((imageData, index) => ({
                  //     id: index.toString(),
                  //     desc: imageData.imageDescription || '', // Use the imageDescription or an empty string if not available
                  // }));
  
                  const dateObject = new Date(newData.date_of_investigation);
  
                 // Get the date portion as a string (YYYY-MM-DD)
                 const dateOnly = dateObject.toISOString().slice(0, 10);
                 const nextDay = new Date(dateObject);
  nextDay.setDate(dateObject.getDate() + 1);
  
  // Get the next day's date portion as a string (YYYY-MM-DD)
  const nextDayString = nextDay.toISOString().slice(0, 10);
  
                  setFieldData((prevData) => ({
                      ...prevData,
                      date_of_investigation: nextDayString,
                      // image_desc: imageDescArray,
                      // image_names: newData.image_names,
                      raw_images: newData.raw_images,
                      raw_images_names:newData.raw_images_names,
                      processed_images_names:newData.processed_images_names,
                      //   raw_videos: newData.raw_videos,
                      processed_images: newData.processed_images,
                      need_to_process:newData.need_to_process,
                      // field_observation: newData.field_observation,
                      mode_Transport: newData.mode_of_transport,
                      collection_of_parts: newData.collection_of_parts,
                      root_cause_identified:newData.root_cause_identified,
                      recommendation: newData.recommendation,
                  }));
                  // setSelectedVideos([newData.raw_videos])
              } 
            })
            .catch((error) => {
              // Handle error
              console.error('Failed to save area:', error);
            });
          setLoading(false)
          setSubmitCompleted(true);
        })
        .catch((error) => {
          console.error('Error submitting data:', error);
        }) 
    } else {
      console.error('Assessment ID not found in local storage');
      setLoading(false)
    }
    }
  };


  const handleUpdateAssessment = async () => {
    await updateImagesArray();
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  console.log("loadinggg",loading)

  return (
    <div className={classes.root}>
         {loading && userdatajson.role_id!=3 && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position:'fixed',top:0,width:'100%',background:'#000000ab',zIndex:'9999' }}>
        <CircularProgress /> {/* Show circular progress loader while loading is true */}
      </div>
    )}
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>

      </div>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', padding: "20px" }}>
              {activeStep === 0 &&
                <CustomButton
                  onClick={dataFlow === 0 ? submitAssessmentData : handleUpdateAssessment}
                  text={dataFlow === 0 ? "Submit" : "Update"}
                />
              }
              {activeStep === 1 && redirectUrl === 'Tab' ?
                <CustomButton
                  text={dataFlow === 1 ? "Submit" : "Update"}
                  onClick={handleSubmitField}
                />
                : null // Add a placeholder or replace with the appropriate JSX for the opposite condition
              }


              {/* {
          activeStep === 1 &&
          (
            <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
              {redirectUrl === 'View' ? (
                <>
                  <CustomButton text={fieldButtonText}  style={{marginRight:"10px"}}/>
                  <CustomButton text="Next" />
                </>
              ) : (
                <CustomButton
                  text={dataFlow === 1 ? "Submit" : "Update"}
                  onClick={handleSubmitField}
                />
              )}
            </div>
          )
        } */}
              <div style={{ marginLeft: activeStep === 0 ? "20px" : "0" }}></div>
              {activeStep === 0 && dataFlow === 1 && (
                <CustomButton
                  text={"Next"}
                  onClick={handleNext}
                  style={{ textTransform: 'none' }}
                />
              )}

              <div style={{ marginLeft: activeStep === 1 ? "20px" : "0" }}></div>
              {activeStep === 1 && dataFlow === 1 && userdatajson.role_id!=3 &&(
                <CustomButton
                  text={"Next"}
                  onClick={handleNext}
                  style={{ textTransform: 'none' }}
                  // disabled={!submitCompleted}
                />
              )}

              {activeStep === 2 && dataFlow === 1 && (
                <CustomButton
                  text={"Next"}
                  onClick={handleNext}
                  style={{ textTransform: 'none' }}
                />
              )}
            </div>
          </div>
        )}
      </div>

    </div>
  );
}

export default complaintDetails;
