import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { menuList } from '../recoil/atoms';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography,
  Button
} from '@mui/material';
import routes from '../../routes';
import { AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import SidebarMenuIcon from '../../assets/icons/ic_side_bar_menu_icon.svg';
import NotificationIcon from '../../assets/icons/ic_notification.svg';
import SaintGobainIcon from '../../assets/icons/ic_saint_gobain.svg';
import LogoutIcon from '../../assets/icons/ic_logout.svg';
import ProfileIcon from '../../assets/icons/ic_user_profile_blue.svg'
import { useNavigate } from "react-router-dom";
import { Paper, Popover } from '@material-ui/core';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import Avatar from "@mui/material/Avatar";
import { useLocation } from 'react-router-dom';
import AddIcon from '../../assets/icons/ic_plus.svg';
import BackIcon from '../../assets/icons/ic_back.svg';
import NewIcon from '../../assets/icons/ic_add_blue.svg';




// import QrScanner from '../input/QRCodeScanner';

const drawerWidth = 200;

function LeftDrawer({ onClickFunction }) {
  const [menu, setMenu] = useRecoilState(menuList);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const isUserManagementPath = location.pathname === '/usermanagement';
  const isNewUserPath = location.pathname === '/newuser';
  const isManagementPath = location.pathname === '/manageassessment';


  let name = '';
  let plantName = '';
  let plant_id = '';
  let fullNameInitials = '';
  const userData = JSON.parse(localStorage.getItem("userData"));

  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      const firstName = userData.firstname?.charAt(0) || ''; // Handle undefined or null
      const lastName = userData.lastname?.charAt(0) || '';
      name = userData.firstname + " " + lastName;
      fullNameInitials = firstName + lastName;
      plant_id = userData.plant_id;

      if (plant_id === 1) {
        plantName = "Chennai";
      } else if (plant_id === 2) {
        plantName = "Pune";
      } else if (plant_id === 3) {
        plantName = "ALSC";
      } else if (plant_id === 4) {
        plantName = "HLSC";
      } else {
        plantName = "Bhiwadi";
      }
    }
  } catch (error) {
    // Handle JSON parsing errors or other potential issues
    console.error("Error parsing user data:", error);
  }


  const changeMenu = (name) => {
    setMenu(name);
  };
  const clearLocalStorage = () => {
    localStorage.clear();
   
  }
  const handleLogout = () => {
    clearLocalStorage();
const url='https://uat.cloudgateway.saint-gobain.com/openam/XUI#logout&realm=AccessManagement&goto=https://dev.windshieldidna.saint-gobain.com';
window.location.href=url
  }

  const handleListItemClick = () => {
    handleDrawerClose();
  }

  
  const handleProfileClick = () => {
   navigate('/profile')
  }

  const drawer = (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%', // Set a specific height
      width: 280,
      background: 'linear-gradient(to bottom, #1278B4, #193D75)', // Linear gradient background
    }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Toolbar />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '1rem', marginLeft: 20 }}>
            <Avatar sx={{ bgcolor: '#0D508B', width: "70px", height: "70px" }} onClick={() => { handleProfileClick }}>
              {fullNameInitials}
            </Avatar>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Typography style={{ color: '#FFFFFF', fontSize: 20, fontWeight: 600, marginBottom: '3px' }}>{name}</Typography>
            <Typography style={{ color: '#FFFFFF', fontSize: 16 }}>{plantName}</Typography>
          </div>
        </div>
        <List>  {routes.mainRoutes.map((text, index) =>
      (text.name !== "User Management" || userData.role_id === 1 || userData.role_id === 2) ? (
        getListItemSet(text, index,handleListItemClick)
      ) : null
    )}</List>
        <Divider />
      </div>

      <div style={{
        textAlign: 'center',
        padding: '10px',
        width: 250,
        position: 'fixed',
        bottom: 20,
      }}>
        <img src={SaintGobainIcon}></img>
        <Typography style={{ color: 'white', fontSize: 18, fontWeight: 700 }}>SAINT GOBAIN</Typography>
        <Typography style={{ color: 'white' }}>© 2023 INDEC4.0 Saint-Gobain</Typography>
        <Button
          variant="contained"
          style={{
            width: "158px",
            borderRadius: '3px',
            marginRight: 15,
            background: '#0D508B',
            fontSize: 20,
            color: "white",
            marginTop: "10px",
            textTransform: 'none',
            marginLeft: '5'
          }}
          onClick={handleLogout}
        >
          <img src={LogoutIcon} style={{ marginRight: 10 }} alt="button icon" />
          Logout
        </Button>
      </div>

    </div>

  );

  const handleUserManagement = () => {
    onClickFunction();
  }


  function getListItemSet(text, index) {
    const isSelected = menu === text.name;

    const listItemTextStyle = {
      color: 'white',
      marginLeft: '20px',
      padding: '10px',
      borderBottom: isSelected
      ? '2px solid white'
      : 'none', // Set the initial border color
      background: isSelected
      ? `linear-gradient(90deg, #296FAC 1.17%, #142D63 97.67%);` // Gradient colors when selected
      : 'none',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 'bold',
      fontWeight: isSelected ? "bold": 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      textAlign: 'left',
    };


    return text.icon ? (
      <NavLink key={index} style={{ textDecoration: 'none', }} to={text.path}>
        <ListItem
          key={text}
          disablePadding
          sx={{ display: 'block' }}
          selected={isSelected}
          // style={{ backgroundColor: isSelected ? '#00448B' : '', }}
          onClick={() => {
            changeMenu(text.name);
            setOpen(!open); // Toggle the open state when clicking anywhere in the ListItem
          }}        >

          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: 'initial',
              px: 2.5
            }}
          >
            {isSelected ? (
              <SvgIcon style={{ color: 'black' }}>
                <text.iconSelected stock={'white'} />
              </SvgIcon>
            ) : (
              <SvgIcon style={{ color: 'white' }}>
                <text.icon stock={'white'} />
              </SvgIcon>
            )}
            <ListItemText
        disableTypography
        primary={<Typography variant="body2" style={listItemTextStyle}>{text.name}</Typography>}
      />
          </ListItemButton>
        </ListItem>
      </NavLink>
    ) : null;
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackIcon = () => {
    navigate(-1);
  }
  const handleOpenQr = () => {
    // navigate(`/qrScanner`);
  }

  const popoverContent = (
    <div style={{
      padding: 16,
      width: '40vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>
      <div onClick={handleOpenQr} style={{ display: 'flex', padding: 20, }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Scan QR</Typography>
      </div>
      <div style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
          New Assessment
        </Typography>

      </div>

    </div>
  );
  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}>
          <Toolbar style={{ backgroundColor: 'white', justifyContent: 'space-between' }}>

            {isNewUserPath ? (
              <IconButton
                color="inherit"
                aria-label="menu"
              >
                <img src={BackIcon} onClick={handleBackIcon} alt="Sidebar Menu" />
              </IconButton>
            ) :
              (
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpen(true)}
                >
                  <img src={SidebarMenuIcon} alt="Sidebar Menu" />
                </IconButton>
              )}


            <IconButton color="inherit">
              {/* Conditional rendering of the heading based on the path */}
              {isUserManagementPath || isNewUserPath ? (
                <Typography style={{ color: "#0D508B", fontFamily: "Inter", fontSize: "20px", fontWeight: 600 }}>User Management</Typography>
              ) : (
                <Typography style={{ color: "#0D508B", fontFamily: "Inter", fontSize: "20px", fontWeight: 600 }}>Assessment</Typography>
              )}
            </IconButton>
            <IconButton color="inherit">
              {/* Conditional rendering of the right-side icon based on the path */}
              {/* {isUserManagementPath ? (
                <img onClick={handleUserManagement} src={AddIcon} alt="User Management Icon" />
              ) : (
                <img src={NotificationIcon} alt="Notification Icon" />
              )} */}

              {isUserManagementPath ? (
                <img onClick={handleUserManagement} src={AddIcon} alt="User Management Icon" />
              ) : (
                isManagementPath ? (
                  <img src={NewIcon} alt="Plus Icon" onClick={handleUserManagement} />
                ) : (
                  <img src={ProfileIcon} alt="Notification Icon" onClick={handleProfileClick}/>
                )
              )}

            </IconButton>
            {/* Add your additional toolbar content here */}
          </Toolbar>
        </AppBar>
        <Drawer
          open={open}
          onClose={() => setOpen(false)} >
          {drawer}

        </Drawer>
      </Box>
      <div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper style={{ width: '100%' }}>{popoverContent}</Paper>
        </Popover>
      </div>

    </div>
  );
}
export default LeftDrawer;
