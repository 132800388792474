import React from 'react';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import { Paper, Popover } from '@material-ui/core';
import { Typography } from "@mui/material";


const AddBottomPopover = ({ anchorEl, open, onClose, handleCamera, handleGallery}) => {
    const handleGalleryClick = () => {
        handleGallery();
      };
    const popoverContent = (
        <div style={{
            padding: 16,
            width: '100vw',
            boxSizing: 'border-box',
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={HandleIcon} />
            </div>
            <div onClick={handleCamera} style={{ display: 'flex', padding: 20, }}>
                <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Camera</Typography>
            </div>
            <div onClick={handleGalleryClick} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
                <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
                    Upload from Gallery
                </Typography>
              
            </div>
        </div>
    );
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: window.innerHeight, left: 0 }} // update anchorPosition here
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
      <Paper style={{ width: '100%' }}>{popoverContent}</Paper> {/* add style prop here */}
    </Popover>
    );
};

export default AddBottomPopover;
