import configParam from '../../config';
   const API = {
   INSERT_ASSESSMENT : configParam.API_URL+'insert_assesment',
   IMAGE_UPLOAD : configParam.API_URL+'upload_allImages',
   GET_ASSESMENT : configParam.API_URL+'get_assisment',
   GET_ASSESMENT_IN_PROGRESS : configParam.API_URL+'get_assisment_progress',
   EDIT_ASSESMENT : configParam.API_URL+'edit_assisment',
   UPDATE_ASSESMENT : configParam.API_URL+'update_assesment',
   FUNCTION :configParam.API_URL+'function',
   ROLE :configParam.API_URL+'role',
   PLANT :configParam.API_URL+'plant',
   INSERT_USER :configParam.API_URL+'insert_user',
   UPDATE_USER :configParam.API_URL+'update_user',
   GET_USERS :configParam.API_URL+'users',
   DELETE_USER :configParam.API_URL+'delete_user',
   DELETE_IMAGE:configParam.API_URL+'delete_image',
   DELETE_VIDEO:configParam.API_URL+'delete_video',
   GET_LATEST_ASSESSMENT:configParam.API_URL+'latest_assesmment',
   INSERT_FIELDINVESTIGAT:configParam.API_URL + 'insert_fieldInvestigation',
   INSERT_ROOT:configParam.API_URL + 'get_processed_image',
   EDIT_FIELDINVESTIGAT:configParam.API_URL + 'edit_fieldInvestigation',
   GET_FIELD_INVESTIGATION:configParam.API_URL + 'get_field_investigation',
   UPLOAD_BLOB_IMAGES:configParam.API_URL + 'upload_allImages',
   UPDATE_FIELD_INVESTIGATION:configParam.API_URL + 'update_field_investigation',
   UPLOAD_VIDEO:configParam.API_URL + 'upload_allVideos',
   DASHBOARD_DATA:configParam.API_URL+'get_dashboard',
   PDF_DATA:configParam.API_URL+'get_field_investigation'



   }
export default API;
