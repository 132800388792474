import React, { useRef, useState, useEffect } from 'react';
import DateTimeMonth from '../input/DateTimeMonth';
import { Typography } from '@mui/material';
import { Paper, Popover } from '@material-ui/core';
import CloudUploadIcon from '../../assets/icons/ic_cloud_upload.svg';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import TextCommendBox from '../input/TextCommendBox';
import CheckBox from '../input/CheckBox';
import SelectBox from '../input/SelectBox';
import { imageType, chooseImages, FieldInvestigation, viewVisitData,videoType } from "../recoil/atoms"
import { useRecoilState, useRecoilValue } from 'recoil';
import CapturePhoto from '../assessment/manageassessment/CapturePhoto';
import ImageSliderText from '../input/ImageSliderText';
import CaptureVideo from '../assessment/manageassessment/CaptureVideo';
import VideoPreview from '../input/VideoPreview';
import moment from 'moment';
import configParam from "../../config";
import service from '../sevices/Api';
import axios from 'axios';
import Helper from '../sevices/Helper';
import API from '../sevices/Api';
import TextBox from '../input/TextBox';
import Swal from 'sweetalert2';

const deepCopy = configParam.DEEP_COPY;
const access_token = localStorage.getItem("accesstoken");
const FieldInvestigationTab = () => {
  const fileInputRef = useRef(null);
  const videoInputRef=useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorVideoEl, setAnchorVideoEl] = useState(null);
  const [showSelectBox, setShowSelectBox] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showVideoComponent, setShowVideoComponent] = useState(false);
  const [selectedImages, setSelectedImages] = useRecoilState(imageType);
  
  const [DelImages, setDelImages] = useState([]);
  const [comments, setComments] = useState([]);
  const captureImageArray = useRecoilValue(chooseImages);
  const [selectedVideo, setSelectedVideos] = useRecoilState(videoType);
  const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);
  const [count, setCount] = useState(0);
  const [checkcount,setcheckcount]=useState(0);
  const [modecount,setmodecount]=useState(0);

  const assessment_id = localStorage.getItem('row_id');
  // setFieldData({
  //   date_of_investigation: "",
  //   image_desc: [{
  //     id: "",
  //     desc: ""
  //   }],
  //   image_names: [{
  //     id: "",
  //     name: ""
  //   }],
  //   raw_images: [{
  //     imageName:"",
  //     imageDescription:""
  //   }],
  //   raw_videos:[],
  //   processed_images: [],
  //   field_observation: "",
  //   mode_Transport: "",
  //   collection_of_parts:false,
  //   root_cause_identified:"",
  //   recommendation:"",
  // });

  // useEffect(() => {
  //   fetchData();
  // }, [assessment_id]);
  // console.log("FIELDDATAAA", fieldData);

  // const fetchData = async () => {
  //   try {
  //     const url = `${API.GET_FIELD_INVESTIGATION}/${assessment_id}`; 
  //     const response = await axios.get(url);
  //     console.log("response",response)
  //     console.log("responseField",response.data);
  //     if (response) {

  //       const newData = response.data;
  //       console.log(newData, "newData");
  //       setEditFieldData(newData);


  //       // setFieldData((prevData) => ({
  //       //   ...prevData,
  //       //   // date_of_investigation: newData.date_of_investigation,
  //       //   // image_desc: newData.image_desc,
  //       //   // image_names: newData.image_names,
  //       //   raw_images: newData.raw_images,
  //       //   raw_videos: newData.raw_videos,
  //       //   // processed_images: newData.processed_images,
  //       //   // field_observation: newData.field_observation,
  //       //   mode_Transport: newData.mode_Transport,
  //       //   collection_of_parts: newData.collection_of_parts,
  //       //   // root_cause_identified: newData.root_cause_identified,
  //       //   // recommendation: newData.recommendation,
  //       // })); 
  //     }else{
  //       setEditFieldData([]);
  //     }
  //   } catch (error) {
  //     // setError(error.message || 'An error occurred while fetching data.');
  //     // setEditFieldData([]);
  //   }
  // };

  useEffect(() => {
    const getImageDataUris = captureImageArray.map(item => item.dataUri);
    let mergedArray = [...getImageDataUris, ...selectedImages];
    setDelImages(mergedArray)
  }, [captureImageArray, selectedImages])
  useEffect(() => {
    const updatedImageNames = selectedImages.map((url, index) => ({
      id: index.toString(),
      name: url,
    }));
  
    // Update the recoil state with the new image_names array
    setFieldData({
      ...fieldData,
      image_names: updatedImageNames,
    });
  }, [selectedImages])

 

  const handlePopover = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleVideoPopover = (event) => {
    event.preventDefault();
    setAnchorVideoEl(event.currentTarget);

  };
  const handleCheckBoxClick = () => {
    // setShowSelectBox(!showSelectBox); 
    setFieldData({
      ...fieldData,
      collection_of_parts: !fieldData.collection_of_parts,
    });
    setcheckcount(checkcount+1)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleVideoClose = () => {
    setAnchorVideoEl(null);
  };
  const handleCamera = () => {
    setShowComponent(true);
    setAnchorEl(false)
  }
  const handleGallery = () => {
    fileInputRef.current.click();

  }
  const handleVideoGallery = () => {
    videoInputRef.current.click();

  }
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFieldData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setmodecount(modecount+1)
  }
  const handleVideoCamera = () => {
    setShowVideoComponent(true);
    setShowComponent(false);
  }

  const handleFileSelect = async (event) => {
    const files = event.target.files; // Get the selected files from the input
    const maxAllowedFiles = 5;
    console.log("imagessssss")
    // Check if the number of selected files exceeds the maximum allowed
    if (files.length > maxAllowedFiles) {
      alert(`You can only select up to ${maxAllowedFiles} images.`);
      // Clear the file input to prevent uploading more files
      fileInputRef.current.value = null;
    } else {
      // Process the selected files here
      const loadedImages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageUrl = e.target.result;

          // Push the loaded image URL to the array
          loadedImages.push(imageUrl);

          // Check if all images are processed, then update the state
          if (loadedImages.length === files.length) {
            // Update the state with the selected images
            setSelectedImages(prevState => [...prevState, ...loadedImages]);

            // Display the selected images
            loadedImages.forEach((loadedImageUrl) => {
              const image = new Image();
              image.src = loadedImageUrl;
              document.body.appendChild(image);
            });

            // Now, you can make the API call or perform any other actions if needed
            // uploadBlobImagesAPI(selectedImages);
          }
        };

        reader.readAsDataURL(files[i]); // Read the selected file as a data URL
      }
    }
  };

  const uploadBlobImagesAPI = async (images) => {
    try {
      setShowSelectBox(true);
      let url = API.UPLOAD_BLOB_IMAGES;
      const response = await axios.post(url, {
        images,
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseee", response);
      const imagesArray = response.data;
      const imageNames = imagesArray.map((image) => image.url);
      console.log("imageNames", imageNames);

      // Assuming image_names is part of your state (e.g., fieldData)
      const updatedImageNames = imageNames.map((url, index) => ({
        id: index.toString(),
        name: url,
      }));

      // Update the recoil state with the new image_names array
      setFieldData({
        ...fieldData,
        image_names: updatedImageNames,
      });

      console.log("fieldData", fieldData);

    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };
  console.log("fieldData", fieldData);

  const handleDeleteIconClick = (imageUrl, index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'red',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.isConfirmed) {
        setFieldData((prevFieldData) => {
            if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
                const imageName = getFileNameFromUrl(imageUrl);
                console.log("imageName", imageName);
                const urlObject = new URL(imageUrl);
                const pathParts = urlObject.pathname.split('/');
                console.log("pathpath",pathParts)
                const foldername=pathParts[2]
                const claimnumber=pathParts[3]
                deleteExistingImageFromBlob(imageName,foldername,claimnumber);
      // Process as HTTP URL
    }
  
          // Remove the image from raw_images
          const rawImages = [...prevFieldData.raw_images];
          rawImages.splice(index, 1);
  
          // Log to verify that the image is removed      
          console.log('New RAW', rawImages);
  
          // Return the updated state
          return {
            ...prevFieldData,
            raw_images: rawImages,
          };
         
        });
        setDelImages((prevFieldData) => {
        // Remove the image from raw_images
        const rawImages = [...prevFieldData];
        rawImages.splice(index, 1);

        // Log to verify that the image is removed      
        console.log('New RAW1', rawImages);

        return rawImages;
       
      });
      }
    });
  };

  const handleFileSelectGallery = async (event) => {
    let tmpObj = deepCopy(fieldData);
    if (event.target && event.target.files) {
      const selectedFiles = event.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      const fileNames = selectedFilesArray.map((file) => file.name);
      const fileType = selectedFilesArray.map((file) => file.type);

      // Assuming raw_images is an array
      tmpObj.raw_images = selectedFilesArray.map((file, index) => ({
        imageName: fileNames[index],
        imageDescription: "",
        // base64Image: null, 
      }));

      const imagePromises = selectedFilesArray.map((file, index) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
            resolve(base64Image);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      });

      try {
        const base64Images = await Promise.all(imagePromises);
        setSelectedImages(base64Images);
        setFieldData(tmpObj);
        setShowVideoComponent(false);
        // uploadBase64Image(base64Images, fileNames, fileType);
      } catch (error) {
        console.error("Error reading files:", error);
      }
    } else {
      console.error("Event object or its 'files' property is undefined.");
    }
  };
  // Azure Blob Section
  async function uploadBase64Image(base64Images, fileNames, fileType) {
    let url = service.IMAGE_UPLOAD
    const imageObjects = base64Images.map((base64, index) => ({ image: base64, imageName: fileNames[index], fileType: fileType[index] }));
    await axios.post(url, { image: imageObjects }, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((response) => {
        console.log('Upload successful!', response.data);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
      })

  }

  const handleFileVideoSelectGallery = async (event) => {
    const selectedFiles = event.target.files;
    const selectedFile = selectedFiles[0];
    console.log("videosssss")
    try {
      const base64Video = await readFileAsDataURL(selectedFile);
      setSelectedVideos([base64Video]);
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Video = e.target.result;
        resolve(base64Video);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDateChange = (e, key) => {
    if (key === "date_of_investigation") {
      const temObj = { ...fieldData };
      temObj.date_of_investigation = e.target.value;
      setFieldData(temObj);
      setCount(count+1)
    }
  };



  const popoverContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>
      <div onClick={handleCamera} style={{ display: 'flex', padding: 20, }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Camera</Typography>
      </div>
      <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
          Upload from Gallery
        </Typography>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          accept="image/png, image/jpeg, image/web"
          multiple
        />
      </div>

    </div>
  );

  // VideoPOPUP Content
  const VideoContent = (
    <div style={{
      padding: 16,
      width: '100vw',
      boxSizing: 'border-box',
    }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HandleIcon} />
      </div>
      <div onClick={handleVideoCamera} style={{ display: 'flex', padding: 20, }}>
        <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Video Camera</Typography>
      </div>
      <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
        <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
        <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
          Upload Video from Gallery
        </Typography>
        <input
          type="file"
          ref={fileInputRef} // Assign the input element to the ref
          style={{ display: 'none' }}
          onChange={handleFileVideoSelectGallery}
          accept="video/*"

        />
      </div>

    </div>
  );
console.log("dellog",DelImages)
  return (
    <div style={{ margin: "15px" }}>

      <Typography
        variant='h4'
        style={{
          textAlign: 'left',
          color: '#252C32',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.408px',
        }}
      >
        Field Investigation
      </Typography>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: "#909190",
          marginTop: "10px"
        }}
      >
        The data required for performing the assessment on the cracked images are uploaded here.
      </Typography>
      <div style={{ marginTop: "20px" }}>
        <TextBox
          id="toDate"
          label="Date of Field Visit *"
          type="date"
          value={count > 0 ? fieldData.date_of_investigation : ""}
          onChange={(e) => handleDateChange(e, "date_of_investigation")}
        />
      </div>
      {DelImages.length === 0 && <div style={{ marginTop: "20px" }}>
        <Typography style={{ textAlign: "left" }}>Defect Image</Typography>
        <div style={{
          border: "2px dashed #00448B",
          padding: "20px",
          flex: 1,
          display: 'flex',
          width: "100%",
          height: 150,
          marginTop: 10,
          backgroundColor: '#00448a1a',
          marginRight: 20,
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px"
        }}>
          <img onClick={handleGallery} src={CloudUploadIcon} style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
          }} alt="My Image" />
          <Typography style={{
            textAlign: 'center',
            marginLeft: 15,
            color: 'black',
            fontWeight: 600,
            width: 170,
            fontSize: 16
          }}>Upload your Images or Browse</Typography>
         <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple
        />
        </div>
      </div>}
      <div>
        {showComponent && <CapturePhoto />}
      </div>

      <div style={{ marginTop: "30px" }}>
        {
          DelImages.length > 0 && DelImages.length <= 5 &&
          <div>

            <ImageSliderText
              images={DelImages}
              descriptions={""}
              setSelectedImages={setDelImages}
              handlePopover={handlePopover}
              onDeleteIconClick={handleDeleteIconClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelectGallery}
              multiple
            />
          </div>
        }
      </div>
      <div style={{ marginTop: "20px" }}>
        <Typography style={{ textAlign: "left" }}>Defect Video (Optional)</Typography>
        <div style={{
          border: "2px dashed #00448B",
          padding: "20px",
          flex: 1,
          display: 'flex',
          width: "100%",
          height: 150,
          marginTop: 10,
          backgroundColor: '#00448a1a',
          marginRight: 20,
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: "4px"
        }}>
          <img onClick={handleVideoGallery} src={CloudUploadIcon} style={{
            width: 30,
            height: 30,
            marginLeft: 10,
            marginBottom: 10,
          }} alt="My Image" />
          <Typography style={{
            textAlign: 'center',
            marginLeft: 15,
            color: 'black',
            fontWeight: 600,
            width: 170,
            fontSize: 16
          }}>Upload your Videos or Browse</Typography>
             <input
          type="file"
          ref={videoInputRef} // Assign the input element to the ref
          style={{ display: 'none' }}
          onChange={handleFileVideoSelectGallery}
          accept="video/*"

        />
        </div>
      </div>
      <div>
        {showVideoComponent && <CaptureVideo />}
      </div>

      <div style={{ marginTop: "30px" }}>
        {
          selectedVideo ?
            <VideoPreview selectedVideo={selectedVideo} /> : null
        }

      </div>

      <div>
        <CheckBox label="Collection of Part Required" name="collection_of_parts" handleCheckBoxClick={handleCheckBoxClick} value={checkcount>0? fieldData.collection_of_parts:false} />
      </div>
      <div style={{ marginTop: 10 }}>
        {checkcount>0 && fieldData.collection_of_parts && (
          <SelectBox
            labelVariant="body2"
            isLable={true}
            lableValue="Mode of Transport"
            options={Helper.ModeOfTransportArray}
            value={modecount>0?fieldData.mode_Transport:""}
            name="mode_Transport"
            onChange={handleFieldChange}
            labelCondition="name"
          />

          // <SelectBox labelVariant="body2" isLable={true} lableValue="Mode of Transport" options={options} name="mode_Transport" value={fieldData.mode_Transport} onChange={handleFieldChange} />
        )}
      </div>


      {/* Image POPUP */}
      <div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper style={{ width: '100%' }}>{popoverContent}</Paper>
        </Popover>
      </div>


      {/* Video POPUP */}
      <div>
        <Popover
          open={Boolean(anchorVideoEl)}
          anchorVideoEl={anchorVideoEl}
          onClose={handleVideoClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: window.innerHeight, left: 0 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper style={{ width: '100%' }}>{VideoContent}</Paper>
        </Popover>
      </div>
    </div>
  );
}
export default FieldInvestigationTab;

