import React ,{useState} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TextField, Typography } from '@mui/material';
import TextCommendBox from '../input/TextCommendBox';
import SelectBox from '../input/SelectBox';
import RawImageIcon from '../../assets/icons/ic_raw_image.png';
import TextBox from '../input/TextBox';
import Helper from '../sevices/Helper';
import { rootData,FieldInvestigation } from '../recoil/atoms';
import ImageSliderRoot from '../input/ImageSliderRoot';
import ImageSliderProcessed from '../input/ImageSliderProcessed';

const RootCause = () => {
  const options = ["External Demage", "Manufacturing Defect", "Abused Product", "others"]
  const [rootCause, setRootCause] = useState(Helper.RootCauseArray[0]?.id || ''); // Set the default value to the ID of the first option
  const [rootcausedata,setrootcausedata]=useRecoilState(rootData)
  const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);
  const handleRootCauseIdentifiedChange = (event) => {
    const selectedValue = event.target.value;
    selectedRootCause(selectedValue);
  };

  const handleDateChange = (e, key) => {
    if (key === "recommendation") {
      const temObj = { ...fieldData };
      temObj.recommendation = e.target.value;
      setFieldData(temObj);
    }
  };

console.log("dataroot",rootcausedata)
console.log("fielddat",fieldData)
  return (
    <div style={{ margin: 15 }}>
      <Typography
        variant='h4'
        style={{
          textAlign: 'left',
          color: '#252C32',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.408px',
        }}
      >
        Root Cause Analysis
      </Typography>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: "#909190",
          marginTop: "10px"
        }}
      >
       The processed images are rendered and the findings and results are recorded here
      </Typography>
      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: 'var(--dark-gray-dark-gray-2, #252C32)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '-0.084px',
        }}>
          Complaint No: 12345678
        </Typography>
      </div>
      <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
        <Typography style={{
          color: 'var(--dark-gray-dark-gray-2, #252C32)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '-0.084px',
        }}>
          Name: Test name
        </Typography>
        <Typography style={{
          color: 'var(--dark-gray-dark-gray-2, #252C32)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '-0.084px',
        }}>
          Location: Chennai
        </Typography>
      </div>


      <div style={{marginTop:"30px"}}>
                 {rootcausedata && (
                        <ImageSliderRoot images={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.raw_images.imageName)
                          : rootcausedata.raw_images.map(image => image.imageName)}
                            descriptions={Array.isArray(rootcausedata)
                              ? rootcausedata.map(image => image.raw_images.imageDescription)
                              : rootcausedata.raw_images.map(image => image.imageDescription)}
                        />
                    )}
                </div>

      {/*  processsed image */}
      <div style={{marginTop:"30px"}}>
                 {rootcausedata &&  (
                        <ImageSliderProcessed images={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.imageurl)
                          : rootcausedata.processed_images.map(image => image)}
                        prediction={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.Crack_type)
                          : rootcausedata.root_cause_identified.map(image => image)}
                        rootCauseIdentified={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.Crack_type)
                          : rootcausedata.root_cause_identified.map(image => image)}
                        />
                    )}
                </div>

          <div style={{ marginTop: "20px" }}>
            <Typography style={{
              color: '#333',
              fontVariantNumeric: 'slashed-zero',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}>Result</Typography>
            <TextField
              name={`Observation`}
              value={fieldData.recommendation ? fieldData.recommendation : ""}
              onChange={(e) => handleDateChange(e, "recommendation")}
              isString={true}
              multiline={true}
              style={{ width: '100%', marginTop: "10px" }}  // Adjust the height property as needed  
            />
          </div>

    </div>



  );
}

export default RootCause;
