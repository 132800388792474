import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { useRecoilState } from 'recoil';
import { viewVisitData, editVisitData, flowType } from "../../recoil/atoms"
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import LowPriorityIcon from '../../../assets/icons/ic_low_priority.svg';
import HighPriorityIcon from '../../../assets/icons/ic_high_priority.svg';
import MessageIcon from '../../../assets/icons/ic_message.svg';
import AvatarIcon from '../../../assets/icons/ic_avatar.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import API from '../../sevices/Api';
import deleteIcon from '../../../assets/icons/ic_delete.svg';
import editIcon from '../../../assets/icons/ic_edit.svg';
import shareIcon from '../../../assets/icons/ic_share.svg';
import Divider from '@material-ui/core/Divider';


const access_token = localStorage.getItem("accesstoken");

const InProgressTab = () => {
  const [sampleDataView, setSampleDataView] = useRecoilState(viewVisitData);
  const [editDataView, setEditDataView] = useRecoilState(editVisitData);
  const [processedImageStatus,setprocessedImageStatus]=useState(null);
  const [approvedStatus,setapprovedStatus]=useState(null);
  const [, setEditflowType] = useRecoilState(flowType);
  // const [editFieldData, setEditFieldData] = useRecoilState(editFieldInvestigat);
  const userdata=localStorage.getItem('userData')
  const userdatajson=JSON.parse(userdata)

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Row, setRow] = useState(null)
  const navigate = useNavigate();

  const handleClick = async(event, id) => {
    setAnchorEl(event.currentTarget);
    setRow(id)
    console.log("did",id)
    console.log("eventttttt",event)
    console.log()
    const url = `${API.GET_FIELD_INVESTIGATION}/${id.id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("response", response);
      console.log("responseField", response.data);
      if (response.status === 200) {
        const newData = response.data[0];
        console.log(newData, "newData");
        const field_investigation_id = newData.id;
        setprocessedImageStatus(newData.processed_image_status)
        setapprovedStatus(newData.approval_status)
      

        
      } else {
        console.log("NORESPONSE");
      }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
    // setRow(id)
  };
  console.log("anchorelll",anchorEl)

  useEffect(() => {
    getOpenSampleViewData();
  }, [editDataView]);


  const editFieldInvestigation = async (id) => {
    try {
      const ids = id;
      const url = `${API.EDIT_FIELDINVESTIGAT}/${ids}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseField", response.data);
      if (response.data.length > 0) {
        const newData = response.data[0];
        console.log("newData", newData)
        // setEditFieldData(newData);
        // setEditDataView(response.data[0]);
        // navigate(`/ComplaintDetails`);
      }
    } catch (error) {
      console.error(error);
    }
  };



  const editAssisment = async (id) => {
    try {
      const ids = id; // Your ID value
      const url = `${API.EDIT_ASSESMENT}/${ids}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      if (response.data.length > 0) {
        setEditDataView(response.data[0]);
        navigate(`/ComplaintDetails`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOpenSampleViewData = async () => {
    if(userdatajson.role_id===3){
      try {
        const id=userdatajson.id;
        let url = `${API.GET_ASSESMENT}/${id}`
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
  
        if (response.data.length > 0) {
          const newArr = response.data.map((z, index) => {
            return response.data[index];
          });
          setSampleDataView(newArr);
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        setIsLoading(false);
      }
    }
    else{
      try {
        let url = API.GET_ASSESMENT_IN_PROGRESS
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });
  
        if (response.data.length > 0) {
          const newArr = response.data.map((z, index) => {
            return response.data[index];
          });
          setSampleDataView(newArr);
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        setIsLoading(false);
      }
    }

  };

  const handleOpenStepper = () => {
    setEditflowType(1)
    setAnchorEl(null);
    setIsLoading(true);
    editAssisment(Row.id);
    const assessmentData = JSON.stringify(Row);
    localStorage.setItem("assessment_data",assessmentData);
    localStorage.setItem('row_id', Row.id);
    editFieldInvestigation(Row.id)
  }
  console.log("approv",approvedStatus)
  return (
    <div>
      {isLoading && <div className='loader'><CircularProgress /> </div>}
      {sampleDataView.map((items, index) => (
        <Card style={{ width: "100%", paddingBottom: 10, paddingTop: 10, marginBottom: "30px" ,
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',}} key={index}>
        
          <div style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", display: "flex", }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px", color: "#252C32", marginLeft: 20 }}>Claim No: {items.claim_number}</Typography>
            <Typography style={{
              fontWeight: 600, fontSize: "10px",
              background: items.status === 'Resolved' ? '#148E00' : "#F8DD4E",
              borderRadius: "5px", marginLeft: 15, padding: 5, color: "#333333"
            }}>{items.status}</Typography>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                style={{ '&:focus': { backgroundColor: 'transparent' } }}
                onClick={(e) => handleClick(e, items)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    // background: 'rgba(255, 255, 255, 0.0)', // Adjust the alpha value as needed
                    // boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.15)',
                    width: '150px', // Set the desired width
                  },
                }}
              >
                {userdatajson.role_id === 1 || (userdatajson.role_id === 3 && approvedStatus === null)|| (userdatajson.role_id === 2 && approvedStatus === null) ? (
                <MenuItem onClick={() => handleOpenStepper(items.id)}>
                  <img src={editIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                  <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}>Edit</Typography>
                </MenuItem>
                 ):null}
                <Divider />
                <MenuItem onClick={handleClose}>
                  <img src={shareIcon} alt="Share Icon" style={{ marginRight: '10px' }} />
                  <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}>Share</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <img src={deleteIcon} alt="Delete Icon" style={{ marginRight: '10px' }} />
                  <Typography style={{
                    color: '#FF3347',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}>Delete</Typography>
                </MenuItem>
                <Divider />
              </Menu>
            </div>

          </div>
          <hr style={{ margin: '0 20px' }} />

          <div style={{ margin: 10 }}>
            <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography style={{ color: "#909190", fontWeight: 500, fontSize: "12px" }}>Dealer Name</Typography>
              <Typography style={{ color: '#909190', fontSize: "12px", fontWeight: 500 }}>Dealer Location</Typography>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography style={{ color: "#252C32", fontWeight: 600, fontSize: "14px" }}>{items.dealer_info.dealer_Name}</Typography>
              <Typography style={{ color: '#252C32', fontSize: "14px", fontWeight: 600 }}>{items.dealer_info.dealer_Address}</Typography>
            </div>
          </div>

          <div style={{ margin: 10 }}>
            <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography style={{ color: "#909190", fontWeight: 500, fontSize: "12px" }}>Start Date</Typography>
              <Typography style={{ color: '#909190', fontSize: "12px", fontWeight: 500 }}>End Date</Typography>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
              <Typography style={{ color: "#252C32", fontWeight: 600, fontSize: "14px" }}>{moment(items.created_at).format(("DD-MMM-YYYY"))}</Typography>
              <Typography style={{ color: '#252C32', fontSize: "14px", fontWeight: 600 }}>{moment(items.created_at).add(21, 'days').format("DD-MMM-YYYY")}</Typography>
            </div>
            <hr style={{ margin: '10px' }} />
          </div>
          <div style={{ justifyContent: "space-between", display: "flex", margin: 10 }}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <img src={LowPriorityIcon} alt="Menu Icon" style={{ marginLeft: "5px" }} />
              <Typography
                style={{
                  color: "#252C32",
                  fontWeight: 600,
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "150px"
                }}
              >
                {/* {item.assessmentTitle} */}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
              <img src={HighPriorityIcon} alt="Menu Icon" style={{ marginRight: "10px" }} />
              <img src={MessageIcon} alt="Menu Icon" style={{ marginRight: "10px" }} />
              <img src={AvatarIcon} alt="Menu Icon" />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default InProgressTab;
