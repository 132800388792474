import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import MenuIcon from '../../../assets/icons/ic_menu.svg';
import LowPriorityIcon from '../../../assets/icons/ic_low_priority.svg';
import HighPriorityIcon from '../../../assets/icons/ic_high_priority.svg';
import MessageIcon from '../../../assets/icons/ic_message.svg';
import AvatarIcon from '../../../assets/icons/ic_avatar.svg';
import {useRecoilValue } from 'recoil';
import {viewVisitData} from"../../recoil/atoms"
import moment from 'moment';

const ResolvedTab = () => {
  const statusFilterAssesment = useRecoilValue(viewVisitData);
  console.log("statusFilterAssesment",statusFilterAssesment);
  const filteredData = statusFilterAssesment.filter((item) => item.status ==="Resolved");
  console.log("filteredData",filteredData);
 
 
  //       complaintNo: '12345678',
  //       status: 'Resolved',
  //       dealerName: 'Tata Motors',
  //       dealerLocation: 'Chennai, Tamil Nadu',
  //       startDate: '12/10/2023',
  //       endDate: '12/10/2023',
  //       assessmentTitle: 'Assessment Timeline',
  //     },
  //     {
  //         complaintNo: '87654321',
  //         status: 'Resolved',
  //         dealerName: 'Tata Motors1',
  //         dealerLocation: 'Chennai, Tamil Nadu',
  //         startDate: '12/10/2023',
  //         endDate: '12/10/2023',
  //         assessmentTitle: 'Assessment Timeline',
  //       },
  //       {
  //         complaintNo: '5553234',
  //         status: 'Resolved',
  //         dealerName: 'Tata Motors2',
  //         dealerLocation: 'Chennai, Tamil Nadu',
  //         startDate: '12/10/2023',
  //         endDate: '12/10/2023',
  //         assessmentTitle: 'Assessment Timeline',
  //       },
  //       {
  //         complaintNo: '55543421',
  //         status: 'Resolved',
  //         dealerName: 'Tata Motors3',
  //         dealerLocation: 'Chennai, Tamil Nadu',
  //         startDate: '12/10/2023',
  //         endDate: '12/10/2023',
  //         assessmentTitle: 'Assessment Timeline',
  //       },
  //     // Add more data objects here
  //   ];

  return (
      <div>
        {filteredData.map((items, index) => (
          <Card style={{ width: "100%", paddingBottom: 10, paddingTop: 10 , marginBottom:20}} key={index}>
            <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex", padding: 10 }}>
              <Typography style={{ fontWeight: 600, fontSize: "14px" }}>Complaint No: {items.vehicle_info.vinNumber}</Typography>
              <Typography style={{ fontWeight: 600, fontSize: "10px", background:"#148E00", borderRadius: "5px", padding: 5, color:"#333333" }}>{items.status}</Typography>
              <img src={MenuIcon} alt="Menu Icon" />
            </div>
            <hr style={{ margin: '0 10px' }} />
  
            <div style={{ paddingTop: 10 }}>
              <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
                <Typography style={{ color: "#909190", fontWeight: 500, fontSize: "10px" }}>Dealer Name</Typography>
                <Typography style={{ color: '#909190', fontSize: "10px", fontWeight: 500 }}>Dealer Location</Typography>
              </div>
              <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
                <Typography style={{ color: "#252C32", fontWeight: 600, fontSize: "14px" }}>{items.dealer_info.customer_Name}</Typography>
                <Typography style={{ color: '#252C32', fontSize: "14px", fontWeight: 600 }}>{items.dealer_info.delarAddress}</Typography>
              </div>
            </div>
  
            <div style={{ paddingTop: 15 }}>
              <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
                <Typography style={{ color: "#909190", fontWeight: 500, fontSize: "10px" }}>Start Date</Typography>
                <Typography style={{ color: '#909190', fontSize: "10px", fontWeight: 500 }}>End Date</Typography>
              </div>
              <div style={{ justifyContent: "space-between", display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
                <Typography style={{ color: "#252C32", fontWeight: 600, fontSize: "14px" }}>{moment(items.created_at).format(("DD-MMM-YYYY"))}</Typography>
                <Typography style={{ color: '#252C32', fontSize: "14px", fontWeight: 600 }}>YYYY/MM/DD</Typography>
              </div>
              <hr style={{ margin: '10px' }} />
            </div>
            <div style={{ justifyContent: "space-between", display: "flex", paddingRight:"10px"}}>
              <div style={{ flexDirection: "row", display: "flex"}}>
                <img src={LowPriorityIcon} alt="Menu Icon" />
                <Typography
                  style={{
                    color: "#252C32",
                    fontWeight: 600,
                    fontSize: "14px",
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    overflow: "hidden", // Hide overflow text
                    textOverflow: "ellipsis", // Add ellipsis when text overflows
                    width:"150px"
                  }}
                >
                  {/* {item.assessmentTitle} */}
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={HighPriorityIcon} alt="Menu Icon" style={{ marginRight: "10px" }} />
                <img src={MessageIcon} alt="Menu Icon" style={{ marginRight: "10px" }} />
                <img src={AvatarIcon} alt="Menu Icon"/>
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
};
export default ResolvedTab;