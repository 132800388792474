import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import { Button } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VechileInfo from "../assessment/VehicleInfo"
import GlassInfo from '../assessment/GlassInfo';
import NatureofComplaint from '../assessment/NatureofComplaint';
import DealerInfo from '../assessment/DealerInfo';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editVisitData, delearInfo, glassInfo, NatureofComplaintInfo, vechicleInfo, FieldInvestigation } from "../recoil/atoms"
import { Route, Routes , useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import QRCodeScanner from '../input/QRCodeScanner';


const AssismentComplaintDetails = ({ stepperError }) => {
  const [expanded, setExpanded] = useState('panel1');
  const convertedJSON = useRecoilValue(editVisitData);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(true);
  //  const fieldInvestigationValue=useRecoilValue(editFieldInvestigat);
  const [dealerInfo, setDealerInfo] = useRecoilState(delearInfo);
  const [fieldInfo, setFieldInfo] = useRecoilState(FieldInvestigation);

  const [glassInformation, setGlassInformation] = useRecoilState(glassInfo);
  const [natureComplaint, setNatureComplaint] = useRecoilState(NatureofComplaintInfo);
  const [vechicleInformation, setVechicleInformation] = useRecoilState(vechicleInfo);
  const [showQRCodeScanner, setShowQRCodeScanner] = useState(false);
  const [scannedData, setScannedData] = useState(null);
  const qrscandata=JSON.parse(localStorage.getItem('qrdata'))

  const navigate = useNavigate();


  const setAllDataAtom = () => {
    setDealerInfo({
      ...dealerInfo,
      dateComplaint_record: convertedJSON ? convertedJSON.dealer_info.dateComplaint_record : "",
      complaintRecord_customer: convertedJSON ? convertedJSON.dealer_info.complaintRecord_customer : "",
      dealer_Name: convertedJSON ? convertedJSON.dealer_info.dealer_Name : "",
      dealer_Address: convertedJSON ? convertedJSON.dealer_info.dealer_Address : "",
      contact_Person: convertedJSON ? convertedJSON.dealer_info.contact_Person : "",
      customer_Name: convertedJSON ? convertedJSON.dealer_info.customer_Name : "",
      claim_type_id: convertedJSON ? convertedJSON.dealer_info.claim_type_id : "",
      region_id: convertedJSON ? convertedJSON.dealer_info.region_id : "",
    })

    setVechicleInformation({
      ...vechicleInformation,
      vehicle_Model: convertedJSON ? convertedJSON.vehicle_info.vehicle_Model : "",
      vin_Number: convertedJSON ? convertedJSON.vehicle_info.vin_Number : "",
      date_sale: convertedJSON ? convertedJSON.vehicle_info.date_sale : "",
      Total_kms_Covered: convertedJSON ? convertedJSON.vehicle_info.Total_kms_Covered : "",
    })
    setGlassInformation({
      ...glassInformation,
      glass_Batchcode: convertedJSON ? convertedJSON.glass_info.glass_Batchcode : "",
      replaced_Glass: convertedJSON ? convertedJSON.glass_info.glass_status : "",
      Production_Date: convertedJSON ? convertedJSON.glass_info.Production_Date : qrscandata?qrscandata.packing_created_date:"",
      part_Name: convertedJSON ? convertedJSON.glass_info.part_Name : "",
      glass_status: convertedJSON ? convertedJSON.glass_info.glass_status : "",
      part_number: convertedJSON ? convertedJSON.glass_info.part_number : "",
    })
    setNatureComplaint({
      ...natureComplaint,
      defect_Location: convertedJSON ? convertedJSON.nature_of_complaint.defect_Location : "",
      customer_Voice: convertedJSON ? convertedJSON.nature_of_complaint.customer_Voice : "",
      imageList: convertedJSON ? convertedJSON.nature_of_complaint.imageList : "",
      videoList: convertedJSON ? convertedJSON.nature_of_complaint.videoList : "",
      imageList_noc:convertedJSON ? convertedJSON.nature_of_complaint.imageList_noc : "",

    })


    // setFieldInfo({
    //   // date_of_investigation:fieldInvestigationValue ? fieldInvestigationValue.date_of_investigation :""
    //   mode_of_transport:fieldInvestigationValue ? fieldInvestigationValue.mode_of_transport :"",

    // })
  }


  const handleConfirmDelete = () => {

    setConfirmationModalOpen(false); // Close the confirmation modal
    handleCancelDelete();
    navigate('/qrScanner'); // Navigate to the QRCodeScanner page
  };

  const handleCancelDelete = () => {
    setConfirmationModalOpen(false);
  };

  useEffect(() => {
    setAllDataAtom();
  }, [])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div style={{ margin: 15 }}>

      <Typography
        variant='h4'
        style={{
          textAlign: 'left',
          color: '#252C32',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.408px',
        }}
      >
        Complaint Details
      </Typography>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: "#909190",
          marginTop: "10px", 
          marginBottom:"10px"
        }}
      >
        The data required for performing the assessment on the cracked images are uploaded here.
      </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{margin:10}}
        >
          <Typography sx={{ flexShrink: 0, fontWeight: 600,color:"#333" , fontSize:"18px",fontFamily:"Inter" }}>
            Dealer Information
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <DealerInfo stepperError={stepperError} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{margin:10}}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
           <Typography sx={{ flexShrink: 0, fontWeight: 600,color:"#333" , fontSize:"18px",fontFamily:"Inter" }}>Vehicle Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VechileInfo stepperError={stepperError} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
         style={{margin:10}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ flexShrink: 0, fontWeight: 600,color:"#333" , fontSize:"18px",fontFamily:"Inter" }}>
            Glass Information
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <GlassInfo stepperError={stepperError} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          style={{margin:10}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
           <Typography sx={{ flexShrink: 0, fontWeight: 600,color:"#333" , fontSize:"18px",fontFamily:"Inter" }}>Nature Of Complaint</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NatureofComplaint />
        </AccordionDetails>
      </Accordion>
       <Dialog open={confirmationModalOpen} onClose={handleCancelDelete}>

<DialogTitle>Are you sure you want to scan QR?</DialogTitle>

<DialogActions>

  <Button onClick={handleCancelDelete} >Cancel</Button>

  <Button onClick={handleConfirmDelete} variant="contained" color="primary">

    Confirm

  </Button>

</DialogActions>

</Dialog> 

 <Routes>

      <Route
        path="/qrScanner"
        element={<QRCodeScanner />}
      />
    </Routes> 
    </div>
  );
}


export default AssismentComplaintDetails;
