import React, { useRef, useState, useEffect } from 'react';
import API from '../sevices/Api';
import CircleIcon from '../../assets/icons/ic_take_picture.svg';
import CloseIcon from '../../assets/icons/ic_close1.svg';
import switchCamera from '../../assets/icons/ic_switch_camera.png';
import PauseIcon from '../../assets/icons/ic_pause.png';
import Typography from '@mui/material/Typography';
import { Pause } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const VideoRecorder = () => {
  const videoRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [elapsedTime, setElapsedTime] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const setupCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setMediaStream(stream);

        // Set the video element's srcObject to the camera stream
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    setupCamera();

    // Cleanup function to stop the camera when the component unmounts
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []); // Empty dependency array ensures this effect runs once on component mount
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream);
  
      const recorderInstance = new MediaRecorder(stream);
      setRecorder(recorderInstance);
  
      const chunks = [];
      recorderInstance.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
  
      recorderInstance.onstop = async () => {
        try {
          const recordedBlob = new Blob(chunks, { type: 'video/webm' });
          const videoUrl = URL.createObjectURL(recordedBlob);
          console.log('Video URL:', videoUrl);
  
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64Data = reader.result.split(',')[1];
            console.log("bbb",base64Data)
            const videoName = `video_${Date.now()}.webm`;
  
            try {
              const url = API.UPLOAD_VIDEO;
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  videoData: base64Data,
                  videoName: videoName,
                }),
              });
  
              if (response.ok) {
                const data = await response.json();
                console.log('Video uploaded successfully:', data.videoUrl);
              } else {
                console.error('Failed to upload video:', response.statusText);
              }
            } catch (error) {
              console.error('Error uploading video:', error.message);
            }
          };
  
          reader.readAsDataURL(recordedBlob);
        } catch (error) {
          console.error('Error creating Blob:', error);
        }
      };
  
      // Set the video element's srcObject to the camera stream
      videoRef.current.srcObject = stream;
  
      // Check if autoplay is allowed and start recording
      if (document.documentElement && document.documentElement.clientHeight > 0) {
        videoRef.current.play().then(() => {
          recorderInstance.start();
          setIsRecording(true);
          setShowVideo(false); // Hide the video view when recording starts
  
          // Start the countdown timer
          const intervalId = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
          }, 1000);
  
          // Automatically stop recording after 30 seconds
          setTimeout(() => {
            clearInterval(intervalId);
            stopRecording();
          }, 30000);
        });
      } else {
        // Handle cases where autoplay is restricted
        console.error('Autoplay is restricted. Interact with the page to start recording.');
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };
  
  const stopRecording = () => {
    if (recorder && mediaStream) {
      recorder.stop();
      mediaStream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setElapsedTime(0); // Reset the elapsed time
    }
  };
  const handleClose = () =>{
    navigate(-1);
  }

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black', overflow: 'hidden' }}>
        {isRecording && (
        <div style={{ position: 'absolute', top: '100px', left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
          <Typography variant="h5" style={{ color: 'white' }}>{`00:${elapsedTime < 10 ? `0${elapsedTime}` : elapsedTime}`}</Typography>
        </div>
      )}

      <div style={{ margin: '10px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {showVideo && (
          <video ref={videoRef} style={{ width: '100%', maxWidth: '100%', height: 'auto' }} autoPlay muted playsInline />
        )}
      </div>
      <div style={{
        position: 'absolute',
        bottom: '30px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
        <div style={{ marginLeft: 25, float: 'left' }}>
          <img src={switchCamera} style={{ width: "33px", height: "33px" }} alt="Switch Camera" />
        </div>
        <div style={{ textAlign: 'center' }}>
        
            <img
              onClick={isRecording ? stopRecording : startRecording}
              src={isRecording ? <PauseIcon style={{ width: 50, height: 50 }} /> : CircleIcon}
              alt="Record Button"
            />
       
        </div>
        <div style={{ float: 'right', marginRight: 25 }}>
          <img onClick={handleClose} src={CloseIcon} alt="Close" />
        </div>
      </div>
    </div>
  );
};

export default VideoRecorder;
