import { Button } from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {Tabs, Tab, makeStyles} from '@material-ui/core';
import AllTab from './AllTab';
import InProgressTab from './InProgressTab';
import ResolvedTab from './ResolvedTab';
import React, {} from "react";
import { useNavigate } from 'react-router-dom';
import NewIcon from '../../../assets/icons/ic_plus_white.svg';
import FilterIcon from '../../../assets/icons/ic_filter.svg';
import {  useResetRecoilState,useRecoilState } from "recoil";
import {delearInfo,vechicleInfo,NatureofComplaintInfo,glassInfo,editVisitData,flowType} from"../../recoil/atoms";
import LeftDrawer from '../../drawer/LeftDrawer';


const useStyles = makeStyles({
    customStyleOnTab:{
      fontSize:'16px',
      color:'#000000',
      fontWeight:'400',
      textTransform:'none',
      marginRight:"30px",
      alignSelf:"center"
    },
    customStyleOnActiveTab:{
      color:'blue'
    },
    activeTab:{
      fontSize:'16px',
      fontWeight:'600',
      color:'#909190',
      textTransform:'none',
      fontFamily:"Inter",
      textAlign: 'center',

    },
    headerText:{
    fontSize:"24px",
    fontWeight:'bold',
    textAlign:"left",
    color:"#252C32",
    },

  })
const ManageAssessment = () =>{
    const navigate = useNavigate();
    const classes = useStyles()
    const [value, setValue] = React.useState(0);
    const resetDealerValue = useResetRecoilState(delearInfo);
    const resetVechileDetValue = useResetRecoilState(vechicleInfo);
    const resetGlassInfoDetValue = useResetRecoilState(glassInfo);
    const resetNatureDetValue = useResetRecoilState(NatureofComplaintInfo);
   const resetFormDetValue =useResetRecoilState(editVisitData);
   const [,setTypeFlow]=useRecoilState(flowType);

   
    const handleNewClick  = () => {  
      setTypeFlow(0)  
      resetDealerValue();
      resetVechileDetValue();
      resetGlassInfoDetValue();
      resetNatureDetValue();
      resetFormDetValue();
      navigate('/ComplaintDetails');
    }
 

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
          
    return (
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "20px", padding:15 }}>
          <Typography style={{ textAlign: 'left', color: '#252C32', fontSize: "24px", fontWeight: 600,letterSpacing:"-0.408px",lineHeight:"22px"}}>Manage Assessment</Typography>
          <LeftDrawer onClickFunction={handleNewClick} />
          {/* <Button
            variant="contained"
            style={{
              width: "107px",
              height: "40px",
              borderRadius: '3px',
              marginRight: "15px",
              backgroundColor: "#0D508B",
              fontSize: 20,
              color: "white",
              marginTop: "10px",
              textTransform: 'none',
              marginLeft: '5px'
            }}
            onClick={handleNewClick}
          >
            <img src={NewIcon} style={{ marginRight: 10 }} alt="button icon" />
            New
          </Button> */}
        </div>
        <div style={{ display: 'flex',marginTop:"5px", flexDirection:"column",paddingLeft:15,paddingRight:15}}>
          <Typography style={{fontFamily:"Inter",fontWeight:"400px",fontSize:"16px", textAlign:"left",color:"#909190"}}>The created assessments are listed with details on the timeline, stage, progress and other properties.</Typography>
        </div>

        <div style={{ flexDirection: 'row', display: 'flex', maxWidth: '100%',padding:15 }}>
          <img src={FilterIcon} alt="Filter Icon" />
          <div style={{ position: 'relative', flex: '1' , paddingRight:"15px"}}>
            <input
              type="text"
              placeholder="Search"
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '5px',
                border: '2px solid #E8E8E8',
                marginLeft: '10px', // Add some left margin for spacing
              }}
            />
          </div>
        </div>
            <div style={{ marginTop: 10}}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={value} onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#0D508B",
                                    fontWeight: 500,
                                }
                            }}
                            aria-label="basic tabs example">
                            <Tab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>All</span>}  />
                            <Tab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>In-Progress</span>} />
                            <Tab label={<span className={value === 2 ? classes.activeTab : classes.customStyleOnTab}>Resolved</span>} />

                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} style={{width:"100%",}}>
                        <AllTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}  style={{width:"100%",}}>
                        <InProgressTab />
                    </TabPanel>
                    <TabPanel value={value} index={2}  style={{width:"100%",}}>
                        <ResolvedTab />
                    </TabPanel>
                </Box>
            </div>

        </div>
    )
}
export default ManageAssessment;