import React, { useState} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types'; // Import PropTypes

import { FieldInvestigation} from "../recoil/atoms"
import { useRecoilState } from 'recoil';
import TextBox from './TextBox';
import { Typography, TextField } from '@mui/material';


// import Swal from 'sweetalert2';


const useStyles = makeStyles(() => ({
  imageSliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  },
  sliderImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: "10px"
  },
  arrowButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    position: "inherit",
    top: "50%",
    transform: "translateY(-1000%)",
  },
  leftArrow: {
    left: "10px",
  },
  rightArrow: {
    right: "10px",
  },
  arrowIcon: {
    fontSize: "40px",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "30px",
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  number: {
    width: "20px",
    height: "20px",
    backgroundColor: "#ccc",
    borderRadius: "50%",
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeNumber: {
    backgroundColor: "#00448B",
    color: "white",
    fontSize: "12px"
  },
  iconContainer: {
    position: "absolute",
    bottom: "2px",
    right: "2px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px !important"
  },
  addIcon: {
    fontSize: "30px",
    marginRight: "10px",
    cursor: "pointer",
    color: "#00448B",
  },
  deleteIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "red",
  },
}));
const ImageSliderProcessedResult = ({ images, prediction,rootCauseIdentified }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);

  // Use descriptions prop or fill with empty strings if not provided
  const [predictionValues, setPredictionValues] = useState(prediction);
  const [rootcauseIdentified,setRootCauseIdentified]=useState(rootCauseIdentified)

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };






  console.log("imagesimages",images)
  
  return (
    <div>
        <Typography style={{
          color: '#333',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.408px',
        }}>
          Result
        </Typography>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Date of Complaint Recorded
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Complaint Number
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {fieldData.date_of_investigation}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            2345656
          </Typography>
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Defect Cause
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Result Outcome
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
           {rootcauseIdentified[currentImageIndex]}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Replace Product
          </Typography>
        </div>


        <div style={{ marginTop: "20px" }}>
          <Typography style={{
            color: 'var(--dark-gray-dark-gray-2, #252C32)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Defect Details
          </Typography>
          <div style={{textAlign:'center'}}>
          <button className={`${classes.arrowButton} ${classes.leftArrow}`} onClick={prevImage}>
          <KeyboardArrowLeftIcon className={classes.arrowIcon} />
        </button>

        {/* Image */}
        <img
          className={classes.sliderImage}
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
        />
        <button className={`${classes.arrowButton} ${classes.rightArrow}`} onClick={nextImage}>
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </button>

        {/* Number Container */}
        <div className={classes.numberContainer}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`${classes.number} ${index === currentImageIndex ? classes.activeNumber : ''}`}
              onClick={() => setCurrentImageIndex(index)}>
              {index + 1}
            </span>
          ))}
        </div>
        </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: 'var(--dark-gray-dark-gray-2, #252C32)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Length: 5 mm
          </Typography>
          <Typography style={{
            color: 'var(--dark-gray-dark-gray-2, #252C32)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Height: 6 mm
          </Typography>
          <Typography style={{
            color: 'var(--dark-gray-dark-gray-2, #252C32)',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Width: 8 mm
          </Typography>
        </div>


        <div style={{ marginTop: "20px" }}>
          <Typography style={{
            color: '#333',
            fontVariantNumeric: 'slashed-zero',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
          }}>Outcome/Observations/Recommendations</Typography>
          <TextField
            name={`Observation`}
            value={fieldData.recommendation}
            isString={true}
            multiline={true}
            style={{ width: '100%', marginTop: "10px" }}  // Adjust the height property as needed  
          />
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Approved By
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Contact Number
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            Sendil T - Qty Manager
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            9856482624
          </Typography>
        </div>
    </div>
  );
};

export default ImageSliderProcessedResult;

