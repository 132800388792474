import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckBox(props) {
  return (
  
<div style={{ textAlign: "left"}}>
      <FormControlLabel required control={<Checkbox  onClick={props.handleCheckBoxClick}/>} label={props.label} checked={props.value} />
      </div>
   
  );
}