import React , { useState, useEffect } from 'react';
import axios from 'axios';
import API from '../sevices/Api';


import {
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import ReactApexChart from 'react-apexcharts';


const cardStyle = {
  flex: "1 0 0",
  height: "200px",
  borderRadius: "4px",
  background:"linear-gradient(107deg, #1278B4 29.06%, #153167 111.1%)"


};

const access_token = localStorage.getItem("accesstoken");

const DashBoard = () => {
  const [DashboardData,setDashboardData]=useState([])
  const userdata=localStorage.getItem('userData')
  const userdatajson=JSON.parse(userdata)
  console.log("welcome2224")
  useEffect(() => {
    console.log("welcome22233")
     fetchdata();
  }, []);
const fetchdata=async ()=>{
  if(userdatajson.role_id===3){
    let url = `${API.DASHBOARD_DATA}/${userdatajson.id}`
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        setDashboardData(res.data)
      }) .catch((error) => {
        console.error('Failed to save area:', error);
      });
  }
  else{
    let url = `${API.DASHBOARD_DATA}`
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      .then((res) => {
        setDashboardData(res.data)
      }) .catch((error) => {
        console.error('Failed to save area:', error);
      });
  }


}
  console.log("DashboardData",DashboardData) 
  const years = DashboardData.chart ? DashboardData.chart.map(data => data.year) : [];
  const total = DashboardData.chart ? DashboardData.chart.map(data => data.total_count) : [];
  const completed = DashboardData.chart ? DashboardData.chart.map(data => data.completed_count) : [];
  const progress = DashboardData.chart ? DashboardData.chart.map(data => data.progress_count) : [];

  console.log("yearsyears",years)
  const options = {
    chart: {
      id: 'composed-chart',
    },
    xaxis: {
      categories:years ,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 6,
    },
 
  };
  
  const series = [
    {
      name: 'Line Series',
      type: 'line',
      data: total,
    },
  ];
    return (
      <div style={{padding:'20px'}}> 
         <Grid item lg={12} xs={12} md={12} sm={12}>

<Card style={{ ...cardStyle}}>
  <CardContent>
    <div >
    <div style={{ display: 'flex', justifyContent: 'space-between',flexDirection: 'column' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between',flexDirection: 'column' }}>
      <Typography
        style={{ fontSize: "16px", color: "#FFFFFF",marginTop: "6px",fontWeight:500 }}
        gutterBottom
      >
        Total Assessments
      </Typography>
      <Typography
        style={{ color: "#FFFFFF",fontSize:"32px",fontWeight:600 }}
        gutterBottom
      >
        {DashboardData.totalcount}
      </Typography>
      </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between',flexDirection: 'column' }}>
      <Typography
        style={{ fontSize: "16px", color: "#FFFFFF",marginTop: "6px",fontWeight:500 }}
        gutterBottom
      >
        In Progress
      </Typography>
        
        <Typography
          
          component="div"
          style={{ color: "#FFFFFF",fontSize:"32px",fontWeight:600 }}
        >
           {DashboardData.totalcount-DashboardData.completed}
          {/* {data.trendingValue} */}
        </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between',flexDirection: 'column' }}>
      <Typography
        style={{ fontSize: "16px", color: "#FFFFFF",marginTop: "6px",fontWeight:500 }}
        gutterBottom
      >
        Completed
      </Typography>
        <Typography
          
          component="div"
          style={{ color: "#FFFFFF",fontSize:"32px",fontWeight:600 }}
        >
          {DashboardData.completed}
          {/* {data.trendingValue} */}
        </Typography>
</div>
      </div>


    </div>


  </CardContent>
  {/* </Card>   */}
</Card>

</Grid>

<Grid item >
  <Typography
  style={{marginTop:"40px",marginBottom:"20px",fontWeight:600}}
  >
    Complaint Details
  </Typography>
<ReactApexChart options={options} series={series} type="line" height={450} />
      </Grid>
      </div>
    );
  
}

export default DashBoard;