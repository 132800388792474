import React, { useState,useEffect} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types'; // Import PropTypes
import axios from 'axios';
import API from '../sevices/Api';

const useStyles = makeStyles(() => ({
  videoSliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  },
  sliderVideo: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: "10px",
  },
  arrowButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  leftArrow: {
    left: "10px",
  },
  rightArrow: {
    right: "10px",
  },
  arrowIcon: {
    fontSize: "40px",
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  number: {
    width: "20px",
    height: "20px",
    backgroundColor: "#ccc",
    borderRadius: "50%",
    margin: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeNumber: {
    backgroundColor: "#00448B",
    color: "white",
    fontSize: "12px",
  },
  iconContainer: {
    position: "absolute",
    bottom: "2px",
    right: "2px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px !important",
  },
  deleteIcon: {
    fontSize: "30px",
    cursor: "pointer",
    color: "red",
  },
}));
const access_token = localStorage.getItem("accesstoken");
const VideoSlider = ({ videos,  setSelectedVideos,natureInfo,setNatureInfo }) => {
  const classes = useStyles();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);



  useEffect(() => {
   // Ensure the video source is updated when currentVideoIndex changes
   const videoElement = document.getElementById('videoPlayer');
   if (videoElement) {
     videoElement.src = videos[currentVideoIndex];
     videoElement.load(); // Reload the video to apply the new source
   }
 }, [currentVideoIndex, videos]);
  const prevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const nextVideo = () => {
   setCurrentVideoIndex((prevIndex) => {
     if (prevIndex === videos.length - 1) {
       return 0;
     } else {
       return prevIndex + 1;
     }
   });
 };
 const getFileNameFromUrl = (url) => {
  const urlObject = new URL(url);
  const pathParts = urlObject.pathname.split('/');
  const fileName = pathParts[pathParts.length - 1];
  return fileName;
};

const deleteExistingImageFromBlob = async (imageName,foldername,claimnumber) => {
  try {
    const response = await axios.delete(`${API.DELETE_VIDEO}/${imageName}/${foldername}/${claimnumber}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      }
    });
    console.log("response for delete image", response);
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}

  const onDeleteVideo = (index) => {
    const clickedImageName = videos[index];
    console.log("clickedimages",clickedImageName)
   Swal.fire({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this Video!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'red',
    cancelButtonColor: 'gray',
    confirmButtonText: 'Yes, delete it',
  }).then((result) => {
    if (result.isConfirmed) {
      setNatureInfo((prevNatureInfo) => {
        if (clickedImageName.startsWith('http://') || clickedImageName.startsWith('https://')) {
          const imageName = getFileNameFromUrl(clickedImageName);
          console.log("imageName", imageName);
          const urlObject = new URL(clickedImageName);
          const pathParts = urlObject.pathname.split('/');
          console.log("pathpath",pathParts)
          const foldername=pathParts[2]
          const claimnumber=pathParts[3]
          deleteExistingImageFromBlob(imageName,foldername,claimnumber);
// Process as HTTP URL
}
       
        const newImageList = [...prevNatureInfo.videoList];
        newImageList.splice(index, 1);
        return {
          ...prevNatureInfo,
          videoList: newImageList,
        };
      });
      Swal.fire('Deleted!', 'Your Video has been deleted.', 'success');
    }
  });
 };

  return (
    <div className={classes.videoSliderContainer}>
      <button
        className={`${classes.arrowButton} ${classes.leftArrow}`}
        onClick={prevVideo}
      >
        <KeyboardArrowLeftIcon className={classes.arrowIcon} />
      </button>
      <video
       id="videoPlayer"
        className={classes.sliderVideo}
        controls
      >
      
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
        
      </video>
      <button
        className={`${classes.arrowButton} ${classes.rightArrow}`}
        onClick={nextVideo}
      >
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </button>

      <div className={classes.numberContainer}>
        {videos.map((_, index) => (
          <span
            key={index}
            className={`${classes.number} ${
              index === currentVideoIndex ? classes.activeNumber : ""
            }`}
            onClick={() => setCurrentVideoIndex(index)}
          >
            {index + 1}
          </span>
        ))}
      </div>

      <div className={classes.iconContainer}>
        <DeleteIcon
          className={classes.deleteIcon}
          onClick={() => onDeleteVideo(currentVideoIndex)}
        />
      </div>
    </div>
  );
};

// Add propTypes definition
VideoSlider.propTypes = {
  videos: PropTypes.array.isRequired,
  setSelectedVideos: PropTypes.func.isRequired,
};
export default VideoSlider;
