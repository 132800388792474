
import React from 'react';
import { Typography, TextField } from '@mui/material';
import TextCommendBox from '../input/TextCommendBox';
import SelectBox from '../input/SelectBox';
import Divider from '@material-ui/core/Divider';
import RawImageIcon from '../../assets/icons/ic_raw_image.png';
import RawVideoIcon from '../../assets/icons/ic_raw_video.png';
import CustomButton from '../input/CustomButton';
import ImageSlider from '../input/imageSlider';
import VideoSlider from '../input/VideoSlider';
import { rootData,FieldInvestigation } from '../recoil/atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import ImageSliderProcessedResult from '../input/ImageSliderProcessedResult';
import { json } from 'react-router-dom';



const ResultReport = ({processedImageStatus,approvedStatus,Approve,Reject}) => {
  const options = ["External Demage", "Manufacturing Defect", "Abused Product", "others"];
  const replaced = ["Yes", "No"];
  const replacement = ["Replace not in warranty since it is not a manufacturing Process defect", "Replaced Under Warranty", "Replaced Under Good Will Basis", "Replaced through Insurance By Dealer"];
  const a=localStorage.getItem('assessment_data')
  const parsedData = JSON.parse(a);
  const userdata=localStorage.getItem('userData')
  const userdatajson=JSON.parse(userdata)
  console.log("userdatajson",userdatajson)
  const [rootcausedata,setrootcausedata]=useRecoilState(rootData)
  const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);
  console.log("dataroot1",rootcausedata)
console.log("fielddat1",fieldData)


  return (
    <div style={{ margin: 15 }}>
      <Typography
        variant='h4'
        style={{
          textAlign: 'left',
          color: '#252C32',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.408px',
        }}
      >
        Results/Reports
      </Typography>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: "#909190",
          marginTop: "10px"
        }}
      >
The final presentation includes comprehensive assessment details, defect images, and the recorded final result.
</Typography>

      {/* dealer information */}
      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: '#333',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.408px',
        }}>
          Dealer Information
        </Typography>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Date of Complaint Recorded
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            DOC Recorded by Customer
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.dealer_info.dateComplaint_record}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.dealer_info.complaintRecord_customer}
          </Typography>
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Dealer Name
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Dealer Address
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.dealer_info.dealer_Name}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
           {parsedData.dealer_info.dealer_Address}
          </Typography>
        </div>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Customer Name
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Contact Number
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.dealer_info.customer_Name}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.dealer_info.contact_Person}
          </Typography>
        </div>
        <Divider style={{ marginTop: '20px', background: 'light-gray' }} />
      </div>
      {/*dealer information end  */}


      {/* vehicle informationstart */}
      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: '#333',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.408px',
        }}>
          Vehicle Information
        </Typography>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Vehicle Model
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Vehicle Number
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.vehicle_info.vehicle_Model}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
           {parsedData.vehicle_info.vin_Number}
          </Typography>
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            VIN Number
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Total Kms Covered
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
          {parsedData.vehicle_info.vin_Number}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.vehicle_info.Total_kms_Covered}
          </Typography>
        </div>

        <Divider style={{ marginTop: '20px', background: 'light-gray' }} />
      </div>
      {/* vehicle information end */}


      {/* glass information start */}

      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: '#333',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.408px',
        }}>
          Glass Information
        </Typography>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Date of Sale
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Production Date
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.glass_info.Production_Date}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.glass_info.Production_Date}
          </Typography>
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Part Number
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Part Name
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.glass_info.part_number}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.glass_info.part_Name}
          </Typography>
        </div>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Glass Batch Name
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Initial/Replaced Glass
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
             {parsedData.glass_info.glass_Batchcode}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.glass_info.glass_status}
          </Typography>
        </div>
        <Divider style={{ marginTop: '20px', background: 'light-gray' }} />
      </div>
      {/* glass information end */}

      {/* nature of complaint informationstart */}
      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: '#333',
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.408px',
        }}>
          Nature of Complaint
        </Typography>

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Nature of Complaint
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Customer Invoice
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.nature_of_complaint.customer_Voice}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
            {parsedData.nature_of_complaint.customer_Voice}
          </Typography>
        </div>


        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Defect Location
          </Typography>
          <Typography style={{
            color: '#909190',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.072px',
          }}>
            Customer Invoice
          </Typography>
        </div>
        <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
           {parsedData.nature_of_complaint.defect_Location}
          </Typography>
          <Typography style={{
            color: '#252C32',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '-0.084px',
          }}>
             {parsedData.nature_of_complaint.customer_Voice}
          </Typography>
        </div>

        <Divider style={{ marginTop: '20px', background: 'light-gray' }} />
      </div>
      {/*nature of complaint end */}

      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: 'var(--dark-gray-dark-gray-2, #252C32)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '-0.084px',
        }}>
          Defect Image
        </Typography>
        <div style={{ marginTop: "30px" }}>
        <div>{
          parsedData.nature_of_complaint.imageList> 0 &&
            <ImageSlider
              images={parsedData.nature_of_complaint.imageList}
            />
        }
          </div>
          </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Typography style={{
          color: 'var(--dark-gray-dark-gray-2, #252C32)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '-0.084px',
        }}>
          Defect Video
        </Typography>
        <div style={{ marginTop: "30px" }}>
        <div>
       {parsedData.nature_of_complaint.videoList> 0 &&
            <VideoSlider
              videos={parsedData.nature_of_complaint.videoList}
            />
       }
          </div>
          </div>

        <Divider style={{ marginTop: '20px', background: 'light-gray' }} />
      </div>



      {/*  Result start */}
      <div style={{ marginTop: "20px" }}>
     <ImageSliderProcessedResult images={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.imageurl)
                          : rootcausedata.processed_images.map(image => image)}
                        prediction={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.Crack_type)
                          : rootcausedata.root_cause_identified.map(image => image)}
                        rootCauseIdentified={Array.isArray(rootcausedata)
                          ? rootcausedata.map(image => image.Crack_type)
                          : rootcausedata.root_cause_identified.map(image => image)}
     />
      </div>
      {/* Result end */}

      <div style={{ flex: 1, paddingRight: "5px", marginTop: "30px" }}>
        <CustomButton
          text={"Print"}
          style={{ width: "100%" }}
        />
      </div>
      {userdatajson.role_id === 1 && (
      <div style={{ flex: 1, paddingRight: "5px", marginTop: "30px" }}>
        <CustomButton
          text={"Approve"}
          onClick={Approve}
          style={{ width: "100%" }}
        />
      </div>
       )}
        {userdatajson.role_id === 1 && (
      <div style={{ flex: 1, paddingRight: "5px", marginTop: "30px" }}>
        <CustomButton
          text={"Reject"}
          onClick={Reject}
          style={{ width: "100%" }}
        />
      </div>
 )}

    </div>



  );
}

export default ResultReport;
