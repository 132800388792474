import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function QRCodeScanner() {
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const handleScan = async(data) => {
    if (data) {
      setResult(data);
           const generateTokenResponse = await axios.post(
        "https://dev.lamax.saint-gobain.com/mrp_api/api/UserMaster/ValidateUser",
        {
          sgid: "PRINT_SERVICE",
 password: "",
 domain: "zl",
 type: "LDAP",
 lang: "e",
 plantCode: "839",
 apiUrl: "",
 dbConnectionString: ""
        }
      );
      if (generateTokenResponse.status===200){
      const getQrResponse = await axios.post(
        "https://dev.lamax.saint-gobain.com/mrp_api/api/LinkToInvoice/Get_MRP_Invoice_Glass_Details_Claim_System",
        {
          flag: "string",

          _mrp_label_no: `ODE0NDg4NzAy`
        },
        {
          headers: {
            Authorization: `Bearer ${generateTokenResponse.data.token}`,
          },
        }
      );
      if(getQrResponse.status===200){
        console.log("response.dataaa",getQrResponse.data.response)
        localStorage.setItem("qrdata",JSON.stringify(getQrResponse.data.response[0]))
        navigate('/ComplaintDetails'); // Navigate back to the previous page after scanning
      }
      }
   
    }
  }

  const handleError = (error) => {
    console.error(error);
    // Handle error if needed
  }

  useEffect(() => {
    return () => {
      // Cleanup: Stop the camera when the component is unmounted
    };
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <QrReader
        delay={100}
        onError={handleError}
        onScan={handleScan}
        style={{width:400}}
      />
      <h1>Result: {result}</h1>
    </div>
  );
}

export default QRCodeScanner;
