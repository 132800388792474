import React, { useState } from 'react';
import PropTypes from 'prop-types';

const VideoPreview = ({ selectedVideo }) => {
 const [error, setError] = useState('');
 if (selectedVideo.length === 0) {
  return null; 
}
  const videoUrl = selectedVideo[0]; 

  const handleLoadedMetadata = (event) => {
   const { duration } = event.target;
   if (duration > 30) {
     setError('The selected video must be 30 seconds or less.');
   } else {
     setError('');
   }
 };
  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <video  controls={error ? false : true}  width="500" onLoadedMetadata={handleLoadedMetadata}>
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
};
VideoPreview.propTypes = {
  selectedVideo: PropTypes.string.isRequired,
};

export default VideoPreview;
