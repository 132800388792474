import React, { useState } from "react";
import VideoRecorder from "react-video-recorder";
import { Grid } from "@material-ui/core";

const CaptureVideo = () => {
  const [isRecordingComplete, setIsRecordingComplete] = useState(false);
  const [videoData, setVideoData] = useState(null);

  const handleRecordingComplete = (data) => {
    setIsRecordingComplete(true);
    setVideoData(window.URL.createObjectURL(data));
  };

  const handleCreateNewVideo = () => {
    setIsRecordingComplete(false);
    setVideoData(null);
  };

  return (
    <div className="App">
      <h1>Hello There...</h1>
      {!isRecordingComplete && (
        <Grid container>
          <Grid item xs={10}>
            <VideoRecorder
              timeLimit={30000}
              onRecordingComplete={handleRecordingComplete}
            />
          </Grid>
        </Grid>
      )}
      {isRecordingComplete && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <video
                style={{ width: "100%", height: "100%" }}
                src={videoData}
                controls
              />
            </Grid>
          </Grid>
          <button onClick={handleCreateNewVideo}>
            Take another video
          </button>
        </>
      )}
    </div>
  
  );
};

export default CaptureVideo;
