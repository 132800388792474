import React from 'react';

const HelpSupport = () => {
   
    return (
      <div> 
        <button>Help & Support</button>
      </div>
    );
  
}

export default HelpSupport;