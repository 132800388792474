import React, {useState, lazy } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import "./App.css";
import routes from "./routes.js";
import Login from "./pages/login/Login";
import { AuthContext } from "./pages/context/AuthContext.js";
import RouteMissing from "./pages/context/RouteMissing";
import { RecoilRoot } from "recoil";
const DrawerTemplate = lazy(() => import("./pages/drawer/DrawerTemplate"));

export const switchRoutes = (
  <React.Fragment>
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route path={route.path} name={route.name} element={<route.component />} key={index} />
      ) : (
        <React.Fragment></React.Fragment>
      );
    })}
    <Route path="/" element={<Navigate replace to="/login" />} />
  </React.Fragment>
);

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem("userData");
  const user = JSON.parse(JSON.stringify(currentUser));
  return user ?<RecoilRoot><DrawerTemplate /></RecoilRoot>  : <Navigate to="/login" />;
};

function App() {
  const currentUser = localStorage.getItem("userData");
  const user = JSON.parse(JSON.stringify(currentUser));
  const [userId] = useState(user);

  return (
    <div>
      <AuthContext.Provider value={{ userId, setAuthTokens: userId }}>
        <CssBaseline />
        <BrowserRouter>
          {/* <Suspense fallback={<LinearProgress />}> */}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path ="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          {/* </Suspense> */}
        </BrowserRouter>
        </AuthContext.Provider>
    </div>
  );

}

export default App;
