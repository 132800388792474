import Assessment from "./pages/assessment/Assessment";
import DashBoard from "./pages/dashbaord/DashBoard";
import Reports from "./pages/report/Reports";
import HelpSupport from "./pages/helpsupport/HelpSupport"
import UserManagement from "./pages/usermanagement/UserManagement";
import Login from "./pages/login/Login";
// import SavedAssesment from "./pages/assessment/savedAssesment";
import ManageAssessment from "./pages/assessment/manageassessment/ManageAssessment";
import CapturePhoto from "./pages/assessment/manageassessment/CapturePhoto";
import complaintDetails from "./pages/fieldInvestigation/complaintDetails";
import NewUser from "./pages/usermanagement/NewUser";
import QrScanner from "./pages/input/QRCodeScanner";
import VideoRecorder from "./pages/input/VideoRecorder";
import Profile from "./pages/usermanagement/Profile";

import { ReactComponent as DashBoardIcon } from '../src/assets/sidebar/ic_dashboard.svg';
import { ReactComponent as AssessmentIcon } from '../src/assets/sidebar/ic_assessment.svg';
import { ReactComponent as ReporsIcon } from '../src/assets/sidebar/ic_reports.svg';
import { ReactComponent as UserManagementIcon } from '../src/assets/sidebar/ic_user_management.svg';
import { ReactComponent as HelpSupportIcon } from '../src/assets/sidebar/ic_help_support.svg';

const Routes = {
    mainRoutes: [
      {
        path: '/dashboard',
        name: 'DashBoard',
        component: DashBoard,
        icon: DashBoardIcon,
        iconSelected: DashBoardIcon
      },
      {
        path: '/complaintDetails',
        name: 'ComplaintDetails',
        component: complaintDetails,
        // icon: AssessmentIcon,
        // iconSelected:AssessmentIcon
      },
      {
        path: '/manageassessment',
        name: 'Assessment',
        // component: SavedAssesment,
        component: ManageAssessment,
        icon: AssessmentIcon,
        iconSelected:AssessmentIcon
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports, 
        icon:ReporsIcon,
        iconSelected:ReporsIcon        
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/capturephoto',
        name: 'PhotoCapture',
        component: CapturePhoto,
     
      },
      {
        path: '/qrScanner',
        name: 'QrScanner',
        component: QrScanner,
     
      },
      {
        path: '/usermanagement',
        name: 'User Management',
        component: UserManagement, 
        icon:UserManagementIcon,
        iconSelected:UserManagementIcon   
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/helpsupport',
        name: 'Help & Support',
        component: HelpSupport, 
        icon:HelpSupportIcon,  
        iconSelected:HelpSupportIcon 
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/login',
        name: 'Login',
        component: Login, 
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/assessment',
        name: 'Assessment',
        component: Assessment, 
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/newuser',
        name: 'NewUser',
        component: NewUser, 
        // icon: OpenTrialsIcon,
        // iconSelected: NewCaseSelected
      },
      {
        path: '/videorecorder',
        name: 'Video Recorder',
        component: VideoRecorder, 
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile, 
      },
    ]
  };
  
  export default Routes;
  