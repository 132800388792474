import React,{useState, useEffect,useRef} from 'react';
import Typography from "@mui/material/Typography";
import FilterIcon from '../../assets/icons/ic_filter.svg';
import Card from "@material-ui/core/Card";
import MenuIcon from '../../assets/icons/ic_menu.svg';
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import PreviewPdf from './ReportPdf';
import API from '../sevices/Api';
import moment from 'moment';

const access_token = localStorage.getItem("accesstoken");
const Reports = () => {
  const [recordInfo,setrecordInfo]=useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordpdf,setrecordpdf]=useState([])
  const [previewForm, setPreviewForm] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const printCover = () => {
    setTimeout(() => {
      handlePrint();
    }, 400);
  }
  
  const portraitOrientation = () => {
    return (
      <style type="text/css">
        {"@media print{@page {size: landscape; margin: 5mm}}"}
      </style>
    )
  }
  const fetchData = async () => {
    try {
      const url = `${API.PDF_DATA}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      });
      console.log("responseField", response.data);
      setrecordInfo(response.data)
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleMenuIconClick = (event,record) => {
    const rect = event.target.getBoundingClientRect();
    setIsPopupOpen(true);
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    });
    setrecordpdf(record)
  };
  const handlePDF = () => {
    setPreviewForm(true);
    printCover();
  }
    return (
      <div style={{margin:"20px"}}> 
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "20px"}}>
          <Typography style={{ textAlign: 'left', color: '#252C32', fontSize: "24px", fontWeight: 600,letterSpacing:"-0.408px",lineHeight:"22px"}}>Reports</Typography>
          </div>
         <div style={{ display: 'flex',marginTop:"5px", flexDirection:"column"}}>
          <Typography style={{fontFamily:"Inter",fontWeight:"400px",fontSize:"16px", textAlign:"left",color:"#909190"}}>The created assessments are listed with details on the timeline, stage, progress and other properties.</Typography>
        </div>
        <div style={{ flexDirection: 'row', display: 'flex', maxWidth: '100%',marginTop:"15px"}}>
          <img src={FilterIcon} alt="Filter Icon" />
          <div style={{ position: 'relative', flex: '1', marginRight:"5px"}}>
            <input
              type="text"
              placeholder="Search"
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '5px',
                border: '2px solid #E8E8E8',
                marginLeft: '10px', // Add some left margin for spacing
              }}
            />
          </div>
        </div>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        {recordInfo.map((record) => {
          // const timestamp = record.last_updated;
          // const dateObject = new Date(timestamp);
          // const formattedDate = dateObject.toLocaleDateString();
          return (
            <Card
              key={record.id}
              style={{
                width: "100%",
                padding: 20,
                borderRadius: "8px",
                border: "1px solid #E8E8E8",
                background: "var(--black-white-white, #FFF)",
                display: "flex",
                alignItems: "center",
                marginBottom: "30px",
                boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)",
              }}
              variant="outlined"
            >
              {/* <Avatar {...stringAvatar((user.first_name + " " + user.last_name).toUpperCase(), 50)} /> */}
              <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 550, fontSize: "16px", color: '#1D1B20' }}>
                    Claim No : {record.claim_number}
                  </Typography>
                  <img onClick={(event) => handleMenuIconClick(event,record)} src={MenuIcon}></img>
                </div>

                <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{
                    color: '#909190',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.072px',
                  }}>
                    Created By
                  </Typography>
                  {/* <Typography style={{
                    color: '#909190',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    marginRight: '40px',
                    letterSpacing: '-0.072px',
                  }}>
                  Last Updated
                  </Typography> */}
                </div>
                <div style={{ marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    letterSpacing: '-0.084px',
                  }}>
                   {moment(record.created_at).format(("DD-MMM-YYYY"))}

                  </Typography>
                  {/* <Typography style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px',
                    marginRight: '40px',
                    letterSpacing: '-0.084px',
                  }}>
                    {formattedDate}
                  </Typography> */}
                </div>
              </div>
              
              {isPopupOpen && (
                <div style={{
                  position: 'absolute',
                  top: `${popupPosition.top}px`,
                  right: `${popupPosition.right}px`,
                  background: 'rgba(255, 255, 255, 1)',
                  padding: '20px',
                  borderRadius: '8px',
                  border: '1px solid #E8E8E8',
                  boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}>
                  {/* Your popup content */}
                  <div onClick={handlePDF} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
                    <Typography
                      style={{
                        color: '#252C32',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}>View</Typography>
                  </div>
                </div>
              )}
            </Card>
          );
        })}
      </div>
      {
                previewForm ? 
                    <>
                        <div id="cust-print" style={{display: "none", marginTop: 20 }}>
                            <div ref={printRef} style={{
                                margin: "10px",
                                width: "1150px"
                            }}>
                                <style>{portraitOrientation()}</style>
                                <PreviewPdf data={recordpdf}/>
                            
                            </div>
                        </div>
                    </>
                    :
                    <></>
            }
      </div>
    );
  
}

export default Reports;