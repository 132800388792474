import axios from 'axios';
import API from './Api';

const access_token = localStorage.getItem("accesstoken");
const Services = {

 async getLatestAssessment() {
        try {
          const response = await axios.get(`${API.GET_LATEST_ASSESSMENT}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          });
          let data = response.data;
          console.log("DATAAA", data);
          return data;
        } catch (error) {
          console.log("Error:", error);
          return null;
        }
      },

}
export default Services;