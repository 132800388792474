import { atom } from 'recoil';
export const menuList = atom({
  key: 'menuList',
  default: 'DashBoard'
});

export const menuChange = atom({
  key: 'menuChange',
  default: false
});
export const viewChange = atom({
  key: "viewChange",
  default: false
});
export const popupShow = atom({
  key: "popupShow",
  default: false
});

export const viewVisitData = atom({
  key: "viewVisitData",
  default: []
});

export const editVisitData = atom({
  key: "editVisitData",
  default: []
});
export const recordVisitData = atom({
  key: "editVisitData",
  default: 0
});

export const deleteImages = atom({
  key: "deleteImages",
  default: []
});
export const chooseImages = atom({
  key: "chooseImages",
  default: []
});
export const imageType = atom({
  key: "imageType",
  default: [],
});
export const nocimageType = atom({
  key: "nocimageType",
  default: [],
});
export const videoType = atom({
  key: "videoType",
  default: [],
});
export const error = atom({
  key: "error",
  default: false
});
export const delearInfo = atom({
  key: 'delearInfo',
  default: {
    dateComplaint_record: "",
    complaintRecord_customer: "",
    dealer_Name: "",
    dealer_Address: "",
    contact_Person: "",
    customer_Name: "",
    claim_type_id:"",
    region_id:"",
  }
});

export const vechicleInfo = atom({
  key: 'vechicleInfo',
  default: {
    vehicle_Model: "",
    vin_Number: "",
    date_sale: "",
    // Production_Date: "",
    // part_Name: "",
    Total_kms_Covered: ""
  }
});
export const glassInfo = atom({
  key: 'glassInfo',
  default: {
    Production_Date: "",
    part_Name: "",
    part_number:"",
    glass_Batchcode: "",
    glass_status: ""
  }
});

export const NatureofComplaintInfo = atom({
  key: 'NatureofComplaintInfo',
  default: {
    defect_Location: "",
    customer_Voice: "",
    imageList: [],
    videoList: [],
    imageList_noc:[],
  }
});
export const FieldInvestigation = atom({
  key: 'FieldInvestigation',
  default: {
    date_of_investigation: "",
    image_desc: [{
      id: "",
      desc: ""
    }],
    image_names: [{
      id: "",
      name: ""
    }],
    raw_images: [{
      imageName:"",
      imageDescription:""
    }],
    raw_images_names: [{
      imageName:"",
      imageDescription:""
    }], 
    need_to_process:[],
    raw_videos:[],
    processed_images: [],
    processed_images_names: [],
    field_observation: "",
    mode_Transport: "",
    collection_of_parts:false,
    root_cause_identified:[],
    recommendation:"",
  }
});


export const fieldInvestigationButton = atom({
  key: 'fieldInvestigationButton',
  default:'Edit'
});

export const flowType = atom({
  key: "flowType",
  default: 1
});
export const rootData = atom({
  key: "rootData",
  default: []
});