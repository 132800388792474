import React, { useRef, useState, useEffect } from 'react';
import DateTimeMonth from '../input/DateTimeMonth';
import { Typography } from '@mui/material';
import { Paper, Popover } from '@material-ui/core';
import CloudUploadIcon from '../../assets/icons/ic_cloud_upload.svg';
import GalleryIcon from '../../assets/icons/ic_gallery.svg';
import CameraIcon from '../../assets/icons/ic_camera.svg';
import HandleIcon from '../../assets/icons/ic_handle.svg';
import TextCommendBox from '../input/TextCommendBox';
import CheckBox from '../input/CheckBox';
import SelectBox from '../input/SelectBox';
import { imageType, chooseImages, FieldInvestigation, viewVisitData,videoType } from "../recoil/atoms"
import { useRecoilState, useRecoilValue } from 'recoil';
import CapturePhoto from '../assessment/manageassessment/CapturePhoto';
import ImageSliderText from '../input/ImageSliderText';
import CaptureVideo from '../assessment/manageassessment/CaptureVideo';
import VideoPreview from '../input/VideoPreview';
import moment from 'moment';
import configParam from "../../config";
import service from '../sevices/Api';
import axios from 'axios';
import Helper from '../sevices/Helper';
import API from '../sevices/Api';
import TextField from '@mui/material/TextField';
import CustomButton from '../input/CustomButton';
import Swal from 'sweetalert2';
import AddBottomPopover from "../input/AddBottomPopover";
import TextBox from '../input/TextBox';
import RootCause from './RootCause';
import { useNavigate } from 'react-router-dom';


const deepCopy = configParam.DEEP_COPY;
const access_token = localStorage.getItem("accesstoken");
const FieldInvestigationView = ({ onEditClick, onNextClick }) => {
    const fileInputRef = useRef(null);
    const videoInputRef=useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorVideoEl, setAnchorVideoEl] = useState(null);
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [showVideoComponent, setShowVideoComponent] = useState(false);
    const [selectedImages, setSelectedImages] = useRecoilState(imageType);
    const [DelImages, setDelImages] = useState([]);
    const [comments, setComments] = useState([]);
    const captureImageArray = useRecoilValue(chooseImages);
    const [selectedVideo, setSelectedVideos] = useRecoilState(videoType);
    const [fieldData, setFieldData] = useRecoilState(FieldInvestigation);
    const assessment_id = localStorage.getItem('row_id');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [processedImageStatus,setprocessedImageStatus]=useState(null);
    const [approvedStatus,setapprovedStatus]=useState(null);
    const navigate = useNavigate();
    console.log("rawraw4",fieldData)
    const handleEditClick = () => {
        setIsEditMode(true);
        // If you have a specific function passed as `onEditClick` prop, you can call it here
        if (onEditClick) {
            onEditClick();
        }
    };

    const updateImagesFieldArray = async () => {
        try {
          const images = [];
          const indexes=[];
          console.log("selectedImages", selectedImages);
          console.log("fieldrawimages",fieldData.raw_images_names)
          // Process each image
          for (let index = 0; index < fieldData.raw_images_names.length; index++) {
            const imageDataUrl = fieldData.raw_images_names[index].imageName;
    
            if (imageDataUrl.startsWith('data:image/')) {
              const imageType = imageDataUrl.substring(
                imageDataUrl.indexOf('/') + 1,
                imageDataUrl.indexOf(';')
              );
              const imageBase64 = imageDataUrl.substring(
                imageDataUrl.indexOf(',') + 1
              );
              const claim=localStorage.getItem('assessment_data')
              const claimdata=JSON.parse(claim)
              const claim_number=claimdata.claim_number
              const folder_name='raw'
              const imageName = `image_${Date.now()}_${index}.${imageType}`;
              images.push({
                imageName,
                imageBase64,
                imageType,
                claim_number,
                folder_name
              });
              indexes.push(index)
            }
          }
    
          // Call uploadBlobImagesAPI once after processing all images
          if (images.length > 0) {
            const response = await axios.post(API.UPLOAD_BLOB_IMAGES, {
              images,
            }, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                }
              });
            let updatedRawImages = [...fieldData.raw_images_names];
            console.log("updatedrawimages2",updatedRawImages)
            console.log("responseee", response);
            const imagesArray = response.data;
            console.log("imagesArray", imagesArray);
            const imageNames = imagesArray.map((image) => image.url);
            console.log("imageNames", imageNames);
            console.log("indexes",indexes)

            for (let index = 0; index < indexes.length; index++) {
                updatedRawImages[indexes[index]] = {
                    ...updatedRawImages[indexes[index]],
                    imageName: imageNames[index],
                  };
            }
            // Update natureInfo with the new image names
         const updateimagenames=updatedRawImages.map(obj => obj.imageName)
         const updatedFinalImageNames = updateimagenames.map(url => {
            const cleanUrl = url.split("?")[0];
            const parts = cleanUrl.split("/");
            return parts[parts.length - 2] + "/" + parts[parts.length - 1];
          });
          const updatedData = updatedRawImages.map((item, index) => ({
            imageName: updatedFinalImageNames[index],
            imageDescription:item.imageDescription
          }));
    console.log("updatedrawimages23",updatedData)
            await dataUpdateField(updatedData,updatedFinalImageNames,fieldData.processed_images_names);
          } else {
            // No base64 images, update natureInfo without calling uploadBlobImagesAPI
            const updateimagenames=fieldData.raw_images_names.map(obj => obj.imageName)
            const updatedFinalImageNames = updateimagenames.map(url => {
               const cleanUrl = url.split("?")[0];
               const parts = cleanUrl.split("/");
               return parts[parts.length - 2] + "/" + parts[parts.length - 1];
             });
             const updatedData = fieldData.raw_images.map((item, index) => ({
               imageName: updateimagenames[index],
               imageDescription:item.imageDescription
             }));
            const needToprocess=[]
            await dataUpdateField(updatedData,needToprocess,updateimagenames);
          }
        } catch (error) {
          console.error('Error updating images:', error);
          // Handle error as needed
        }
      };

    const dataUpdateField = async (updatedNatureInfo,needToprocess,processedimages) => {
        const ids = localStorage.getItem('field_investigation_id');
        const storedId = localStorage.getItem('row_id');
        let url = `${API.UPDATE_FIELD_INVESTIGATION}/${ids}`
        console.log("fieldData.need_to_process",fieldData.need_to_process)
        axios.post(url, {
          collection_of_parts: fieldData.collection_of_parts,
          date_of_investigation: fieldData.date_of_investigation,
          field_observation: fieldData.field_observation,
          mode_of_transport: fieldData.mode_Transport,
          assessment_id: storedId,
          processed_images: processedimages,
          raw_images: updatedNatureInfo,
          raw_videos: fieldData.raw_videos,
          recommendation: fieldData.recommendation,
          need_to_process:needToprocess,
          root_cause_identified: fieldData.root_cause_identified,
          processed_image_status:processedImageStatus,
          approval_status:approvedStatus
        }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
          .then((res) => {
            console.log(res)
            if (res.data.affected > 0) {
              Swal.fire({
                icon: 'success',
                title: 'Update Successful',
                text: 'Your data has been updated successfully!',
              }).then(() => {
                navigate(`/manageassessment`);
              });
    
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Submission Error',
                text: 'There was an error submitting your data.',
              });
            }
    
          });
    
      }

    const handleUpdateClick =  async () => {

       await updateImagesFieldArray();
    };


    const handleNextClick = () => {
        // Add your logic for the "Edit" button click here
        // For example, you can log a message for now
        console.log("NEXT button clicked!");
    
        // If you have a specific function passed as `onEditClick` prop, you can call it here
        if (onNextClick) {
            onNextClick();
        }
    };  




    useEffect(() => {
        fetchData();
     
          // Update the recoil state with the new raw_images array
        //   setFieldData((prevFieldData) => ({
        //     ...prevFieldData,
        //     raw_images: uniqueImages,
        //   }));
    }, [assessment_id]);

    const fetchData = async () => {
        try {
            const url = `${API.GET_FIELD_INVESTIGATION}/${assessment_id}`;
            const response = await axios.get(url, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                }
              });
            console.log("response", response)
            console.log("responseField", response.data);
            console.log("rawraw1",fieldData)
            if (response) {

                const newData = response.data[0];
                console.log(newData, "newData");
                console.log("newData",newData.raw_images);
                console.log("newData1",newData.mode_of_transport);
                // const imageDescArray = newData.raw_images.map((imageData, index) => ({
                //     id: index.toString(),
                //     desc: imageData.imageDescription || '', // Use the imageDescription or an empty string if not available
                // }));

                const dateObject = new Date(newData.date_of_investigation);

               // Get the date portion as a string (YYYY-MM-DD)
               const dateOnly = dateObject.toISOString().slice(0, 10);
               const nextDay = new Date(dateObject);
nextDay.setDate(dateObject.getDate() + 1);

// Get the next day's date portion as a string (YYYY-MM-DD)
const nextDayString = nextDay.toISOString().slice(0, 10);

                setFieldData((prevData) => ({
                    ...prevData,
                    date_of_investigation: nextDayString,
                    // image_desc: imageDescArray,
                    // image_names: newData.image_names,
                    raw_images: newData.raw_images,
                    raw_images_names:newData.raw_images_names,
                    //   raw_videos: newData.raw_videos,
                    processed_images: newData.processed_images,
                    processed_images_names:newData.processed_images_names,
                    need_to_process:newData.need_to_process,
                    // field_observation: newData.field_observation,
                    mode_Transport: newData.mode_of_transport,
                    collection_of_parts: newData.collection_of_parts,
                    root_cause_identified:newData.root_cause_identified,
                    recommendation: newData.recommendation,
                }));
                // setSelectedVideos([newData.raw_videos])
                setprocessedImageStatus(newData.processed_image_status)
                setapprovedStatus(newData.approval_status)
            } else {
                setEditFieldData([]);
            }
        } catch (error) {
            // setError(error.message || 'An error occurred while fetching data.');
            // setEditFieldData([]);
        }
    };

    useEffect(() => {
        const getImageDataUris = captureImageArray.map(item => item.dataUri);
        let mergedArray = [...getImageDataUris, ...selectedImages];
        setDelImages(mergedArray)
    }, [captureImageArray, selectedImages])
    useEffect(() => {
        if(selectedImages.length>0){
            const updatedImageNames = selectedImages[selectedImages.length-1]
            const updatedRawImages = [...fieldData.raw_images];
            const updatedrawimagesnames=[...fieldData.raw_images_names]
      console.log("upup",updatedRawImages)
      console.log("upup1",selectedImages)
            // Update the description and set imageName for the specified index in raw_images
            updatedRawImages[updatedRawImages.length] = {
              imageDescription: updatedRawImages[updatedRawImages.length]?.imageDescription || '',
              imageName: updatedImageNames, // Set to empty string if undefined
            };
            updatedrawimagesnames[updatedrawimagesnames.length] = {
                imageDescription: updatedrawimagesnames[updatedrawimagesnames.length]?.imageDescription || '',
                imageName: updatedImageNames, // Set to empty string if undefined
              };
            console.log("rawraw2",fieldData)
            // Update the recoil state with the new raw_images array
            setFieldData((prevFieldData) => ({
              ...prevFieldData,
              raw_images: updatedRawImages,
              raw_images_names:updatedrawimagesnames,
            }));
        }
       
      
      }, [selectedImages])
     

    const handlePopover = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleVideoPopover = (event) => {
        event.preventDefault();
        setAnchorVideoEl(event.currentTarget);

    };
    const handleCheckBoxClick = () => {
        // setShowSelectBox(!showSelectBox); 
        if (isEditMode) {
            setFieldData({
                ...fieldData,
                collection_of_parts: !fieldData.collection_of_parts,
            });
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleVideoClose = () => {
        setAnchorVideoEl(null);
    };
    const handleCamera = () => {
        setShowComponent(true);
        setAnchorEl(false)
    }
    const handleGallery = () => {
        fileInputRef.current.click();

    }
    const handleVideoGallery = () => {
        videoInputRef.current.click();

    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFieldData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleVideoCamera = () => {
        setShowVideoComponent(true);
        setShowComponent(false);
    }

    

    const handleDeleteIconClick = (imageUrl, index) => {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this image!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'red',
          cancelButtonColor: 'gray',
          confirmButtonText: 'Yes, delete it',
        }).then((result) => {
          if (result.isConfirmed) {
            setFieldData((prevFieldData) => {
        //         if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
        //             const imageName = getFileNameFromUrl(imageUrl);
        //             console.log("imageName", imageName);
        //             const urlObject = new URL(imageUrl);
        //             const pathParts = urlObject.pathname.split('/');
        //             console.log("pathpath",pathParts)
        //             const foldername=pathParts[2]
        //             const claimnumber=pathParts[3]
        //             deleteExistingImageFromBlob(imageName,foldername,claimnumber);
        //   // Process as HTTP URL
        // }
      
              // Remove the image from raw_images
              const rawImages = [...prevFieldData.raw_images];
              const rawImagesnames=[...prevFieldData.raw_images_names]
              rawImages.splice(index, 1);
              rawImagesnames.splice(index, 1);
              const processedImage=[...prevFieldData.processed_images]
              const processedImagenames=[...prevFieldData.processed_images_names]
          
              // Log to verify that the image is removed      
              console.log('New RAW', rawImages);
              if(processedImage.length===0){
                return {
                    ...prevFieldData,
                    raw_images: rawImages,
                    raw_images_names:rawImagesnames,
                  };
              }
              else{
                processedImage.splice(index, 1);
                processedImagenames.splice(index, 1);
                return {
                    ...prevFieldData,
                    raw_images: rawImages,
                    processed_images:processedImage,
                    raw_images_names:rawImagesnames,
                    processed_images_names:processedImagenames,
                  };
              }
              // Return the updated state
            
            });
          }
        });
      };
      
    

      const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const pathParts = urlObject.pathname.split('/');
        const fileName = pathParts[pathParts.length - 1];
        return fileName;
      };
    

      
      const deleteExistingImageFromBlob = async (imageName,foldername,claimnumber) => {
        try {
          const response = await axios.delete(`${API.DELETE_IMAGE}/${imageName}/${foldername}/${claimnumber}`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          });
          console.log("response for delete image", response);
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      }

  const handleFileSelect = async (event) => {
    const files = event.target.files; // Get the selected files from the input
    const maxAllowedFiles = 5;

    // Check if the number of selected files exceeds the maximum allowed
    if (files.length > maxAllowedFiles) {
      alert(`You can only select up to ${maxAllowedFiles} images.`);
      // Clear the file input to prevent uploading more files
      fileInputRef.current.value = null;
    } else {
      // Process the selected files here
      const loadedImages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageUrl = e.target.result;

          // Push the loaded image URL to the array
          loadedImages.push(imageUrl);

          // Check if all images are processed, then update the state
          if (loadedImages.length === files.length) {
            // Update the state with the selected images
            setSelectedImages(prevState => [...prevState, ...loadedImages]);

            // Display the selected images
            loadedImages.forEach((loadedImageUrl) => {
              const image = new Image();
              image.src = loadedImageUrl;
              document.body.appendChild(image);
            });

            // Now, you can make the API call or perform any other actions if needed
            // uploadBlobImagesAPI(selectedImages);
          }
        };

        reader.readAsDataURL(files[i]); // Read the selected file as a data URL
      }
    }
  };

    // const uploadBlobImagesAPI = async (images) => {
    //     try {
    //         setShowSelectBox(true);
    //         let url = API.UPLOAD_BLOB_IMAGES;
    //         const response = await axios.post(url, {
    //             images,
    //         });
    //         console.log("responseee", response);
    //         const imagesArray = response.data;
    //         const imageNames = imagesArray.map((image) => image.url);
    //         console.log("imageNames", imageNames);

    //         // Assuming image_names is part of your state (e.g., fieldData)
    //         const updatedImageNames = imageNames.map((url, index) => ({
    //             id: index.toString(),
    //             name: url,
    //         }));

    //         // Update the recoil state with the new image_names array
    //         setFieldData({
    //             ...fieldData,
    //             image_names: updatedImageNames,
    //         });

    //         setFieldData((prevFieldData) => ({
    //             ...prevFieldData,
    //             raw_images: [...prevFieldData.raw_images, ...imageNames],
    //           }));

    //     } catch (error) {
    //         console.error('Error uploading images:', error);
    //         throw error;
    //     }
    // };

    const uploadBlobImagesAPI = async (images) => {
        try {
            setShowSelectBox(true);
            let url = API.UPLOAD_BLOB_IMAGES;
            const response = await axios.post(url, {
                images,
            }, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                }
              });

            const imagesArray = response.data;
            const updatedRawImages = [
                ...fieldData.raw_images,
                ...imagesArray.map((image, index) => ({
                    imageName: image.url, // Assuming the URL is the new image name
                    imageDescription: "", // You can set a default description or fetch it from somewhere
                })),
            ];

            // Update the fieldData with the modified raw_images array
            setFieldData((prevFieldData) => ({
                ...prevFieldData,
                raw_images: updatedRawImages,
            }));
            //   let obj = [];
            //   const imageNames = imagesArray.map((image) => {

            //       let raw_images = fieldData.raw_images;

            //       raw_images.map(raw=>{



            //       })
            //   }

            //   );
            //   console.log("imageNames",imageNames);

            //   setFieldData((prevFieldData) => (
            //     console.log(prevFieldData),
            //   {
            //   ...prevFieldData,
            //   image_names: [...prevFieldData.image_names, ...imageNames],
            //   raw_images: [...prevFieldData.raw_images, imageName: image],
            // }))
            // Assuming image_names is part of your state (e.g., fieldData)

            console.log("fieldData", fieldData);
        } catch (error) {
            console.error('Error uploading images:', error);
            throw error;
        }
    };


    const handleRefresh = () => {
        setShowSelectBox(false);
    }
    const handleFileSelectGallery = async (event) => {
        let tmpObj = deepCopy(fieldData);
        if (event.target && event.target.files) {
            const selectedFiles = event.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            const fileNames = selectedFilesArray.map((file) => file.name);
            const fileType = selectedFilesArray.map((file) => file.type);

            // Assuming raw_images is an array
            tmpObj.raw_images = selectedFilesArray.map((file, index) => ({
                imageName: fileNames[index],
                imageDescription: "",
                // base64Image: null, 
            }));

            const imagePromises = selectedFilesArray.map((file, index) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const base64Image = e.target.result;
                        resolve(base64Image);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                });
            });

            try {
                const base64Images = await Promise.all(imagePromises);
                setSelectedImages(base64Images);
                setFieldData(tmpObj);
                setShowVideoComponent(false);
                // uploadBase64Image(base64Images, fileNames, fileType);
            } catch (error) {
                console.error("Error reading files:", error);
            }
        } else {
            console.error("Event object or its 'files' property is undefined.");
        }
    };
    // Azure Blob Section
    async function uploadBase64Image(base64Images, fileNames, fileType) {
        let url = service.IMAGE_UPLOAD
        const imageObjects = base64Images.map((base64, index) => ({ image: base64, imageName: fileNames[index], fileType: fileType[index] }));
        await axios.post(url, { image: imageObjects }, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          })
            .then((response) => {
                console.log('Upload successful!', response.data);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            })

    }

    const handleFileVideoSelectGallery = async (event) => {
        const selectedFiles = event.target.files;
        const selectedFile = selectedFiles[0];
        try {
            const base64Video = await readFileAsDataURL(selectedFile);
            setSelectedVideos([base64Video]);
        } catch (error) {
            console.error("Error reading file:", error);
        }
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64Video = e.target.result;
                resolve(base64Video);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleDateChange = (e, key) => {
        if (key === "date_of_investigation") {
            const temObj = { ...fieldData };
            temObj.date_of_investigation = e.target.value;
            setFieldData(temObj);
        }
    };

    const handleAddIconClick = () => {
        console.log("add click");
        setPopoverOpen(true);
        setPopoverAnchorEl(event.currentTarget);

    }

    const handleClosePopover = () => {
        setPopoverOpen(false);
        setPopoverAnchorEl(null);
    };


    const handleGalleryClick = () => {
        handleClosePopover();
        fileInputRef.current.click();
    };




    // const handleDateChange = (e, key) => {
    //     if (key === "date_of_investigation") {
    //         const temObj = { ...fieldData };
    //         temObj.date_of_investigation = e ? moment(e.$d).toDate() : null; // Convert to Date
    //         setFieldData(temObj);
    //     }
    // };

console.log("rawraw",fieldData)


    const popoverContent = (
        <div style={{
            padding: 16,
            width: '100vw',
            boxSizing: 'border-box',
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={HandleIcon} />
            </div>
            <div onClick={handleCamera} style={{ display: 'flex', padding: 20, }}>
                <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Camera</Typography>
            </div>
            <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
                <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
                    Upload from Gallery
                </Typography>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept="image/png, image/jpeg, image/web"
                    multiple
                />
            </div>

        </div>
    );

    // VideoPOPUP Content
    const VideoContent = (
        <div style={{
            padding: 16,
            width: '100vw',
            boxSizing: 'border-box',
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={HandleIcon} />
            </div>
            <div onClick={handleVideoCamera} style={{ display: 'flex', padding: 20, }}>
                <img src={CameraIcon} style={{ width: '19px', height: '19px', textAlign: 'center' }} alt="Camera" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>Use Video Camera</Typography>
            </div>
            <div onClick={handleGallery} style={{ display: 'flex', paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
                <img src={GalleryIcon} style={{ width: '19px', height: '19px' }} alt="Gallery" />
                <Typography style={{ textAlign: 'center', marginLeft: '20px', color: "#1D1B20", fontSize: "16px" }}>
                    Upload Video from Gallery
                </Typography>
                <input
                    type="file"
                    ref={fileInputRef} // Assign the input element to the ref
                    style={{ display: 'none' }}
                    onChange={handleFileVideoSelectGallery}
                    accept="video/*"

                />
            </div>

        </div>
    );
    const options = ["Road /Courier", "Air", "Hand Carry"]
    const b=fieldData.raw_images.map(image => image.imageName)
    console.log("bb",b)
    console.log("bbb",selectedImages)

    return (
        <div style={{ margin: "15px" }}>

<Typography
        variant='h4'
        style={{
          textAlign: 'left',
          color: '#252C32',
          fontFamily: 'Inter',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px',
          letterSpacing: '-0.408px',
        }}
      >
      Field Investigation
      </Typography>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: "#909190",
          marginTop: "10px"
        }}
      >
        The data required for performing the assessment on the cracked images are uploaded here.
      </Typography>
            {console.log("FIELDDDDD", fieldData.date_of_investigation)}
            <div style={{ marginTop: "20px" }}>

                <TextBox
                    id="toDate"
                    label="Date of Field Visit"
                    type="date"
                    value={fieldData.date_of_investigation}
                    onChange={(e) => isEditMode && handleDateChange(e, "date_of_investigation")}
                    style={{ marginTop: "20px", marginBottom: "30px", width: "100%" }}
                />
            </div>
            {fieldData.raw_images.length === 0 && <div style={{ marginTop: "20px" }}>
                <Typography style={{ textAlign: "left" }}>Defect Image</Typography>
                <div style={{
                    border: "2px dashed #00448B",
                    padding: "20px",
                    flex: 1,
                    display: 'flex',
                    width: "100%",
                    height: 150,
                    marginTop: 10,
                    backgroundColor: '#00448a1a',
                    marginRight: 20,
                    flexDirection: "column",
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "4px"
                }}>
                    <img onClick={handleGallery} src={CloudUploadIcon} style={{
                        width: 30,
                        height: 30,
                        marginLeft: 10,
                        marginBottom: 10,
                    }} alt="My Image" />
                    <Typography style={{
                        textAlign: 'center',
                        marginLeft: 15,
                        color: 'black',
                        fontWeight: 600,
                        width: 170,
                        fontSize: 16
                    }}>Upload your Images or Browse</Typography>
                      <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept="image/png, image/jpeg, image/web"
                    multiple
                />
                </div>
            </div>}
            <div>
                {showComponent && <CapturePhoto />}
            </div>
            <div>
                <div style={{marginTop:"30px"}}>
                 {fieldData.raw_images && fieldData.raw_images.length > 0 && fieldData.raw_images.length<6 &&(
                        <ImageSliderText images={fieldData.raw_images.map(image => image.imageName)}
                            descriptions={fieldData.raw_images.map(image => image.imageDescription)}
                            onAddIconClick={isEditMode ? handleAddIconClick : undefined}
                            handlePopover={handlePopover}
                            onDeleteIconClick={isEditMode ? handleDeleteIconClick : undefined}
                        />
                    )}
                </div>
                <div>
                    <AddBottomPopover
                        anchorEl={popoverAnchorEl}
                        open={isPopoverOpen}
                        onClose={handleClosePopover}
                        // handleCamera={handleCameraClick}
                        handleGallery={handleGalleryClick}
                        handleFileSelect={handleFileSelect}
                        fileInputRef={fileInputRef}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                        multiple
                    />
                </div>


            </div>
            <div style={{ marginTop: "20px" }}>
                <Typography style={{ textAlign: "left" }}>Defect Video (Optional)</Typography>
                <div style={{
                    border: "2px dashed #00448B",
                    padding: "20px",
                    flex: 1,
                    display: 'flex',
                    width: "100%",
                    height: 150,
                    marginTop: 10,
                    backgroundColor: '#00448a1a',
                    marginRight: 20,
                    flexDirection: "column",
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "4px"
                }}>
                    <img onClick={handleVideoGallery} src={CloudUploadIcon} style={{
                        width: 30,
                        height: 30,
                        marginLeft: 10,
                        marginBottom: 10,
                    }} alt="My Image" />
                    <Typography style={{
                        textAlign: 'center',
                        marginLeft: 15,
                        color: 'black',
                        fontWeight: 600,
                        width: 170,
                        fontSize: 16
                    }}>Upload your Videos or Browse</Typography>
                        <input
                    type="file"
                    ref={videoInputRef} // Assign the input element to the ref
                    style={{ display: 'none' }}
                    onChange={handleFileVideoSelectGallery}
                    accept="video/*"

                />
                </div>
            </div>
            <div>
                {showVideoComponent && <CaptureVideo />}
            </div>

            <div style={{ marginTop: "30px" }}>
                {
                    selectedVideo ?
                        <VideoPreview selectedVideo={selectedVideo} /> : null
                }

            </div>
            <div>
                <CheckBox label="Collection of Part Required" name="collection_of_parts" handleCheckBoxClick={handleCheckBoxClick} value={fieldData.collection_of_parts} />
            </div>
            <div style={{ marginTop: 10 }}>
                {fieldData.collection_of_parts && (

                    <SelectBox
                        labelVariant="body2"
                        isLable={true}
                        lableValue="Mode of Transport"
                        options={Helper.ModeOfTransportArray}
                        value={fieldData.mode_Transport}
                        name="mode_Transport"
                        onChange={isEditMode && handleFieldChange}
                        labelCondition="name"
                    />

                    // <SelectBox labelVariant="body2" isLable={true} lableValue="Mode of Transport" options={options} name="mode_Transport" value={fieldData.mode_Transport} onChange={handleFieldChange} />
                )}
            </div>


            {/* Image POPUP */}
            <div>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: window.innerHeight, left: 0 }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Paper style={{ width: '100%' }}>{popoverContent}</Paper>
                </Popover>
            </div>


            {/* Video POPUP */}
            <div>
                <Popover
                    open={Boolean(anchorVideoEl)}
                    anchorVideoEl={anchorVideoEl}
                    onClose={handleVideoClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: window.innerHeight, left: 0 }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Paper style={{ width: '100%' }}>{VideoContent}</Paper>
                </Popover>
            </div>

            {/* Your existing FieldInvestigationView component JSX */}
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flex: 1, paddingRight: "5px" }}>
                    <CustomButton
                        text={isEditMode ? "Update" : "Edit"}
                        onClick={isEditMode ? handleUpdateClick : handleEditClick}
                        style={{ width: "100%" }}
                    />
                </div>
                {/* <div style={{ flex: 1, paddingLeft: "5px" }}>
                    <CustomButton
                        text="Next"
                        onClick={handleNextClick}
                        style={{ width: "100%" }}
                    />
                </div> */}
            </div>

        </div>
    );
}
export default FieldInvestigationView;

